/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from "react";
import ReactDOM from "react-dom";
import store, { persistor } from "./Store/AppContext";
import App from "./App";
import "./Resources/Lib/Normalize/normalize.css";
import "./Resources/Sass/main.scss";
import "./Resources/Css/site.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'core-js/stable'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'babel-polyfill';
import "react-dates/initialize";
import * as serviceWorker from './serviceWorker';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

ReactDOM.render(
  <App
    store={store}
    persistor={persistor}
    basename={PUBLIC_URL}
  />,
  document.getElementById("root")
);

serviceWorker.unregister();