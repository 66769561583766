/* eslint-disable react/no-multi-comp */
import React, { Fragment } from "react";
import { bool, string, array } from "prop-types";
import logoImage from "../../Resources/Images/Logos/logo-life-seguros-2.svg";
import { GLOBAL } from "../../Config/global";
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { APPCONFIG } from '../../app.config';
import { Nav, NavDropdown, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavDropdownMenuItem, NavDropdownMenu, ProfileBadge, NotificationCounter, ConfirmationModal, Button } from '..';
import Sticky from "react-sticky-fill";
import { USER_TOKEN } from '../../Config/userToken';
import ReactInterval from 'react-interval';
import * as utils from '../../Shared/Utils';
import { APIS } from '../../Config/apis';
import { DEFAULT_HEADER } from '../../Config/userToken';
window.$ = window.jQuery = require('jquery');

//const timeout = 15 * 1000; // 15 segundos
//const timeout = 60 * 1000; // un minuto
const timeout = 15 * 60 * 1000; // 15 minutos
//const timeout = 6 * 10 * 60000; // una hora
let menuOptionCount = 0;

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            setExpanded: false,
            showNotificationPanel: false,
            showLogoutModal: false,
            showNotification: false,
            notifications: [],
            menu: [],
            menuSelected: utils.getStorage('menu-selected') ? parseInt(utils.getStorage('menu-selected')) : 0
        };
        
        this.loadMenu = this.loadMenu.bind(this);
        this.redirect = this.redirect.bind(this);
        this.notificationCounterChange = this.notificationCounterChange.bind(this);
    }

    componentDidMount() {
 
        if (userAuth.isAuthenticated()) {
            this.verifyVersion();
            this.loadPermissions();
            this.loadNotifications();
        }
    }

    renderOptions = (menu, levelCount = 0, menuPlacement = 'right') => (
        <NavDropdownMenu key={("menu_option_" + menuOptionCount++).toString()} nested={Boolean(levelCount)} menuPlacement={menuPlacement} className='menu-header'>
            {menu.options.map(option => {
                const increasedLevelCount = levelCount + 1;
                return (
                    <NavDropdownMenuItem key={("menu_option_" + menuOptionCount++).toString()} item={option}>
                        {option.options && this.renderOptions(option, increasedLevelCount, menu.menuPlacement)}
                    </NavDropdownMenuItem>
                );
            })}
        </NavDropdownMenu>
    );

    renderMenuOptions = (menu, levelCount = 0, menuPlacement = 'right') => (
        <NavDropdown key={("menu_option_" + menuOptionCount++).toString()} title={(menu.icon ? <><i className={menu.icon}></i>&nbsp;{menu.label}</> : menu.label)} id={`basic-nav-dropdown-${levelCount}`} className={`dropdown-menu-${menuPlacement}`}
            onMouseEnter={this.handleOpen}
            onMouseLeave={this.handleClose}
            >
            {menu.options.map((option, index) => {
                const increasedLevelCount = levelCount + 1;
                return (
                    option && option.label && option.link ? (
                        <>
                            <NavDropdown.Item key={("menu_option_" + menuOptionCount++).toString()} href={option.link} target={option.link.startsWith("http") ? ("_blank") : ("")} onSelect={this.onSelectMenu}>
                                {option.label}
                            </NavDropdown.Item>
                            {(menu.options.length !== index + 1) && (<NavDropdown.Divider />)}
                        </>
                    ) : (
                            option.options && this.renderMenuOptions(option, increasedLevelCount, menu.menuPlacement)
                        )
                );
            })}
        </NavDropdown>
    );

    handleOpen = () => {
        this.setState({ isOpen: true })
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    loadPermissions() {
        if (userAuth.requestSelectProducer() === true || userAuth.isAdmin()) {
            /// nothing
        }
        else {
            let api = '';

            if (userAuth.userIdProducer() !== undefined)
                api = APPCONFIG.ENDPOINTS.PRODUCERURL + "api/simulator/getpermissionsproducersimulator?userIdProducer=" + userAuth.userIdProducer() + '&userId=' + userAuth.simulatorUserId();
            else if (userAuth.userId() !== undefined)
                api = APPCONFIG.ENDPOINTS.PRODUCERURL + "api/login/getroles?userId=" + userAuth.userId();
                
            if (api)
            //fetch(api).then((response) => { return response.json() }).then(this.loadMenu);
            fetch(api)
            .then((response) => {
                if(response.ok) {
                    response.json().then(this.loadMenu);
                }
                else {
                    console.log(response.status);
                }
            })
            .catch((error) => {
                console.log(error);
                //reject(error);
            });

        }
    }

    loadMenu(data) {
        const { COMPONENTS_FORM: { MENUS } } = GLOBAL;

        const isProducer = userAuth.isProducer();

        if (data && data.length > 0 && data.indexOf('logout') > -1) {
            userAuth.signout(() => { this.logout(isProducer); }); 
            return;
        }

        if (data.filter(item => { return item === 'BusquedaCartera' || item === 'CargaNominas' || item === 'RubricaDigital' || item === 'LibreDeuda' }).length > 0) {
            data.push('Cartera');
        }

        if (data.filter(item => { return item === 'LiquidacionCC' || item === 'BusquedaCartera' || item === 'EnvioFacturas' }).length > 0) {
            data.push('CuentaCorriente');
        }

        if (data.filter(item => { return item === 'RechazoMotivo' || item === 'DeudoresPremio' }).length > 0) {
            data.push('Cobranzas');
        }

        if (data.filter(item => { return item === 'AdminNotificaciones' || item === 'AdminComunicaciones' || item === 'AdminLeyendas' || item === 'VerAuditoriaProductor' }).length > 0) {
            data.push('Administracion');
        }

        data.push('Marketing');

        let result = [];
        for (let index = 0; index < data.length; index++) {
            let menu = MENUS.filter(item => { return item.permissionName === data[index]; })[0];
            //// 
            if (menu !== undefined && menu.nivel === 1) {
            if (menu && menu.options.length) {
                menu.options = menu.options.sort((a, b) => a.order - b.order);
                menu.options = this.buildChildMenu(menu.options, data);
                result.push(menu);
            }
            else if (menu) {
                result.push(menu);
            }
        }
        }
        result = result.sort((a, b) => a.order - b.order);
        this.setState({ menu: result });

    }

    loadNotifications() {

        const cuit = userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer();
        if (cuit !== null && cuit !== undefined) {
            
            let urlApi = APPCONFIG.ENDPOINTS.PRODUCERURL + "api/notification/notificationsearch?CUIT=" + cuit + "&Nuevos=true&WebUse=true&MailUse=false";
            if (urlApi)
            QLIBS.fetchDataGET(urlApi, true)
                .then((response) => {
                    let notifications = response.map(e => ({
                        id: e.id,
                        title: e.WebTitle,
                        body: e.WebText,
                        showBody: false,
                        link: e.WebLink
                    }));
                    this.setState({ notifications: notifications, showNotification: true });
                });

        }
    }

    verifyNotification(id) {

        const cuit = userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer();
        if (cuit !== null && cuit !== undefined) {

            let urlApi = APPCONFIG.ENDPOINTS.PRODUCERURL + "api/notification/notificationwebverify?CUIT=" + cuit + "&id=" + id;
            if (urlApi)
            QLIBS.fetchDataGET(urlApi, true)
                .then((response) => {
                    this.loadNotifications();
                });

        }
    }

    verifyVersion() {

        let urlApi = APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Login/GetAppVersion';
        if (urlApi)
        QLIBS.fetchDataGET(urlApi, true)
            .then((appVersion) => {
                const localAppVersion = userAuth.getAppVersion();
                if (localAppVersion === null || localAppVersion !== appVersion) {
                    userAuth.setAppVersion(appVersion);
                    this.emptyCache();
                }
            });
    }

    emptyCache() {
        if('caches' in window){
            caches.keys().then((names) => {
                names.forEach(name => {
                    caches.delete(name);
                })
            });
            window.location.reload(true);
        }
    }

    buildChildMenu(menus, data) {
         
        let result = [];
        for (let index = 0; index < data.length; index++) {
            //// 
            if (menus !== undefined) {
                let menu = menus.filter(item => { return item.permissionName === data[index]; })[0];
                if (menu) {
                    let opt = menu.options;
                    menu.options = []
                    menu.options = this.buildChildMenu(opt, data);
                    result.push(menu);
                }
            }
        }
        return result.sort((a, b) => a.order - b.order);
    }

    logout = (isProducer) => {
        this.executeLogOff();
        if (isProducer) {
            userAuth.signout(() => { this.redirect('/login') });
        }
        else {
            userAuth.signout(() => { this.redirect('/admin') });
        }
    }

    redirect = (path) => {
        // some form handling action
        this.props.push(path); //navigating to ...
    }

    onSelectMenu = (e) => {
        let index = this.state.menu.findIndex(x => x.link === e);
        utils.setStorage('menu-selected', index);
        this.setState({ menuSelected: index });
    }

    executeLogOff() {
        const requestOptions = {
            method: 'POST',
            headers: DEFAULT_HEADER.get(),
            body: JSON.stringify({
                userid: userAuth.userId()
            })
        };
        fetch(APIS.URLS.LOGOUT, requestOptions)
            .then(() => {
            })
    }

    notificationCounterChange(id) {
        if (id !== null) {
            this.verifyNotification(id);
        }
    }

    render() {
        const { USER_DASHBOARD: { LOGOUT_MODAL } } = GLOBAL;        

        menuOptionCount = 0;

        return (
            <div className="header">

            
            <Sticky>
                <Fragment>
                    {this.state.showLogoutModal && (
                        <ConfirmationModal
                            message={LOGOUT_MODAL.BODY}
                            title={LOGOUT_MODAL.TITLE}
                            onDismiss={() => this.setState({ showLogoutModal: false })}
                            onConfirm={() => {
                                const isProducer = userAuth.isProducer();
                                this.logout(isProducer);
                            }}
                        />
                    )}
                    <>
                        <ReactInterval timeout={timeout} enabled={this.props.token}
                            callback={() => USER_TOKEN.refresh_token()} />
                        <Navbar key={'navbar_'+this.props.showNavbar} expand="lg" className={`${this.props.showNavbar ? "" : "shadowed"}`}>

                            <div className="container">
                                <Navbar.Brand href="/">
                                    <img
                                        src={logoImage}
                                        className="header__logo"
                                        alt="Life Seguros logo"
                                    />
                                    {this.props.channelLogo.length ?
                                        <img className="ml-4 channel-logo d-none d-md-flex"
                                            src={this.props.channelLogo}
                                            alt="Logo del canal" />
                                        : null
                                    }
                                </Navbar.Brand>
                            </div>
                            {this.props.showProfileBadge && (
                                <>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="mr-auto">
                                            {(userAuth.userIdProducer() !== undefined) && (
                                                <Nav.Item key={'simulate-nav'}>
                                                    <div className="d-flex justify-content-center">
                                                        <Button color={"secondary"} size={"small"} onClick={() => { userAuth.cleanSimulator(); this.redirect("/simulate-producer"); }}>
                                                            {`Simulando productor (${userAuth.userNameProducer()}) ${userAuth.cuitProducer()}`}&nbsp;<i title="Cambiar productor" className="fa fa-close"></i>
                                                        </Button>
                                                    </div>
                                                </Nav.Item>
                                            )}
                                        </Nav>
                                        <Nav className="justify-content-center" justify={true} navbar={true}>
                                            <div style={{ marginTop: "10px", marginRight: "40px"}}>
                                                <NotificationCounter data={this.state.notifications} visible={this.state.showNotification} onChange={this.notificationCounterChange} />
                                            </div>
                                            <Nav.Link key={'avatar'} className="avatar-header">
                                                <ProfileBadge height="35" width="35" inHeader image={userAuth.userAvatar()} />
                                            </Nav.Link>
                                            <Nav.Item className="userMenu"style={{ paddingTop: '8px' }} >
                                                <NavDropdown key={'userName'}  title={userAuth.userName().toUpperCase()} id="collasible-nav-dropdown">
                                                    {(userAuth.isProducer() || userAuth.userIdProducer() !== undefined ) && utils.getStorage('menuAdvanced') !== "true" && (
                                                        <>
                                                            <NavDropdown.Item onClick={() => { this.redirect('/user-dashboard-info'); }}>Editar perfil</NavDropdown.Item>
                                                            <NavDropdown.Divider />
                                                            
                                                        </>
                                                    )}
                                                    {(userAuth.rol() !== "Gestor" && (
                                                    <>
                                                    <NavDropdown.Item key={'delegado'} onClick={() => { this.props.push('/delegado');}}>Agregar subusuario</NavDropdown.Item>
                                                            <NavDropdown.Divider /></>))
                                                    }
                                                    {userAuth.isUserSimulator() && (
                                                        <>
                                                            <NavDropdown.Item key={'change'} onClick={() => { userAuth.cleanSimulator(); this.redirect('/simulate-producer') }}>Cambiar productor / Avanzado</NavDropdown.Item>
                                                            <NavDropdown.Divider />
                                                        </>
                                                    )}
                                                    <NavDropdown.Item key={'salir'}  onClick={() => { this.setState({ showLogoutModal: true }) }}>Salir</NavDropdown.Item>
                                                </NavDropdown>
                                            </Nav.Item>
                                        </Nav>
                                    </Navbar.Collapse>
                                </>
                            )}
                        </Navbar>
                        {this.props.showNavbar && this.state.menu.length >0 && (
                            <div className="menu-nav">                                                        
                            <Nav id="menu" className="justify-content-center header-nav" activeKey="/home">
                                {this.state.menu.map((menu, index) => (
                                    <>
                                        {(menu.options.length) === 0 ? (
                                            <Nav.Item key={("menu_option_" + menuOptionCount++).toString()}>
                                                <Nav.Link key={("menu_option_" + menuOptionCount++).toString()} href={menu.link} target={menu.link.startsWith("http") ? ("_blank") : ("")} className={menu.className} onSelect={this.onSelectMenu}>
                                                    {menu.tooltip ? (<OverlayTrigger placement="top" overlay={<Tooltip id={"button-tooltip-" + index}>{menu.tooltip}</Tooltip>}>
                                                        <p className={this.state.menuSelected === index ? "menu-bold" : ""}>{menu.icon ? <><i className={menu.icon}></i>&nbsp;{menu.label} </> : menu.label}</p>
                                                    </OverlayTrigger>) : (<p className={this.state.menuSelected === index ? "menu-bold" : ""}>{menu.icon ? <><i className={menu.icon}></i>&nbsp;{menu.label} </> : menu.label}</p>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ) : (
                                                menu.options && (
                                                    menu.tooltip ? (
                                                    <OverlayTrigger key={("menu_option_" + menuOptionCount++).toString()} placement="top" overlay={<Tooltip id={"button-tooltip-" + index}>{menu.tooltip}</Tooltip>}>
                                                        <div>{this.renderMenuOptions(menu)}</div>
                                                    </OverlayTrigger>)
                                                    : (this.renderMenuOptions(menu))
                                                )

                                            )}
                                    </>
                                ))}
                            </Nav>
                            </div>
                        )}
                    </>
                </Fragment>
            </Sticky>
            </div>
        );
    }
}

Header.defaultProps = {
    showBackButton: true,
    centered: false,
    channelLogo: '',
    showHamburgerMenu: false,
    showNavbar: false,
    showProfileBadge: false,
    showNotificationPanel: false,
    notifications: [],
    token: false
};

Header.propTypes = {
    menuItems: array,
    centered: bool,
    channelLogo: string,
    showBackButton: bool,
    showHamburgerMenu: bool,
    showNavbar: bool,
    showProfileBadge: bool,
    showNotificationPanel: bool,
    token: bool
};

export default Header;