/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import {
	Alert,
	Breadcrumb,
	Loading,
	Table as TableComponent
} from "../../Components";
import Actions from "../../Components/GridComponents/Actions";
import 'react-dates/initialize';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { GLOBAL } from "../../Config/global";
import "font-awesome/css/font-awesome.min.css";
import { APPCONFIG } from '../../app.config';
import { USER_TOKEN } from '../../Config/userToken';
import moment from "moment";
import "moment/locale/es";
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';

class HistorialNotificaciones extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			listNotificaciones: [],
			indexItem: null,
			showModal: false,
			showGrid: false,
			loadingData: false
		};
		this.searchResultsError = "";

		this.detailsColumn = {
			Header: "",
			accessor: "actions",
			width: 100,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<>
					<Actions
						icon="fa fa-file"
						onDownload={() => this.detailsItem(row)}
					/>
				</>
			)
		};

		this.baseTableColumns = this.createBaseTableColumns();
		this.listTableColumns = this.createListTableColumns();

		this.handleShowBody = this.handleShowBody.bind(this);
		this.handleHideBody = this.handleHideBody.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.loadGrid = this.loadGrid.bind(this);
	}

	componentDidMount() {

		this.handleResponse([]);
		this.loadGrid();

	}


	detailsItem = event => {
		const index = event.row._original.index;
		this.handleShowBody(index);
	}

	handleShowBody(index) {
		this.setState({showModal: true, indexItem: index });
	}

	handleHideBody() {
		this.setState({showModal: false, indexItem: null });
	}


	loadGrid = () => {
		this.searchResultsError = "";

		this.setState({loadingData: true });

		const cuit = userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer();
        if (cuit !== null && cuit !== undefined) {
            
            let urlApi = APPCONFIG.ENDPOINTS.PRODUCERURL + "api/notification/notificationsearch?CUIT=" + cuit + "&Nuevos=false&WebUse=true&MailUse=false";
            if (urlApi)
            QLIBS.fetchDataGET(urlApi, true)
			.then(this.handleResponse)
			.catch(this.handleResponse);
        }

	}

	handleResponse(response) {
		const { COMMON_SEARCH_FORM } = GLOBAL;

		let tableData = [];
		if (Array.isArray(response)) {
			this.searchResultsError = "";

			moment.locale("es");
			tableData = response.map(function (item, index) {
				item.index = index;
				return {
					...item,
					CreateDateDesc: item.CreateDate == null ? '-' : moment(item.CreateDate).format("DD/MM/YYYY HH:mm:ss"),
				}
			});

		}
		else {
			this.searchResultsError = COMMON_SEARCH_FORM.SERVER_ERROR;
		}

		this.listTableColumns = this.createListTableColumns();

		this.searchTableData = {
			makeData: () => {
				return tableData
			}
		};

		this.setState({ loadingData: false, showGrid: true, listNotificaciones: response });
	}


	createListTableColumns() {
		const newTableColumns = [this.detailsColumn, ...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}

	createBaseTableColumns() {
		const { REPORT_FORMAT } = GLOBAL;

		let newTableColumns = [];

		// Get Columns identifier
		let columns = {
			"CreateDateDesc": { title: "Fecha", field: "CreateDate", format: REPORT_FORMAT.FECHA, show: true, width: 300 },
			"WebTitle": { title: "Título", field: "WebTitle", show: true }
		};

		// Add Columns to Table
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });

		return newTableColumns;
	}

	GetUrlWebLink(id) {
		const userToken = USER_TOKEN.get();
		const AccessToken = userToken.token;
		const RefreshToken = userToken.refreshToken;

		const download_url = APPCONFIG.ENDPOINTS.PRODUCERURL + `api/FileRepository/Download/NotificationAttach/${id}?RefreshToken=${RefreshToken}&AccessToken=${AccessToken}`;
		return download_url;
	}


	render(...props) {
		const { PAGES } = GLOBAL;

		return (
			<>

			{this.state.showModal && (
				<div className="modal" tabIndex="-1" role="dialog"  aria-modal="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
							<button type="button" className="close" aria-hidden="true" onClick={() => this.handleHideBody()}>x</button>
							<h4 className="modal-title text-center">{this.state.listNotificaciones[this.state.indexItem].WebTitle}</h4>
							</div>
							<div className="modal-body m-4" 
								dangerouslySetInnerHTML={{ __html: this.state.listNotificaciones[this.state.indexItem].WebText }}
							>
							</div>
							<div className="modal-footer">
								{this.state.listNotificaciones[this.state.indexItem].WebLink && (
								<div className='col-12 text-center'>
									<a target='_blank'
									href={this.GetUrlWebLink(this.state.listNotificaciones[this.state.indexItem].Id)}
									style={{ color: "#504F4F" }}>
										<span>Ver archivo adjunto</span>
									</a>
								</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
				
				<Loading visible={this.state.loadingData}></Loading>
				
				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/historialnotificaciones" />
						</div>
						<div className="col-12">
							<h4 className="form-title">{PAGES.TITLE.HISTORIALNOTIFICATION}</h4>
						</div>
						<div className="form-container container table-view col-md-12 mt-2">

							{this.searchResultsError && (
								<div id="form-error-message" className="row my-2">
									<div className="col-12">
										<Alert
											icon="times"
											message=""
											title={this.searchResultsError}
											errorInfo={this.searchResultsError}
											type="error"
										/>
									</div>
								</div>
							)}
							{this.state.showGrid && (
								<div className="row my-3">
									<div className="col-12 px-3">
										{<TableComponent
											columns={this.listTableColumns}
											data={this.searchTableData.makeData()}
										/>}
									</div>
								</div>
							)}
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default HistorialNotificaciones;