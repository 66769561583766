/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import { Footer, Header, Breadcrumb, ConfirmationModal, DragAndDrop, Button, Alert, Loading, Input, Combobox, SimulatorWarning } from '../../Components';
import { GLOBAL } from '../../Config/global';

class TipsFacturacion extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			error: '',
			success: false,
		};
		/// FUNCIONES
		//this.handleChange = this.handleChange.bind(this);
	}
	render(...props) {
		const { INVOICE_DELIVERY, PAGES } = GLOBAL;
		return (
			<>
				<Loading visible={this.state.loadingForm}></Loading>
				<section className='form-container master-page tipsfacturacion'>
					<div className='col-12'>
						<div className='row'>
							<div className='col-12'>
								<Breadcrumb currentPage="/tipsfacturacion" />
								<div className="col-12">
									<div className="row">
										<div className="col-12">
											<h4 className="form-title">{PAGES.TITLE.TIPSFACTURACION}</h4>
										</div>
									</div>
								</div>
								<section>
									<div className="col-12">
										<div className="row">
											<div className="subtitleTip">¿Cómo facturar?</div>
											<div className="textTip">Si operás en el ramo de vida y/o patrimoniales deberás facturar de forma independiente a Orígenes Seguros S.A y si operás en el ramo de
											retiro deberás facturar a Orígenes Seguros de Retiro S.A.
											</div>
										</div>
									</div>
									<div className="datosFacturacion">
										<div className="row">
											<div className="col-6">
												<div className="subtitleTip">Datos de facturación:
										</div>
												<div className="textTip">
													<p>Si facturás a Orígenes Seguros de Vida:</p>
													<p><b>ORIGENES SEGUROS SA</b></p>
													<p>CUIT: 30-50005574-6</p>
												</div>
												<div className="">
													<p>Si facturás a Orígenes Seguros de Retiro:</p>
													<p><b>ORIGENES SEGUROS DE RETIRO SA</b></p>
													<p>CUIT: 30-62442189-9</p>
												</div>
											</div>
											<div className="col-6">
												<div className="subtitleTip">¿Cuáles son los medios habilitados para facturar?
										</div>
												<div className="textTip">
													<p>Por nuestra WEB PAS:</p>
													<p>- Ingresando a <a href="/enviofacturas" alt="Envio Facturas"> <b>Envío de Facturas</b></a> vas a
													poder adjuntar la factura, seleccionar el mes de liquidación, la
													empresa a la que corresponde facturar y por último enviarla.</p>
													<p>- <b>Por mail:</b> para casos excepciones podés enviar tu factura a
													 <b><a href="mailto:liquidaciones@origenes.com.ar"> liquidaciones@origenes.com.ar </a></b>
 													y copiando a tu ejecutivo de cuentas con el asunto <b>“Liquidación de Comisiones:
													mes xx / año xx”.</b></p>
												</div>
											</div>
										</div>
									</div>

									<div className="col-12">
										<div className="row">
											<div style={{ marginTop: "15px" }}>
												<p><b>Aclaración:</b></p>
												<p>- Las importes de las comisiones, están expresados sin IVA. Aquellos productores responsables inscriptos deberán adicionar el IVA al
												momento de su facturación.</p>
												<p>- Los seguros de Vida y Sepelio se facturan de forma independiente, a los seguros de Accidentes Personales, Salud y Patrimoniales</p>
											</div>
										</div>
									</div>

									<div className="col-12">
										<div className="row">
											<div style={{ marginTop: "15px" }}>
												<div className="subtitleTip">Tips de Cobranza para Clientes:</div>
												<p>En el caso de que haya algun rechazo de pago por alguno de tus clientes, te dejamos a mano un instructivo donde tenés todos los datos según la rama del producto.</p>
											</div>
										</div>
										<div className="col-12" style={{ padding: "15px" }}>
											<div className="row">
												<div className="col-3">
													<Button onClick={() => window.open("https://origenes.com.ar/docs/productores/Tips-Cobranzas_Clientes_Vida-Patrimoniales.pdf")} >Vida / Patrimoniales</Button>
												</div>
											</div>
										</div>
										<div className="col-12" style={{ padding: "15px" }}>
											<div className="row">
												<div className="col-3">
													<Button onClick={() => window.open("https://origenes.com.ar/docs/productores/Tips-Cobranzas_Clientes_Retiro.pdf")} >Retiro</Button>
												</div>
											</div>
										</div>
									</div>

									<div className="col-12">
										<div className="row">
											<div style={{ marginTop: "15px" }}>
												<div className="subtitleTip">Teléfono de contacto de Ejecutivos de Cuentas Corrientes</div>
											</div>
											<div className="col-12">
												<div className="">
													<p><b>Florencia Sidero</b></p>
													<p>4340-3632</p>
													<p><a href="mailto:fsidero@origenes.com.ar"> fsidero@origenes.com.ar </a>
													</p>
												</div>

												<div style={{ paddingTop: "10px" }}>
													<p><b>Viviana Mosdin</b></p>
													<p>4340-3627</p>
													<p><a href="mailto:vmosdin@origenes.com.ar"> vmosdin@origenes.com.ar </a>
													</p>
												</div>

												<div style={{ paddingTop: "10px", paddingBottom:"15px" }}>
													<p>Otras consultas:
															<b><a href="mailto:opcobranzas@origenes.com.ar "> opcobranzas@origenes.com.ar </a></b>
													</p>
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default withRouter(TipsFacturacion);
