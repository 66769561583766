import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import EnvioFacturasStep1 from '../../Resources/Images/Tutoriales/EnvioFacturas/EnvioFacturas-1.jpg';

const EnvioFacturas = props => {
    return (
        <div>
            <ReactWizard
                steps={[
                    {
                        stepName: "PASO 1", component: <StepTutorial image={EnvioFacturasStep1} text={[
                            'Seleccionar el sector: "Envío de Factura" ',
                            'Escribir un mensaje y el asunto del mismo',
                            'Si es necesario, agregar un archivo adjunto',
                            'Si se desea, seleccionar “Recibir copia en mi e-mail”',
                            'Hacer clic en “Enviar consulta” para que sea recibida por el sector correspondiente'
                        ]} />
                    }
                ]}
                navSteps
                title="Paso a paso para el Envio de Facturas."
                headerTextCenter
                validate
                color="green"
                previousButtonText="Volver"
                nextButtonText="Continuar"
                finishButtonText="Finalizar"
                finishButtonClick={() => { props.onFinish(); }}
            />
        </div>
    )
}

EnvioFacturas.propTypes = {

}

export default EnvioFacturas