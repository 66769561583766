import React, { Component } from 'react'
import { Button, Alert, Combobox, Checkbox, Input } from '../../Components';
import { FORM_ERRORS } from '../../Config/form_errors';
import { GLOBAL } from '../../Config/global';
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import CarouselDImageOne from '../../Resources/Images/FormImages/home-img.jpg';
import moment from 'moment';

export class SendEmailNews extends Component {
    constructor(props) {
        super(props)
        this.state = {
            onDismiss: props.onDismiss,
            news: props.news,
            errorMessage: '',
            errorBorder_user: '',
            errorMsg_user: '',
            users: [],
            userIds: [],
            allUsers: true,
            baseImage: '',
            emails: '',
            errorMsg_emails: '',
            errorBorder_emails: '',
            addEmails: false
        }
    }

    componentDidMount() {
        QLIBS.fillCombo('users', APIS.URLS.PRODUCER, this, false, true);
        var self = this;
        var xhr = new XMLHttpRequest()
        xhr.open("GET", CarouselDImageOne);
        xhr.responseType = "blob";
        xhr.send();
        xhr.addEventListener("load", function () {
            var reader = new FileReader();
            reader.readAsDataURL(xhr.response);
            reader.addEventListener("loadend", function () {
                self.setState({ baseImage: reader.result });
            });
        });
    }

    handleComboChange = (e, s) => {
        this.setState({ userIds: e.map((item) => { return item.value }) }, () => { console.log(this.state) });
    }

    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";
        let inputName = event.target.id ? event.target.id : event.target.name;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;
        this.setState({ [inputName]: inputValue }, () => {
            
        });
    }

    validate = () => {
        const { COMMON_ERRORS } = FORM_ERRORS;
        let errorBorder_user = '', errorBorder_emails = '';
        let errorMsg_user = '', errorMsg_emails = '';
        let errors = false;
        let result = false;

        if (!this.state.allUsers && this.state.userIds.length === 0) {
            errorMsg_user = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            errors = true;
        }
        // 
        if (this.state.emails.length > 0) {
            let emails = this.state.emails.split(';');
            emails.forEach(function (value, index) {
                if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
                    errorMsg_emails = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMAIL_INVALID;
                    errors = true;
                    return false;
                }
            });
            errorBorder_emails = (errorMsg_emails) ? "error" : "success";

        }
        errorBorder_user = (errorMsg_user) ? "error" : "success";

        this.setState({ errorMsg_user, errorBorder_user, errorMsg_emails, errorBorder_emails });

        if (!errors) {
            result = true;
        }
        return result;
    }

    save = () => {
        if (this.validate()) {

            let entityCopy = Object.assign({}, this.state.news);

            entityCopy.Image = (entityCopy.DefaultImage ? this.state.baseImage : (entityCopy.Image ? entityCopy.Image : null));
            entityCopy.CreationDate = moment();
            QLIBS.fetchDataPOST(APIS.URLS.SEND_NEWS, {
                'News': entityCopy,
                'UserIds': this.state.userIds.length > 0 ? this.state.userIds : [],
                'ExtraEmails': this.state.emails
            }, true)
                .then(this.handleResponse)
                .catch(this.handleResponse);
        }
    }

    handleResponse = (response) => {
        // 
        if (response) {
            this.state.onDismiss();
        } else {
            this.setState({ errorMessage: 'Ha ocurrido un error. Vuelva a intentarlo.' })
        }
    }

    render() {
        const { COMPONENTS_FORM } = GLOBAL;
        return (
            <div className="modal" tabIndex="-1" role="dialog" aria-hidden="false">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div>
                            <button type="button" className="close positioning" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={this.state.onDismiss}>&times;</span>
                            </button>
                            <h5 className="modal-title text-center">ENVIAR AVISO POR E-MAIL</h5>
                        </div>
                        <div className="modal-body p-0 mb-1">
                            <br />

                            <div className="row">
                                {this.state.errorMessage && (
                                    <div className="col-12 mb-1">
                                        <Alert type="error" icon="exclamation" title={COMPONENTS_FORM.ALERT_ERROR_TITLE} message={this.state.errorMessage} noDetail={true} />
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <Checkbox
                                        id={"allUsers"}
                                        text="Enviar a todos los productores"
                                        checked={this.state.allUsers}
                                        onChange={() => { this.setState({ allUsers: !this.state.allUsers }) }}
                                    />
                                </div>
                                <div className="col-7">
                                    <Combobox
                                        id="users"
                                        label="Productores"
                                        borderColor={this.state.errorBorder_user}
                                        errorMsg={this.state.errorMsg_user}
                                        options={this.state.users.map((item) => ({ value: item.id, label: item.value }))}
                                        isDisabled={this.state.allUsers}
                                        onChange={this.handleComboChange}
                                        placeholder="SELECCIONAR 1 o VARIOS PRODUCTORES"
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <Checkbox
                                        id="addEmails"
                                        text="Enviar correo a e-mails particulares"
                                        checked={this.state.addEmails}
                                        onChange={() => {
                                            // 
                                            if (this.state.addEmails && this.state.emails.length > 0) {
                                                this.setState({ errorMessage: "No es posible cambiar esta opción con emails ingresados" });
                                                document.getElementById("addEmails").checked = true;
                                            } else {
                                                this.setState({ addEmails: !this.state.addEmails })
                                            }
                                        }}
                                    />
                                </div>
                                {this.state.addEmails && (
                                    <div className="col-7">
                                        <Input type="text" id="emails" text="E-mails" smallPrint="Ingrese uno o más e-mails separados por ; (punto y coma)"
                                            onChange={this.handleChange}
                                            errorMsg={this.state.errorMsg_emails}
                                            borderColor={this.state.errorBorder_emails}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type="button" size="medium-lg" color="secondary" fill="outline" data-dismiss="modal" onClick={this.state.onDismiss}>{GLOBAL.COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                            <Button type="button" size="medium-lg" onClick={() => { this.save() }}>ENVIAR</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SendEmailNews