import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import loginStep1 from '../../Resources/Images/Tutoriales/Login/login-1.jpg';
import loginStep2 from '../../Resources/Images/Tutoriales/Login/login-2.jpg';

const Login = props => {
    return (
        <div>
            <ReactWizard
                steps={[
                    {
                        stepName: "PASO 1", component: <StepTutorial image={loginStep1} text={[
                            <span>Ingresar a <a href='http://productores.lifeseguros.com.ar/'>http://productores.lifeseguros.com.ar/</a>,
                             escribir el CUIT y contraseña que generó en el registro al sistema </span>,
                             <span>Presionar el botón <b>Ingresar</b></span>]} />
                    },
                    {
                        stepName: "PASO 2", component: <StepTutorial image={loginStep2} text={[
                            "Si los datos ingresados son correctos, se redireccionará al portal web PAS",
                        <span>En caso que no recuerde su contraseña, acceda a 
                            <a href='http://productores.lifeseguros.com.ar/recover-password' > http://productores.lifeseguros.com.ar/recover-password </a>
                             para poder reestablecerla</span>]} />
                    }
                ]}
                navSteps
                title="Paso a paso del ingreso al sistema."
                //description=""
                headerTextCenter
                validate
                color="green"
                previousButtonText="Volver"
                nextButtonText="Continuar"
                finishButtonText="Finalizar"
                finishButtonClick={() => { props.onFinish(); }}
            />
        </div>
    )
}

Login.propTypes = {

}

export default Login
