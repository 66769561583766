/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Button } from '../../Components'
import { GLOBAL } from '../../Config/global'
import { func } from 'prop-types';
import ReactJson from 'react-json-view'

export const AuditoriaModal = (props) => {
    return (
        <div className="modal" tabIndex="-1" role="dialog" aria-hidden="false" id="modal-auditoria">
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close positioning" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={props.onDismiss}>&times;</span>
                        </button>
                        <h5 className="modal-title text-center">{props.title}</h5>
                    </div>
                    <div className="modal-body p-2 mt-2">
                        {props.logs && (
                            <>
                                <div className="row">
                                    <div className="col-12 big-text">
                                        <label>Detalle:</label><br />
                                        <label><b>{props.logs && props.logs.detail ? (props.logs.detail) : ""}</b></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-4">
                                        <label>Gravedad:</label><br />
                                        <label><b>{props.logs && props.logs.severity ? (props.logs.severity) : ""}</b></label>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-4">
                                        <label>Fecha:</label><br />
                                        <label><b>{props.logs && props.logs.creationDate ? (props.logs.creationDate) : ""}</b></label>
                                    </div>

                                </div>
                                <div className="row mt-2">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-2">
                                        <label>Origen:</label><br />
                                        <label><b>{props.logs && props.logs.source ? (props.logs.source) : ""}</b></label>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-6">
                                        <label>Clase:</label><br />
                                        <label><b>{props.logs && props.logs.class ? (props.logs.class) : ""}</b></label>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-4">
                                        <label>Método:</label><br />
                                        <label><b>{props.logs && props.logs.method ? (props.logs.method) : ""}</b></label>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    {props.logs && props.logs.logEvent && (
                                        <div className="col-6 big-text">
                                            <label>Log de Evento:</label><br />
                                            <ReactJson src={JSON.parse(props.logs.logEvent)} collapsed={true} displayDataTypes={false} displayObjectSize={false} enableClipboard={false} />
                                        </div>
                                    )}
                                    {props.logs && props.logs.parameters && (
                                        <div className="col-6 big-text">
                                            <label>Parámetros:</label><br />
                                            <ReactJson src={JSON.parse(props.logs.parameters)} collapsed={true} displayDataTypes={false} displayObjectSize={false} enableClipboard={false} />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {props.actions && (
                            <>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <label>Aplicación:</label><br />
                                        <label><b>{props.actions && props.actions.application ? (props.actions.application) : ""}</b></label>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <label>Fecha:</label><br />
                                        <label><b>{props.actions && props.actions.creationDate ? (props.actions.creationDate) : ""}</b></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <label>URL:</label><br />
                                        <label><b>{props.actions && props.actions.url ? (props.actions.url) : ""}</b></label>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <label>Acción:</label><br />
                                        <label><b>{props.actions && props.actions.action ? (props.actions.action) : ""}</b></label>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <label>Función:</label><br />
                                        <label><b>{props.actions && props.actions.function ? (props.actions.function) : ""}</b></label>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    {props.actions && props.actions.trace && (
                                        <div className="col-12 big-text">
                                            <label>Trace:</label><br />
                                            <ReactJson src={JSON.parse(props.actions.trace)} collapsed={true} displayDataTypes={false} displayObjectSize={false} enableClipboard={false} />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {props.messages && (
                            <>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <label>Tipo:</label><br />
                                        <label><b>{props.messages && props.messages.typeString ? (props.messages.typeString) : ""}</b></label>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <label>ID Template:</label><br />
                                        <label><b>{props.messages && props.messages.registerId ? (props.messages.registerId) : ""}</b></label>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <label>Fecha de envio:</label><br />
                                        <label><b>{props.messages && props.messages.sendDate ? (props.messages.sendDate) : ""}</b></label>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <label>Enviado a:</label><br />
                                        <label><b>{props.messages && props.messages.to ? (props.messages.to) : ""}</b></label>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="modal-footer">
                        <Button type="button" size="medium-lg" color="secondary" fill="outline" data-dismiss="modal" onClick={props.onDismiss}>{GLOBAL.COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

AuditoriaModal.propTypes = {
    onDismiss: func.isRequired,
};