import React, { useState } from "react";
import { Header, Sidebar, NotificationsSection, ConfirmationModal } from "../../Components";
import { GLOBAL } from "../../Config/global";
import NChannel from "../../Resources/Images/Logos/channel-2-logo.png";

const UserDashboard = ({ history }) => {
  const {
    USER_DASHBOARD: { SIDEBAR, NOTIFICATION_TILES, LOGOUT_MODAL }
  } = GLOBAL;
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleRouteChange = option => {
    if (option.doBeforeRedirect) {
      return setShowLogoutModal(true);
    }

    return history.push(option.link);
  };

  return (
    <section>
      {showLogoutModal && (
        <ConfirmationModal
          message={LOGOUT_MODAL.BODY}
          title={LOGOUT_MODAL.TITLE}
          status="warning"
          onDismiss={() => setShowLogoutModal(false)}
          onConfirm={() => history.push("/")}
        />
      )}
      <Header
        channelLogo={NChannel}
        showBackButton={false}
        showNotificationPanel
        showProfileBadge
        showNavbar
        showHamburgerMenu
      />
      <section className="MainContainer user-dash-notif">
        <section className="container mb-0 mt-md-5">
          <div className="row">
            <div className="col-md-3 p-0 p-md-2">
              <Sidebar
                onChange={item => handleRouteChange(item)}
                title={SIDEBAR.TITLE}
                items={SIDEBAR.ITEMS}
              />
            </div>
            <div className="col-md-9 py-2">
              <NotificationsSection
                displayCount={4}
                unreadNotifications={NOTIFICATION_TILES}
              />
            </div>
          </div>
        </section>
      </section>
      {/*<Footer />*/}
    </section>
  );
};

export default UserDashboard;
