import React, { Component } from 'react'
import { Loading, TextArea, Button, Checkbox, Combobox, DatePicker, Input, Alert } from '..';
import { GLOBAL } from '../../Config/global';
import { APPCONFIG } from '../../app.config';
import { DEFAULT_HEADER } from '../../Config/userToken';
import * as QLIBS from '../../qLibsHelpers';
import moment from "moment";


export default class LabelModal extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            modeEdition: false,
            loadingData: false,
            showForm: false,
            errorMessage: null,
            entity: null,
            textFinishDate: ''
        }

        this.getLabel = this.getLabel.bind(this);
        this.clearLabel = this.clearLabel.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleComboChange = this.handleComboChange.bind(this);
    }


    componentDidMount() {

        let Id = this.props.Id;
        if (Id === 0) {
            this.clearLabel(); 
        }
        else {
           this.getLabel(Id); 
        }

    }

	getLabel = (Id) => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		this.setState({loadingData: true, showForm: false, errorMessage: null, entity: null });

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};			
		return fetch(`${PRODUCERURL}api/Label/getLabel?Id=${Id}`, requestOptions)
		.then((response) => {

			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then((response) => {
                    const entity = response;
                    if (entity.EndDate == null) entity.EndDate = '';
                    if (entity.FinishDate == null) entity.FinishDate = '';

                    let textFinishDate = '';
                    if (entity.FinishDate !== '') {
                        const fecha = moment(entity.FinishDate).format("DD/MM/YYYY");
                        textFinishDate = `Fecha de inactivación: ${fecha}`;
                    }

                    this.setState({ modeEdition: entity.Enabled, loadingData: false, showForm: true, entity: entity, textFinishDate: textFinishDate });
                })
				.catch((response) => {
                    const message = 'Error procesando datos';
                    this.setState({ loadingData: false, showForm: false, errorMessage: message });
                });
			}
			else {
				const message = `Error procesando solicitud: ${response.statusText}`;
				this.setState({ loadingData: false, showForm: false, errorMessage: message });
			}

		});
	}

    clearLabel = () => {

        const entity = {
            Section: '',
            Type: '',
            Description: '',
            Text: '',
            Enabled: true,
            StartDate: new Date(),
            EndDate: '',
            FinishDate: '',
        };
        this.setState({ modeEdition: true, loadingData: false, showForm: true, entity: entity });
    }

    validar() {
        const entity = {...this.state.entity};
        if (!entity.Section || !entity.Type || !entity.Description || !entity.Text || !entity.StartDate) {
            this.setState({ errorMessage: 'Debe completar todos los campos obligatorios' });
            return false;
        }

        return true;
    }


    handleSubmit = () => {

        if (this.validar()) {
            const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

            let data = {...this.state.entity};
            
            //normalizo los campos sin datos
            if (data.EndDate === '') data.EndDate = null;
            if (data.FinishDate === '') data.FinishDate = null;

            this.setState({loadingData: true, showForm: false, errorMessage: null });

            const requestOptions = {
                method: 'POST',
                headers: DEFAULT_HEADER.get(),
                body: JSON.stringify(data)
            };			
            return fetch(`${PRODUCERURL}api/Label/SaveLabel`, requestOptions)
            .then((response) => {

                if (response.ok) {
                    QLIBS.authorizeAction(response)
                    .then((response) => {
                        this.setState({ loadingData: false, showForm: false, entity: null, errorMessage: null });
                        this.props.dismiss(true);
                    })
                    .catch((response) => {
                        const message = 'Error procesando datos';
                        this.setState({ loadingData: false, showForm: false, errorMessage: message });
                    });
                }
                else {
                    response.text()
                    .then((error) => {
                        this.setState({ loadingData: false, showForm: false, errorMessage: error });
                    });
                }

            });
        }

    }

    handleInputChange = event => {
        const isCheckbox = event.target.type === "checkbox";

        let inputName = event.target.name ? event.target.name : event.target.id;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;

        let entity = {...this.state.entity};
        switch (inputName) {
            case "ctlDescription":
                entity.Description = inputValue;
                break;
            case "ctlText":
                entity.Text = inputValue;
                break;
            case "ctlEnabled":
                entity.Enabled = !inputValue;
                break;
            default:
                break;
        }

        this.setState({ entity: entity });
    };

    handleDatePickerChange = event => {
        let inputName = event.target.name ? event.target.name : event.target.id;
        let inputValue = event.target.value;

        if (inputValue !== '') {
            inputValue = new Date(inputValue);
        }

        let entity = {...this.state.entity};
        switch (inputName) {
            case "ctlStartDate":
                entity.StartDate = inputValue;
                break;
            case "ctlEndDate":
                entity.EndDate = inputValue;
                break;
            default:
                break;
        }

        this.setState({ entity: entity });
    };

    handleComboChange = (selectedOption, event, index) => {
        let inputName = event.name;
        let inputValue = selectedOption.value;

        let entity = {...this.state.entity};
        switch (inputName) {
            case "ctlSection":
                entity.Section = inputValue;
                break;
            case "ctlType":
                entity.Type = inputValue;
                break;
            default:
                break;
        }

        this.setState({ entity: entity });
    };


    render() {
        const { COMMON_MODAL_FORM } = GLOBAL;

        return (
		    <>
                <Loading visible={this.state.loadingData}></Loading>

                <div id="form-leyenda" className="modal" tabIndex="-1" role="dialog"  aria-modal="true">
                    <div className="modal-dialog modal-lg m-b-modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title text-center">Definición de Leyenda</h4>
                            </div>
                            <div className="modal-body">

                                {this.state.errorMessage && (<Alert type="error" title="ERROR" message={this.state.errorMessage} noDetail={true} />)}
                                {this.state.showForm && (
                                 <section>
                                    <div className="row pb-4">

                                        <div className="col-12 pt-3">
                                            <Input
                                                id="ctlDescription"
                                                type="text"
                                                text={"Descripción (*)"}
                                                defaultValue={this.state.entity.Description}
                                                classes="mb-0"
                                                maxLength="250"
                                                onChange={this.handleInputChange}
                                                smallPrint="Es la descripción general de la Leyenda"
                                                disabled={!this.state.modeEdition}
                                            />
                                        </div>

                                        <div className="col-12 pt-3">
                                            <label for="ctlText">Texto (*)</label>
                                            <TextArea
                                                id="ctlText"
                                                defaultValue={this.state.entity.Text}
                                                rowNum="5"
                                                maxLength="4000"
                                                onChange={this.handleInputChange}
                                                disabled={!this.state.modeEdition}
                                            />
                                        </div>

                                    </div>
                                    <div className="row pb-4">

                                        <div className="col-6 pt-3">
                                            <Combobox
                                                id="ctlSection"
                                                label={"Planilla (*)"}
                                                options={[
                                                    { value: '', label: "Seleccione una planilla..." },
                                                    { value: 'rechazos', label: "Rechazos de débitos automáticos" },
                                                    { value: 'cartera', label: "Búsqueda de cartera" },
                                                    { value: 'liquidacion', label: "Liquidación de comisiones" },
                                                    { value: 'deudores', label: "Deudores por premio" }
                                                ]}
                                                onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                defaultValue={this.state.entity.Section}
                                                idDisabled={!this.state.modeEdition}
                                            />
                                        </div>

                                        <div className="col-6 pt-3">
                                            <Combobox
                                                id="ctlType"
                                                label={"Tipo (*)"}
                                                options={[
                                                    { value: '', label: "Seleccione un tipo..." },
                                                    { value: 'subtitulo', label: "Subtítulo" },
                                                    { value: 'aviso', label: "Aviso" }
                                                ]}
                                                onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                defaultValue={this.state.entity.Type}
                                                idDisabled={!this.state.modeEdition}
                                            />
                                        </div>

                                        <div className="col-6 pt-3">
                                            <label className="pb-10px">{"Vigencia desde (*)"}</label>
                                            <DatePicker
                                                id="ctlStartDate"
                                                maxLength="10"
                                                noDefaultValue="true"
                                                onChange={this.handleDatePickerChange}
                                                defaultValue={this.state.entity.StartDate.toString()}
                                                disabled={(!this.state.modeEdition || this.props.Id != 0 && (new Date(this.state.entity.StartDate)).getTime() <= (new Date()).getTime())}
                                            />
                                        </div>

                                        <div className="col-6 pt-3">
                                            <label className="pb-10px">{"Vigencia hasta"}</label>
                                            <DatePicker
                                                id="ctlEndDate"
                                                maxLength="10"
                                                noDefaultValue="true"
                                                onChange={this.handleDatePickerChange}
                                                defaultValue={this.state.entity.EndDate.toString()}
                                                disabled={!this.state.modeEdition}
                                            />
                                        </div>

                                        <div className="col-6 pt-3 text-left">
                                            <Checkbox
                                                id="ctlEnabled"
                                                text="Inactivar manualmente"
                                                checked={!this.state.entity.Enabled}
                                                onChange={this.handleInputChange}
                                                smallPrint=""
                                                disabled={!this.state.modeEdition}
                                            />
                                        </div>
                                        <div className="col-6 pt-3 text-right">
                                            <label className="pb-10px">{this.state.textFinishDate}</label>
                                        </div>

                                    </div>
                                    <div className="pb-4 xMensajeAclarativo">
                                        <label className="xMensajeAclarativo">(*) obligatorio</label><br />
                                    </div>
                                 </section>
                                )}
                            
                            </div>
                            <div className="modal-footer">
                                <Button type="button" size="medium-lg" fill="outline" color="secondary" data-dismiss="modal" onClick={() => { this.props.dismiss(false) }}>{COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                                {this.state.showForm && this.state.modeEdition && (
                                    <Button type="button" size="medium-lg" fill="outline" onClick={() => { this.handleSubmit() }}>{COMMON_MODAL_FORM.SAVE_BUTTON}</Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
