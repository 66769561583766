import { string } from 'prop-types';
import React from 'react';
import userAuth from '../../Config/userauth';
const isAdmin = userAuth.isAdmin();
const QuickAccess = ({ customClass }) => (
    <div className='quickBody'>
        <div className="row-access">
            <div className="col-4"  id="accesoRapido">
                <b>Acceso rápido</b>
            </div>
            {(isAdmin) &&
            (<div className="col-sm col-access">
            <i class="fa fa-user-plus fa-1x" ></i>
                <p><a href='/simulator'>Administrar simuladores</a></p>
            </div>)}
            <div className="col-sm col-access">
            <i class="fa   fa-refresh fa-1x" ></i>
                <p>Restablecer contraseña</p>
            </div>
            <div className="col-sm col-access">
            <i class="fa   fa-user fa-1x" ></i>
                <p>Mis datos</p>
            </div>
        </div>
    </div>
);

QuickAccess.defaultProps = {
    customClass: ''
}

QuickAccess.propTypes = {
    customClass: string
}

export default QuickAccess;
