import React from "react";
import { bool } from "prop-types";
import { GLOBAL } from "../../Config/global";
import Button from "../Button";

const { FILE_UPLOAD } = GLOBAL;

export default class FileUploadMultiple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      identity: 0,
    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.uploadButtonRef = React.createRef();
  }

  getFiles = () => {
    return this.state.items.map(item => {
      return ({
        name: item.name,
        file: item.file
      });
    });
  }

  clearFiles = () => {
    this.setState({
      items: [],
      identity: 0
    });
  }

  onChange = e => {
    const parent = this;
    e.target.files.forEach((f, i) => {

      let reader = new FileReader();
      reader.onload = function (event) {
        const item = {
          id: parent.state.identity,
          name: f.name,
          file: event.target.result
        };
  
        parent.setState({
          items: [...parent.state.items, item],
          identity: (parent.state.identity + 1)
        });
    
      }
      reader.readAsDataURL(f);
      
    });

    e.target.value = "";
  }

  removeFile = (id) => {
    this.setState({
      items: this.state.items.filter(f => f.id !== id)
    });
  }

  onButtonClick = () => {
    this.uploadButtonRef.current.click();
  }

  render() {

    return (
      <React.Fragment>

        <div className="col-md-12">
          {(this.props.multiple || this.state.items.length === 0) && (
          <Button type="button" fill="outline" size="medium" onClick={this.onButtonClick} disabled={this.disabled} >
            {FILE_UPLOAD.TEXT}
          </Button>
          )}
          <input
            ref={this.uploadButtonRef}
            type="file"
            id="file"
            {...(this.props.multiple ? {multiple: 'true'} : {})}
            hidden
            onChange={this.onChange}
          />
        </div>

        <div className="col-md-12 tag-editor-container">
          {this.state.items.map((item, index) => {
            return (
              <div className="tag-editor" key={index}>
                <div className="tag-editor-tag">{item.name}</div>
                <div className="tag-editor-delete" title="quitar" onClick={this.removeFile.bind(this, item.id)}>x</div>
                <div className="tag-editor-spacer">&nbsp;,</div>
              </div>
            );
          })}
        </div>

      </React.Fragment>
    );

  }

}

FileUploadMultiple.propTypes = {
  disabled: bool,
  multiple: bool
}

FileUploadMultiple.defaultProps = {
  disabled: false,
  multiple: true
}

