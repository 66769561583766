import React, { Component } from 'react'
import { Breadcrumb, Button, Alert, Loading, Input, Combobox, Checkbox, FileUploadMultiple } from '../../Components';
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import { FORM_ERRORS } from "../../Config/form_errors";
import { withRouter, useParams } from "react-router-dom";
import userAuth from '../../Config/userauth';
import { GLOBAL } from '../../Config/global';

export class Consultas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingForm: false,
            sectores: [],
            tiposConsulta: [],
            tipoConsulta: "",
            sector: "",
            motivo: "",
            comentario: "",
            showQueryReason: true,
            copy: false,

            errorMsg_tipoConsulta: '',
            borderColor_tipoConsulta: '',
            errorMsg_sector: '',
            borderColor_sector: '',
            errorMsg_comentario: '',
            errorBorder_comentario: '',

            submitResponse: null,
            submitOk: false,
            submitInProcess: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResponse = this.handleResponse.bind(this);

        this.refFileUploadMultiple = React.createRef();
    }

    componentDidMount() {
        let self = this;
        QLIBS.fillCombo('sectores', APIS.URLS.SECTOR, self, GLOBAL.COMBO_EMPTY_ITEM, true);
    }

    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";
        let inputName = event.target.id ? event.target.id : event.target.name;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;
        this.setState({ [inputName]: inputValue });
    }

    checkEnvioFactura = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const param1 = urlParams.get('data');
        if (param1 === 'envioFactura') {
            this.state.showQueryReason =  false
            this.state.sector = "4"
        }
        return param1 === 'envioFactura'
    }

    handleComboChange = (selectedOption, event) => {
        if (selectedOption.value !== '') {
            let self = this;
            let inputName = event.name;
            let inputValue = selectedOption.value;
            self.setState({ [inputName]: inputValue }, () => {
                if (inputName === 'sector') {
                    QLIBS.fillCombo('tiposConsulta', APIS.URLS.TIPO_CONSULTA + inputValue, self, GLOBAL.COMBO_EMPTY_ITEM, true);
                    let messageLabel = document.getElementById('message');
                    messageLabel.innerHTML = selectedOption.message ?? '';
                    if (!selectedOption.hasSubCategory)
                        this.setState({ showQueryReason: false })
                    else
                        this.setState({ showQueryReason: true })
                }
            });
        }

    }

    handleSubmit = event => {
        const { COMMON_ERRORS } = FORM_ERRORS;

        event.preventDefault();
        new FormData(event.target);

        let errors = false;
        let errorMsg_tipoConsulta = '', errorMsg_comentario = '',
            errorMsg_sector = '', errorBorder_sector = '',
            errorBorder_tipoConsulta = '', errorBorder_comentario = '';

        this.motivo = event.target.motivo.value;
        this.comentario = event.target.comentario.value;
        this.copy = event.target.copy.checked;
        this.tipoConsulta = event.target.tipoConsulta?.value;
        if (this.state.showQueryReason && !this.tipoConsulta) {
            errorMsg_tipoConsulta = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            errors = true;
        }

        if (!this.state.sector) {
            errorMsg_sector = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            errors = true;
        }

        if (!this.comentario) {
            errorMsg_comentario = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            errors = true;
        }
        else if (this.comentario.length < 25) {
            errorMsg_comentario = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.FIELD_MIN_CHARACTERS_25;
            errors = true;
        }

        errorBorder_tipoConsulta = (errorMsg_tipoConsulta) ? "error" : "success";
        errorBorder_sector = (errorMsg_sector) ? "error" : "success";
        errorBorder_comentario = (errorMsg_comentario) ? "error" : "success";

        this.setState({ errorMsg_tipoConsulta, errorMsg_sector, errorMsg_comentario, errorBorder_tipoConsulta, errorBorder_sector, errorBorder_comentario });
        if (!errors) {
            this.setState({ loadingForm: true, submitOk: false, submitError: '', submitInProcess: true }, () => {
                const files = this.refFileUploadMultiple.current.getFiles();
                const listaFileName = files.map(f => { return f.name; });
                const listaFile = files.map(f => { return f.file; });

                QLIBS.fetchDataPOST(APIS.URLS.CONSULTA_SEND, {
                    'IdSector': this.state.sector,
                    'IdSectorQueryReason': this.tipoConsulta,
                    'Motive': this.motivo,
                    'Detail': this.comentario,
                    'SendCopy': this.copy,
                    'FileName': listaFileName,
                    'File': listaFile,
                    'User': userAuth.userId()
                }, true).then(this.handleResponse);
            });
        }
    }

    handleResponse = response => {
        this.submitResponse = response
        if (response.success) {
            this.submitResponse = response
            this.setState({ loadingForm: false, submitOk: true }, () => {
                setTimeout(function () {
                    window.location.reload(true);
                }, 5000);
            });
        }
        else {
            this.setState({ loadingForm: false, submitError: response.businessExceptions.map(x => x.message).join(". ") });
        }
    }

    render() {
        const { showQueryReason } = this.state
        return (
            <section>
                <Loading visible={this.state.loadingForm}></Loading>
                <section className="form-container master-page">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <Breadcrumb currentPage="/consulta" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 form-container container containerMarginMin">
                        <div className="row">
                            <div className="col-2 p-5">
                                <div id='message' className="lblCobranzas"></div>
                            </div>
                            <div className="col-8 padding10">
                                <form id="consulta_form" onSubmit={this.handleSubmit}>
                                    {this.state.submitError && (
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <Alert
                                                    icon="times"
                                                    title="Error"
                                                    message={this.state.submitError}
                                                    noDetail={true}
                                                    level={this.submitResponse.level}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {this.state.submitOk && (
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <Alert
                                                    type="success"
                                                    title="Mensaje enviado"
                                                    message="Su comentario seria recepcionado y respondido a la brevedad. Muchas gracias."
                                                    noDetail={true}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <Checkbox
                                                id="copy"
                                                checked={this.state.copy}
                                                fontSize="smaller-text"
                                                text="Recibir copia en mi e-mail"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <Combobox
                                                id="sector"
                                                label="Sector"
                                                options={
                                                        this.state.sectores.map((item) => ({ value: item.id, label: item.value, hasSubCategory: item.hasSubCategory, message: item.message }))
                                                }
                                                isDisabled={this.checkEnvioFactura()}
                                                defaultValue={this.state.sector}
                                                onChange={this.handleComboChange}
                                                errorMsg={this.state.errorMsg_sector}
                                                borderColor={this.state.borderColor_sector}
                                            />
                                        </div>
                                        {
                                            showQueryReason && (<div className="col-6">
                                                <Combobox
                                                    id="tipoConsulta"
                                                    label="Tipo de Consulta"
                                                    options={this.state.tiposConsulta.map((item) => ({ value: item.id, label: item.value }))}
                                                    defaultValue={this.state.tipoConsulta}
                                                    onChange={this.handleComboChange}
                                                    errorMsg={this.state.errorMsg_tipoConsulta}
                                                    borderColor={this.state.borderColor_tipoConsulta}
                                                />
                                            </div>
                                            )}

                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <Input
                                                id="motivo"
                                                type="text"
                                                text="Motivo - Asunto"
                                                defaultValue={this.state.motivo}
                                                maxLength="200"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className={this.state.errorMsg_comentario.length ? 'input input-span' : 'input'}>
                                                <label htmlFor="comentario">Comentario</label>
                                                <div className={`input-group input-group-${this.state.errorBorder_text} rounded`}>
                                                    <textarea className="form-control" id="comentario" onChange={(e) => this.handleChange(e)} maxLength={2000} rows={5}>{this.state.comentario}</textarea>
                                                </div>
                                                {this.state.errorMsg_comentario.length ? <span className={`input-span-${this.state.errorBorder_comentario}`}>{this.state.errorMsg_comentario}</span> : null}
                                            </div>
                                        </div>
                                    </div><div className="row mb-3">
                                        <div className="col-12">
                                            <label htmlFor="comentario">Adjuntar archivo</label>
                                            <div>
                                                <FileUploadMultiple ref={this.refFileUploadMultiple} multiple={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {!this.state.submitInProcess && (
                                            <div className="col-12">
                                                <Button type="submit" id="btnSubmit">ENVIAR</Button>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </section>
            </section>
        )
    }
}

export default withRouter(Consultas);