import React from 'react'
import Button from '../../Button';

function CRUDButtons({ onClick, customStyles, text, icon, colorOpc }) {
  return (
    <Button className="button button-primary-full button-small button--full rounded" color={colorOpc} onClick={onClick} customStyles={customStyles}>
      <i className={`fas mx-2 ${  icon}`} />
        {text}
    </Button>
  )
}

export default CRUDButtons;
