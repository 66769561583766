import React, { useState } from 'react'
import { Accordion, Card, Button } from "react-bootstrap";
import {
    Loading,
    Table as TableComponent,
} from "../../../Components";
import * as QLIBS from '../../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../../Config/userToken';
import { APPCONFIG } from '../../../app.config';
import moment from 'moment';
import { TipoRegistro } from './tipoRegistro';
import { truncate } from '../../../Shared/Utils';
import TotalesPago from './totalesPago';
import { GLOBAL } from '../../../Config/global';
const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
const { REPORT_FORMAT } = GLOBAL;
const SectionEnum = {
    DetalleLiquidacion: 1,
    DetallePago: 2,
    DetalleComision: 3,
};

const initSections = [
    {
        id: SectionEnum.DetalleLiquidacion,
        title: 'Detalle liquidación',
        loaded: false,
        type: TipoRegistro.Liquidacion
    },
    {
        id: SectionEnum.DetallePago,
        title: 'Detalle de pago',
        loaded: false,
        type: TipoRegistro.Pago
    },
    {
        id: SectionEnum.DetalleComision,
        title: 'Detalle de comisión',
        loaded: false,
        type: TipoRegistro.Liquidacion
    },
];

const columnsDef = {
    [SectionEnum.DetalleLiquidacion]: {
        "fecha": { title: "Fecha Liquidación", format: REPORT_FORMAT.FECHA, field: 'Fecha' },
        "devengo": { title: "Concepto", field: 'Devengo' },
        "montoNeto": { title: "Monto Bruto", format: REPORT_FORMAT.MONEDA, field: 'MontoNeto' },
        "iva": { title: "Iva 21%", format: REPORT_FORMAT.MONEDA, field: 'IVA' },
        "percepcion": { title: "Iva 3%", format: REPORT_FORMAT.MONEDA, field: 'Percepcion' },
        "totalBR": { title: "Comisión Total", format: REPORT_FORMAT.MONEDA, field: 'TotalBR' },
        "fechaFactura": { title: "Fecha de la FC", format: REPORT_FORMAT.FECHA, field: 'FechaFactura' },
        "comprobanteTipo": { title: "Comprobante Tipo", field: 'ComprobanteTipo' },
        "facturaTipo": { title: "Tipo", field: 'FacturaTipo', format: REPORT_FORMAT.TEXTO  },
        "puntoVenta": { title: "Pto. Vta.", field: 'PuntoVenta', format: REPORT_FORMAT.ENTERO  },
        "facturaNumero": { title: "Factura N°", field: 'FacturaNumero' },
    },
    [SectionEnum.DetallePago]: {
        "fecha": { title: "Fecha Liquidación", format: REPORT_FORMAT.FECHA, field: 'Fecha' },
        "devengo": { title: "Concepto", field:'Devengo' },
        "montoNeto": { title: "Monto Bruto", format: REPORT_FORMAT.MONEDA, field:'MontoNeto' },
        "iva": { title: "Iva 21%", format: REPORT_FORMAT.MONEDA , field:'IVA'},
        "percepcion": { title: "Iva 3%", format: REPORT_FORMAT.MONEDA , field:'Percepcion'},
        "totalBR": { title: "Comisión Total", signo: '$', format: REPORT_FORMAT.MONEDA, field:'TotalBR' },
    },
    [SectionEnum.DetalleComision]: {
        "linea_Negocio": { title: "Línea de Negocio", field: 'Linea_Negocio' },
        "numeroPoliza": { title: "Número de Póliza", field: 'NumeroPoliza' },
        "fechaEmision": { title: "Fecha Emisión", format: REPORT_FORMAT.FECHA, field: 'FechaEmision' },
        "codigo_Movimiento": { title: "Código", field: 'Codigo_Movimiento' },
        "fechaCobro": { title: "Fecha Imputación", format:  REPORT_FORMAT.FECHA, field: 'FechaCobro' },
        "asegurado": { title: "Contratante", field: 'Asegurado' },
        "provincia": { title: "Jurisdicción", field: 'Provincia' },
        "endoso": { title: "Endoso",  format: REPORT_FORMAT.ENTERO,field: 'Endoso' },
        "certificado": { title: "Número Certificado", field: 'Certificado' },
        "premioFacturado": { title: "Premio", signo: '$', format:  REPORT_FORMAT.MONEDA, field: 'PremioFacturado' },
        "premioCobrado": { title: "Premio Cobrado", signo: '$', format: REPORT_FORMAT.MONEDA, field: 'PremioCobrado' },
        "primaNeta": { title: "Prima", signo: '$', format: REPORT_FORMAT.MONEDA, field: 'PrimaNeta' },
        "porcComisionPrima": { title: "% Prima", format: REPORT_FORMAT.DECIMAL, field: 'PorcComisionPrima' },
        "excedente": { title: "Excedente", format: REPORT_FORMAT.MONEDA,field: 'Excedente' },
        "porcComisionExcedente": { title: "% Excedente", format: REPORT_FORMAT.DECIMAL, field: 'PorcComisionExcedente' },
        "comisionTotal": { title: "Comisión Total", signo: '$', format: REPORT_FORMAT.MONEDA, field: 'ComisionTotal' },
    },
};

const apiMethods = {
    [SectionEnum.DetalleLiquidacion]: (props) => `DetalleLiquidacion?LiqNro=${props.original.numero}`,
    [SectionEnum.DetalleComision]: (props, page, pageSize) => `DetalleComision?LiqNro=${props.original.numero}&offset=${page}&pageSize=${pageSize}`,
    [SectionEnum.DetallePago]: (props) => `DetallePago?pagoNro=${props.original.numero}`,
};
class PolicyDetailGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: QLIBS.getTableColumns(columnsDef[props.id]),
            data: [],
            loadingTable: false,
        };
        this.pageSize = 10;
        this.page = 0;
        this.pages = 0;
    }

    createExcelReportColumns() {
        const excelReportColumns = this.state.columns.filter(f => f.show).map(c => {
            return {
                Title: c.title,
                Field: c.field,
                Summarize: c.summarize,
                Format: c.format
            }
        });
        return excelReportColumns;
    }

    handleSubmit = () => {
        const requestOptions = {
            method: this.props.id === SectionEnum.DetalleComision ? 'POST' : 'GET',
            headers: DEFAULT_HEADER.get(),
            body: this.props.id === SectionEnum.DetalleComision ? JSON.stringify(this.state.filters) : null
        };

        this.setState({ loadingTable: true });
        const offset = this.page * this.pageSize;
        let parseUrl = `${PRODUCERURL}api/Settlement/${apiMethods[this.props.id](this.props, offset, this.pageSize)}`;

        if (this.state.sort) {
            const params = {
                desc: this.state.sort[0].desc,
                sort: this.state.sort[0].id,
            };
            const queryString = Object.keys(params)
                .map((key) => key + '=' + params[key])
                .join('&');
            parseUrl += `&${queryString}`
        }

        fetch(parseUrl, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const dataArray = this.props.id === SectionEnum.DetalleComision ? data.data : data;
                const cols = columnsDef[this.props.id];

                if (dataArray.length) {
                    for (let entity of dataArray) {
                        for (let colName in cols) {
                            const col = cols[colName];

                            if (col && col.format) {
                                switch (col.format) {
                                    case REPORT_FORMAT.MONEDA:
                                        entity[colName] = "$ " + truncate(entity[colName], 2)
                                        break;
                                    case REPORT_FORMAT.FECHA:
                                        entity[colName] =entity[colName] ? moment(entity[colName]).format('DD/MM/YYYY') : ' '
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                    }
                    this.pages = Math.ceil(data.total / this.pageSize);
                    this.setState({ data: dataArray });
                }
                this.setState({ loadingTable: false });
            });
    }
    getFileName = (id) => {
        for (let seccion in SectionEnum) {
            if (SectionEnum[seccion] === id) {
                return `${seccion}.xlsx`;
            }
        }
        return `Documento.xlsx`;
    }
    handleExport = () => {
        const requestOptions = {
            method: 'POST',
            headers: DEFAULT_HEADER.get(),
            body: JSON.stringify({ numero: this.props.original.numero, columns: JSON.stringify(this.createExcelReportColumns()) })
        };

        this.setState({ loadingTable: true });
        let parseUrl = `${PRODUCERURL}api/Settlement/DetallePagoLiquidacion/Export?tipoPagoLiq=${this.props.id}`;
        let nombre = this.getFileName(this.props.id);
        fetch(parseUrl, requestOptions)
            .then((response) => {
                if (response.ok)
                    return response.blob();
            })
            .then(function (blob) {
                if (blob) {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = nombre;
                    link.click();
                }
            })
            .then(() => {
                this.setState({ loadingTable: false });
            });
    }

    componentDidMount() {
        this.handleSubmit()
    }

    applyFilter = (event) => {
        if (event.length > 0) {
            this.page = 0
            this.setState({
                filters: event,
            }, this.handleSubmit);
        }
        else {
            this.setState({
                filters: [],
            }, this.handleSubmit);
        }
    }
    getFilter() {
        let filters = this.state.filters
        return filters
    }
    getSort() {
        let sort = this.state.sort
        return sort
    }
    applySort = (event) => {
        if (event.length > 0) {
            this.setState({
                sort: event,
            }, () => this.handleSubmit());
        }
        else {
            this.setState({
                sort: {},
            },
                this.handleSubmit);
        }
    }
    changeSize = async (size) => {
        this.pageSize = size;
        this.handleSubmit()
    }
    changePage = async (page) => {
        this.page = page;
        this.handleSubmit()
    }

    render() {
        return (
            <>
                <div style={{display:'flex', justifyContent:'end'}}>
                    <Button type="submit"
                        className='button button-primary-full rounded to-bottom button-small search-button'
                        onClick={this.handleExport}
                        size="small">
                        <i className="fas fa-file-download mx-2" /> Descargar</Button>
                </div>
                <div className="row my-3">
                    <div>
                        <TableComponent
                            loading={this.state.loadingTable}
                            columns={this.state.columns}
                            data={this.state.data}
                            manual={Number(this.props.id) === SectionEnum.DetalleComision}
                            onFilterChange={this.applyFilter}
                            onSortedChange={this.applySort}
                            page={this.page}
                            sorted={this.getSort()}
                            pageSize={this.pageSize}
                            onPageSizeChange={this.changeSize}
                            pages={this.pages}
                            changePage={this.changePage}
                            expandable={this.props.expandable}
                            subComponent={props => {
                                const propsJoin = Object.assign({}, { ...this.props }, { ...props }, { original: Object.assign({}, { ...this.props.original }, { ...props.original }) })
                                return (<PagoLiquidacionesDrillDown secondLevel={this.props.expandable} {...propsJoin} />)
                            }} />
                        {TipoRegistro[this.props.original.tipoRegistro] === TipoRegistro.Pago
                            &&
                            <TotalesPago props={this.props.original} />
                        }
                    </div>
                </div>
            </>
        );
    }
}



const PagoLiquidacionesDrillDown = (props) => {
    const tipoRegistro = props.original.tipoRegistro === 'Liquidación' ? TipoRegistro.Liquidacion : TipoRegistro.Pago;
    const filteredSections = initSections.filter(e => e.secondLevel === props.secondLevel && e.type === tipoRegistro || !e.type)

    const [sections, setSections] = useState(JSON.parse(JSON.stringify(filteredSections)));

    const loadSection = (id) => {
        const section = sections.find(e => e.id === id);

        if (section.loaded) return;

        const expandable = section.expandable && !props.secondLevel

        section.loaded = true;
        section.grid = <PolicyDetailGrid  {...props} id={id} expandable={expandable} />;

        setSections(sections.slice());
    };

    return (
        <div className="m-4">
            <Accordion defaultActiveKey="0">
                {sections.map(e => (
                    <Card key={e.id}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={e.id} onClick={() => loadSection(e.id)}>
                                {e.title}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={e.id}>
                            <Card.Body>{e.grid}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </div>

    );
};

export default PagoLiquidacionesDrillDown;
