import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import { Button, DatePicker, Header, Breadcrumb, FileUpload, Radio, Input, Select, TextArea, Checkbox, Toast, Loading } from "../../Components";
import { GLOBAL } from '../../Config/global';
import { MOCK_DATA } from '../../Config/mock-data';

const Form = ({ location: { pathname } }) => {
  const [loadingVisibility] = useState(true);

  const { COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
  const { COMPONENTS_FORM: FORM_DATA } = MOCK_DATA;

  return (
    <section>
      <Loading visible={loadingVisibility}></Loading>
      <Header showBackButton={false} showHamburgerMenu showNavbar />
      <section className="form">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Breadcrumb currentPage={ pathname } />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h4 className="form-title">{ COMPONENTS_FORM.TITLE }</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <p className="form-subtitle">
                { COMPONENTS_FORM.SUBTITLE }
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Input text={ COMPONENTS_FORM.NAME_INPUT } />
            </div>
            <div className="col-md-4">
              <Input text={ COMPONENTS_FORM.LASTNAME_INPUT } />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Input text={ COMPONENTS_FORM.USERNAME_INPUT } />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Input
                text={ COMPONENTS_FORM.PASSWORD_INPUT }
                type="password"
                smallPrint={ COMPONENTS_FORM.PASSWORD_INPUT_MSG }
              />
            </div>
            <div className="col-md-4">
              <Input
                text={ COMPONENTS_FORM.CONFIRM_PASSWORD_INPUT }
                type="password"
                smallPrint={ COMPONENTS_FORM.CONFIRM_PASSWORD_INPUT_MSG }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Input text={ COMPONENTS_FORM.MAIL_INPUT } />
            </div>
            <div className="col-md-4">
              <Input text={ COMPONENTS_FORM.TEL_INPUT } />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 my-2">
              <label>{ COMPONENTS_FORM.BIRTH_DATE }</label>
              <DatePicker
                id="birthday-date"
                birthday
              />
            </div>
            <div className="col-md-2 my-2">
              <label>{ COMPONENTS_FORM.DATE_BEGIN }</label>
              <DatePicker
                id="start-date"
              />
            </div>
            <div className="col-md-2 my-2">
              <label>{ COMPONENTS_FORM.DATE_END }</label>
              <DatePicker
                id="end-date"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Select
                text={ COMPONENTS_FORM.SELECT_INPUT }
                defaultValue={ COMPONENTS_FORM.SELECT_INPUT_DEFAULT }
                options={ FORM_DATA.SELECT }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Select
                multiple
                text={ COMPONENTS_FORM.MULTIPLE_SELECT }
                defaultValue={ COMPONENTS_FORM.SELECT_INPUT_DEFAULT }
                options={ FORM_DATA.MULTIPLE_SELECT }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <label>{ COMPONENTS_FORM.TEXTAREA_LABEL }</label>
              <TextArea text={ COMPONENTS_FORM.TEXT_AREA } />
            </div>
          </div>
          <div className="row form-file-upload">
            <div className="col-12">
              <label>{ FILE_UPLOAD.LABEL }</label>
            </div>
            <div className="col-12 d-flex">
              <FileUpload />
            </div>
          </div>
          <div className="row form-radio">
            <div className="col-12">
              <Radio
                name="radio-set-1"
                options={ COMPONENTS_FORM.RADIO_OPTIONS }
              />
            </div>
          </div>
          <div className="row form-terms">
            <div className="col-12">
              <Checkbox
                text={ COMPONENTS_FORM.TERMS_INPUT }
              />
            </div>
          </div>
          <div className="row form-terms">
            <div className="col-md-2 my-2 my-md-0">
              <Button color="secondary" fill="outline">{ COMPONENTS_FORM.CANCEL_BUTTON }</Button>
            </div>
            <div className="col-md-2">
            <Link to="form-validated">
              <Button>{ COMPONENTS_FORM.OK_BUTTON }</Button>
            </Link>
            </div>
          </div>
        </div>
        <Toast
          confirmMessage={ COMPONENTS_FORM.TOAST_CONFIRM_MESSAGE }
          message={ COMPONENTS_FORM.TOAST_MESSAGE }
        />
      </section>
    {/*<Footer rol={ USER_OPTIONS.ROLE } />*/}
  </section>
  )
}

Form.propTypes = {
  location: object.isRequired
};

export default Form;
