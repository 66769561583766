import React from 'react';
import { Table as TableComponent, Alert, Breadcrumb, Actions, Button, NewUserModal, ConfirmationModal, Loading } from '../../../Components'
import { GLOBAL } from "../../../Config/global";
import { Component } from 'devextreme-react/core/component';
import * as QLIBS from '../../../qLibsHelpers';
import { APPCONFIG } from '../../../app.config';
import { DEFAULT_HEADER } from '../../../Config/userToken';
import userAuth from '../../../Config/userauth';
import { checkEmail } from '../../../Shared/Utils/form_validation';
export class Delegado extends Component {

	constructor(props) {
		super(props)

		this.state = {
			tableData: [],
			loadingForm: false,
			showModal: false,
			showDeleteModal: false,
			alertMessage: '',
			error: false,
			user: {}
		}
		this.detailsColumn = {
			Header: "",
			accessor: "actions",
			width: 100,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<>
					<Actions
						customTitle={'Eliminar usuario'}
						customIcon={'fa fa-trash'}
						onCustom={() => this.setState({ showDeleteModal: true, userToDelete: row.original.cuit })}
					/>
				</>

			),

		};
		this.baseTableColumns = this.createBaseTableColumns();
		this.listTableColumns = this.createListTableColumns();
	}
	createBaseTableColumns() {
		const { REPORT_FORMAT } = GLOBAL;
		let newTableColumns = [];
		let columns = {
			"nombre": { title: "Nombre", field: "nombre", show: true, width: 250 },
			"apellido": { title: "Apellido", field: "apellido", show: true, width: 250 },
			"cuit": { title: "CUIT", field: "cuit", show: true },
			"email": { title: "Email", field: "email", show: true },
			//"fechaCreacion": { title: "Fecha de creación", field: "fechaCreacion", format: REPORT_FORMAT.FECHA, show: true },
			"estado": { title: "Estado", field: "estado", show: true },
		};
		// Add Columns to Table
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });
		return newTableColumns;
	}
	removeuser(cuit) {
		this.setState({ loadingData: true });
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		let url = new URL(`${PRODUCERURL}api/Producer?cuit=${cuit}`);
		const requestOptions = {
			method: 'DELETE',
			headers: DEFAULT_HEADER.get(),
		};
		fetch(url, requestOptions)
			.then((response) => {
				return QLIBS.authorizeAction(response);
			})
			.then(() => {
				this.getData();
			}).finally(() => { this.setState({ loadingData: false, showDeleteModal: false }); })
	}
	createListTableColumns() {
		const newTableColumns = [this.detailsColumn, ...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}
	getData() {
		this.setState({ loadingData: true });
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};

		fetch(`${PRODUCERURL}api/Producer/Delegados/?userId=${userAuth.userId()}`, requestOptions)
			.then((response) => {
				return QLIBS.authorizeAction(response);
			})
			.then(response => {
				if (Array.isArray(response)) {
					let data = response.map(function (item) {
						return {
							...item,
							inputName: item.inputName,
						}
					});
					this.setState({ tableData: data })
				}
				else {
					// show error
				}
			})
			.then(this.setState({ loadingData: false, showGrid: true }))

	}
	componentDidMount() {
		this.getData();
	}
	componentDidUpdate() {
	}

	createNewUser() {
		this.setState({ showModal: true })
	}
	hideModal = (input) => {
		this.setState({ showModal: false })
	}
	saveUser = async (input) => {
		
		if (!input 
			|| this.state.user.cuit === undefined 
			|| this.state.user.nombre === undefined 
			|| this.state.user.apellido === undefined) {
			this.setState({ showModal: true, alertMessage: 'Por favor, complete todos los campos.', error: true });
			this.hideModal()
			return
		}

		if(!checkEmail(this.state.user.email)){
			this.setState({ showModal: true, alertMessage: 'El email ingresado no es válido.', error: true });
			this.hideModal()
			return;
		}

		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				Application: GLOBAL.APPNAME,
				Name: input.nombre,
				LastName: input.apellido,
				CUIT: input.cuit?.replace(/-/g, ""),
				Email: input.email,
				Telephone: input.telephone,
				TelephoneCompany: input.compania,
				reportTo: userAuth.userId(),
				Subusuario: true,
				ReportToName: userAuth.userName(),
				Rol: 'Gestor'
			})
		};

		try {
			this.setState({ loadingData: true });
			await fetch(PRODUCERURL + "api/login/register", requestOptions).then(x=> x.json()).then(response => {
				debugger
				if (response.success) {
					this.getData();
					this.setState({ showModal: true, alertMessage: 'Subusuario creado de forma correcta.', error: false });
				}
				else {
					this.setState({ showModal: true, alertMessage: response.businessExceptions[0].message, error: true });
				}
			}).catch(error=>{
				this.setState({ showModal: true, alertMessage: error.businessExceptions[0].message, error: true });
			});
		} catch {
			this.setState({ showModal: true, alertMessage: 'Error al contactar al servidor', error: true });
		}
		finally {
			this.setState({ loadingData: false });
			this.hideModal()
		}
	}

	handleResponseError(response) {
		response.text()
			.then((err) => {
				debugger
				this.setState({ alertMessage: err, error: true });
			});
	}

	render() {
		const { PAGES } = GLOBAL;
		return (
			<>
				<Loading visible={this.state.loadingData}></Loading>
				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/delegado" />
						</div>
						<div className="col-12">
							<h4 className="form-title">{PAGES.TITLE.DELEGADO}</h4>
						</div>
						<div className="form-container container table-view col-md-12 mt-2">

							{this.state.showDeleteModal && <ConfirmationModal
								message={GLOBAL.TABLE_VIEW.DELETE_MODAL_SUBUSER}
								status="primary"
								title={'Eliminar subusuario'}
								onDismiss={() => this.setState({ showDeleteModal: false })}
								onCancel={() => this.setState({ showDeleteModal: false })}
								onConfirm={() => this.removeuser(this.state.userToDelete)}
							/>
							}

							{this.state.showModal && <NewUserModal
								status="primary"
								title="Agregar nuevo usuario"
								producer='true'
								onDismiss={this.hideModal}
								onConfirm={this.saveUser}
								user={this.state.user}
							></NewUserModal>}
							<div className="row pt-4">
								{(this.state.alertMessage) && (
									<div className="col-12 px-0">
										<Alert
											icon="info"
											noDetail={true}
											type={this.state.error ? 'error' : 'success'}
											title={this.state.error ? 'Error: ' : '¡Hecho! '}
											message={this.state.alertMessage}
										/>
									</div>
								)}
							</div>

							<div className="row col-12 my-3">
								<div className="search-buttons">
									<Button type="button" id="btnNuevo" onClick={() => { this.createNewUser() }} classes="to-bottom button-small search-button"><span className="fa fa-plus"> </span> Nuevo usuario</Button>
								</div>
							</div>
							<div className="row my-3">
								<div className="col-12 px-3">
									<div className="col-12 mb-2">
										{<TableComponent
											columns={this.listTableColumns}
											data={this.state.tableData}
										/>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default Delegado;