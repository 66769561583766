import React from "react";
import recoverSuccessImage from '../../Resources/Images/FormImages/login-form.png';
import { Alert, Input, Button, FormTitle, Header, SubTitle} from "../../Components";
import InputCheck from '../../Resources/Images/Logos/input-check.png';
import InputError from '../../Resources/Images/Logos/input-error.png';
import { GLOBAL} from "../../Config/global";
import { FORM_ERRORS } from "../../Config/form_errors";
import { APPCONFIG } from "../../app.config";
import {Redirect, withRouter} from "react-router-dom";
import userAuth from "../../Config/userauth";
import { checkPassword, ValidarCuit, validarEmail } from "../../Shared/Utils/form_validation";

class RecoverReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      error:'',
      redirect: null
    };

    this.cuit = "";
    this.password = "";
    this.confirmacion = "";
    this.formError = false;

    this.cuitError = "";
    this.cuitErrorIcon = "";
    this.cuitErrorBorderColor = "";
    this.passwordError = "";
    this.passwordErrorIcon = "";
    this.passwordErrorBorderColor = "";
    this.confirmacionError = "";
    this.confirmacionErrorIcon = "";
    this.confirmacionErrorBorderColor = "";

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);

     //Si link no tiene código de recupero recibido en mail lo redirije a recupero de contraseña para nuevo envio de código
     if (!new URLSearchParams(window.location.search).has("code"))
       //document.location.href = "/recover-password";
       this.setState({
         redirect: "/recover-password"
       });

       this.isSimulador = false;
       if (new URLSearchParams(window.location.search).has("simulador")){
        this.isSimulador = true;
       }
  }

  handleKeyDown = event => {
    // Chek which field
    switch(event.target.id){
      case "password":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      case "confirmacion":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      default:
        // Do Nothing
        break;
    }
  };

  handleChange = event => {




    // Chek which field
    switch(event.target.id){
      case "cuit":
        // Remove "-"
        this.cuit = (event.target.value) ? event.target.value.replace(/-/g, "") : "";
        this.cuit = this.cuit.trim();

        if (this.isSimulador === false && this.cuit.length === 11 && ValidarCuit(this.cuit)){
          this.cuitError = "";
          this.cuitErrorBorderColor = "success";
          this.cuitErrorIcon = InputCheck;
        } else if (this.isSimulador && validarEmail(this.cuit)){
          this.cuitError = "";
          this.cuitErrorBorderColor = "success";
          this.cuitErrorIcon = InputCheck;
        } else {
          this.cuitError = "";
          this.cuitErrorBorderColor = "";
          this.cuitErrorIcon = "";
        }
      break;

      case "password":
        this.passwordErrorIcon = "";
        this.passwordErrorBorderColor = "";
      break;

      case "confirmacion":
        this.confirmacionErrorIcon = "";
        this.confirmacionErrorBorderColor = "";
      break;

      default:
        // Do Nothing
      break;
    }

    this.setState({ name: this.state.name})
  };

  handleInputBlur = event => {


    const { COMMON_ERRORS} = FORM_ERRORS;
    // Chek which field

    if (event.target.id === "cuit") {
      this.cuit = (event.target.value) ? event.target.value.replace(/-/g, "") : "";
      this.cuit = this.cuit.trim();
      if (event.target.value.length <= 0){
        this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
        this.cuitErrorIcon = InputError;
        this.cuitErrorBorderColor = "error";
      }else{
        if (this.isSimulador === false && this.cuit.length === 11 && ValidarCuit(this.cuit)){
          this.cuitError = "";
          this.cuitErrorBorderColor = "success";
          this.cuitErrorIcon = InputCheck;
        } else if (this.isSimulador && validarEmail(this.cuit)){
          this.cuitError = "";
          this.cuitErrorBorderColor = "success";
          this.cuitErrorIcon = InputCheck;
        } else {
          // Validate data
          this.cuitValid = ValidarCuit(this.cuit);
          this.cuitErrorIcon = InputError;
          this.cuitErrorBorderColor = "error";
          this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;

        }
      }
    } else {
    }

    this.setState({ name: this.state.name});
  };

  handleSubmit = event => {


    const { SECURITYURL } = APPCONFIG.ENDPOINTS;
    const { COMMON_ERRORS} = FORM_ERRORS;

    event.preventDefault();
    new FormData(event.target);

    this.cuit = event.target.cuit.value;
    this.password = event.target.password.value;
    this.confirmacion = event.target.confirmacion.value;

    this.cuitError = "";
    this.passwordError = "";
    this.confirmacionError = "";
    this.formError = "";

    this.cuitErrorIcon = "";
    this.passwordErrorIcon = "";
    this.confirmacionErrorIcon = "";

    this.cuitErrorBorderColor = "";
    this.passwordErrorBorderColor = "";
    this.confirmacionErrorBorderColor = "";

    this.loadingForm = false;

    // First validation
    if (this.isSimulador === false && (!this.cuit || this.cuit.length < 11)){
      this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.password){
      this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.confirmacion){
      this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }

    if (this.isSimulador === false && !this.cuitError){
      // Remove "-"
      this.cuit = (this.cuit) ? this.cuit.replace(/-/g, "") : "";

      // Validate data
      if (!ValidarCuit(this.cuit)){
        this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;
      }
    } else if (this.isSimulador && !validarEmail(this.cuit)){
      this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMAIL_INVALID;
    }
    if (!this.passwordError){
      if (this.password.length < 6){
        this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
      }else if (!checkPassword(this.password)){
        this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
      }
    }
    if (!this.confirmacionError){
      if (this.confirmacion.length < 6){
        this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
      }else if (!checkPassword(this.password)){
        this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
      }else if (this.password !== this.confirmacion){
        this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_DONT_MATCH;
      }
    }

    if (this.cuitError || this.passwordError || this.confirmacionError){
      this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
      this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
      this.passwordErrorIcon = (this.passwordError) ? InputError : InputCheck;
      this.passwordErrorBorderColor = (this.passwordError) ? "error" : "success";
      this.confirmacionErrorIcon = (this.confirmacionError) ? InputError : "";
      this.confirmacionErrorBorderColor = (this.confirmacionError) ? "error" : "success";
      this.loadingForm = false;
      this.setState({ name: this.state.name})
    }else{
      this.cuitErrorIcon = InputCheck;
      this.cuitErrorBorderColor = "success";
      this.passwordErrorIcon = InputCheck;
      this.passwordErrorBorderColor = "success";
      this.confirmacionErrorIcon = InputCheck;
      this.confirmacionErrorBorderColor = "success";
      this.loadingForm = true;
      this.setState({ name: this.state.name});
      if (!new URLSearchParams(window.location.search).has("code")){
        //document.location.href = "/recover-password";
        this.setState({
          redirect: "/recover-password"
        });
        return;
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            Cuit : this.cuit,
            Password : this.password,
            ConfirmPassword : this.confirmacion,
            Code: new URLSearchParams(window.location.search).get("code")
        })
      };

      return fetch(SECURITYURL + "account/resetpassword", requestOptions)
            .then(this.handleResponse)
            .catch(this.handleResponse);
    }
  };

  handleResponse(response){




    if (response && response.ok){
      //document.location.href = "../login?recover-success";
      if (this.isSimulador === false){
        this.setState({
          redirect: "/login?recover-success"
        });
      }
      else {
        userAuth.setUserEmailConfirmed(true);
            userAuth.getStorage().removeItem('userCode');
        this.setState({
          redirect: "/simulate-producer"
        });
      }
    }else{
       response.text().then(result => {
          this.setState({
          error: result
      })});
    }

    this.loadingForm = false;
    this.setState({ name: this.state.name})
  }

  render(){
    const { COMPONENTS_FORM} = GLOBAL;
    const { RECOVER_RESET } = GLOBAL;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <section>
        <Header centered/>
        <section className="MainContainer recover-reset">
          <section className="container MainContainer__Center">
            <div className="row MainContainer__Card rounded">
              <div className="col-lg-6 px-0 d-none d-lg-block">
                <img src={ recoverSuccessImage } className="img-fluid" alt="Hermanos en un campo" />
              </div>
              <div className="col-lg-1 recover-reset__Back d-md-block d-none">

              </div>
              <div className="col-lg-4 my-auto">
              {
                  (this.passwordError || this.confirmacionError) &&
                  <div id="form-error-message" className="row my-2">
                    <div className="col-12">
                      <Alert
                        icon="times"
                        message={ COMPONENTS_FORM.ALERT_ERROR_MSG }
                        title={ COMPONENTS_FORM.ALERT_ERROR_TITLE }
                        type="errorMessage"
                      />
                    </div>
                  </div>
                }
                {
                  (this.state.error) &&
                  <div id="form-error-message" className="row my-2">
                    <div className="col-12">
                      <Alert
                        icon="times"
                        message={this.state.error}
                        title={ COMPONENTS_FORM.ALERT_ERROR_TITLE }
                        type="errorMessage"
                      />
                    </div>
                  </div>
                }
                <form id="recover_password_reset" onSubmit={ this.handleSubmit } className="recover-reset__Form px-0">
                  <FormTitle
                    text={RECOVER_RESET.TITLE}
                  />
                  <SubTitle>{ RECOVER_RESET.TITLE_MESSAGE }</SubTitle>
                  {this.isSimulador === false && (<Input
                    id="cuit"
                    type="text"
                    text={ RECOVER_RESET.CUIT }
                    defaultValue=""
                    mask="99-99999999-9"
                    borderColor={ this.cuitErrorBorderColor }
                    imgSrc={ this.cuitErrorIcon }
                    errorMsg={ this.cuitError }
                    onBlur={this.handleInputBlur}
                    onChange={this.handleChange}
                  />)}
                  {this.isSimulador && (<Input
                    id="cuit"
                    type="email"
                    text={"E-mail"}
                    defaultValue={""}
                    // mask="99-99999999-9"
                    borderColor={this.cuitErrorBorderColor}
                    imgSrc={this.cuitErrorIcon}
                    errorMsg={this.cuitError}
                    onBlur={this.handleInputBlur}
                    onChange={this.handleChange}
                  />)}
                  <Input
                    id="password"
                    type="password"
                    text={ RECOVER_RESET.NEW_PASSWORD }
                    defaultValue=""
                    maxLength="15"
                    smallPrint={ RECOVER_RESET.PASS_INSTRUCTIONS }
                    borderColor={ this.passwordErrorBorderColor }
                    imgSrc={ this.passwordErrorIcon }
                    errorMsg={ this.passwordError }
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                  />
                  <Input
                    id="confirmacion"
                    type="password"
                    text={ RECOVER_RESET.NEW_PASSWORD_CONFIRM }
                    defaultValue=""
                    maxLength="15"
                    smallPrint={ RECOVER_RESET.CONFIRM_INSTRUCTIONS }
                    borderColor={ this.confirmacionErrorBorderColor }
                    imgSrc={ this.confirmacionErrorIcon }
                    errorMsg={ this.confirmacionError }
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                  />
                  <Button type="submit">{ RECOVER_RESET.BUTTON_TEXT }</Button>
                </form>
              </div>
              <div className="col-lg-1 recover-reset__Back d-md-block d-none" />
            </div>
          </section>
        </section>
        {/*<Footer/>*/}
      </section>
    );
  }
}

export default withRouter(RecoverReset);
