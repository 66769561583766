/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Header, Button, Alert, Loading, Typeahead } from '../../Components';
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from '../../Config/global';
import { FORM_ERRORS } from "../../Config/form_errors";
import { Redirect, withRouter } from "react-router-dom";
import userAuth from '../../Config/userauth';
import loginImageDesktop from "../../Resources/Images/FormImages/pantalla-login.jpg";
import toolsImg from "../../Resources/Images/FormImages/tools.png";
import * as utils from '../../Shared/Utils';
import { ValidarCuit } from '../../Shared/Utils/form_validation';

export class SimulateProducer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingForm: false,
            error: false,
            redirect: null,
            cuit: '',
            parent: [],
            child: []
        }
        this.errorCuit = '';
        this.errorNombre = '';
        this.errorCuitBorderColor = '';
        this.errorNombreBorderColor = '';

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    componentDidMount() {
        if (userAuth.requestSelectProducer() === false) {
            userAuth.cleanSimulator();
        }
    }

    handleChange = event => {
        const { COMMON_ERRORS } = FORM_ERRORS;

        let isCheckbox = event.target.type === "checkbox";
        let inputName = event.target.name;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;

        this.errorCuit = '';
        this.errorCuitBorderColor = '';

        /// SETEO DE ERRORES

        switch (inputName) {
            case "cuit":
                let cuit = (inputValue) ? inputValue.replace(/-/g, "") : "";
                cuit = cuit.trim();
                this.errorCuit = (cuit.length !== 11 || ValidarCuit(cuit) === false) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID : "";
                this.errorCuitBorderColor = (this.errorCuit) ? "error" : "success";
                this.errorNombreBorderColor = (this.errorCuit) ? "error" : "success";
                break;
            default:
                break;
        }

        this.setState({ [inputName]: inputValue }, () => { console.log(this.state) });
    }

    validate() {
        const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;

        this.errorCuit = '';
        this.errorNombre = '';
        this.errorCuitBorderColor = '';
        this.errorNombreBorderColor = '';

        let cuit = (this.state.cuit) ? this.state.cuit.replace(/-/g, "") : "";
        cuit = cuit.trim();
        if (cuit.length !== 11 || ValidarCuit(cuit) === false) {
            this.errorCuit = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;
        }
        this.errorCuitBorderColor = (this.errorCuit) ? "error" : "success";

        /// autocomplete productor nombre
        if (document.getElementsByTagName('input')[2].value === '') {
            this.errorNombre = COMMON_ERRORS.INITIAL_MARKER + CUSTOM_ERRORS.SIMULAR_PRODUCTOR_NOMBRE_ERROR;
        }
        this.errorNombreBorderColor = (this.errorNombre) ? "error" : "success";

        if (this.errorCuit !== "" || this.errorNombre !== "") {
            this.setState({ redirect: null });
            return false;
        }
        return true;
    }

    handleSubmit = event => {
        event.preventDefault();

        if (this.validate()) {
            this.setState({ loadingForm: true }, () => {
                QLIBS.fetchDataPOST(APIS.URLS.SIMULATE_PRODUCER, { Cuit: this.state.cuit, UserId: userAuth.userId() }, true)
                    .then(this.handleResponse)
                    .catch(this.handleResponse);
            });
        }
    }

    handleResponse(response) {
        if (response && response.success && !response.businessExceptions.length) {
            userAuth.setUserIdProducer(response.userId, response.name, this.state.cuit, response.sysData);
            this.setState({
                redirect: "/home"
            });
        }
        else {
            this.setState({
                error: response.businessExceptions?.map(x => x.message.replace('<br/>', '\n')).join(""),
                redirect: null,
                loadingForm: false
            }, () => console.log(this.state));
        }
    }

    onKeyDown = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            document.getElementById("btnSubmit").click();
        }
    }

    onClickAdvanced = () => {
        if (userAuth.isAuthenticated()) {
            utils.setStorage('menuAdvanced', true);
            this.setState({
                redirect: "/avanzadas"
            });
        } else {

        }
    }

    render() {
        const { SIMULATE_FORM, PAGES, COMMON_SEARCH_FORM } = GLOBAL;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <section>
                <Loading visible={this.state.loadingForm}></Loading>
                <Header
                    {...this.props.history}
                    showBackButton={false}
                    showNotificationPanel
                    showProfileBadge
                    showNavbar={false}
                    showHamburgerMenu
                    token={true}
                />
                <section>
                    <div className='container'>
                   
                        <div className='opciones'>
                            
                            <div className="simulate-opcion">

                           <img
                                    src={loginImageDesktop}
                                    alt={SIMULATE_FORM.TITLE}
                                />
                               

                               

                           
                                {
                                    (this.state.error) &&
                                    <div id="form-error-message" className="col-12 my-2">
                                        <div className="col-12">

                                            <Alert
                                                icon="times"
                                                message={this.state.error}
                                                title={SIMULATE_FORM.ERROR_SIMULATE_PRODUCER}
                                                type="errorMessage"
                                            />
                                        </div>
                                    </div>
                                }
                                
                                <form id='simular-form' onSubmit={this.handleSubmit} onKeyDown={this.onKeyDown} noValidate>
                                    <h3>{PAGES.TITLE.SIMULATEPRODUCER} </h3>
                                    <p>Ingresa a la web como si fuera un productor</p>
                                    <br />
                                    <Typeahead
                                        id="cuit"
                                        label={COMMON_SEARCH_FORM.PRODUCTOR_CUIT}
                                        placeholder={COMMON_SEARCH_FORM.PRODUCTOR_CUIT_PLACEHOLDER}
                                        SEARCH_URI={APIS.URLS.PRODUCER_AUTOCOMPLETE_BY_CUIT}
                                        selected={this.state.parent}
                                        onSelected={(selected) => {
                                            let array = [{ id: selected.id, value: selected.value }];
                                            this.setState({ parent: array, child: array });
                                            this.handleChange({ target: { name: 'cuit', value: selected.id } });
                                        }}
                                        onClean={() => { this.setState({ parent: [], cuit: '' }) }}
                                        labelKey={"id"}
                                        errorMsg={this.errorCuit}
                                        borderColor={this.errorCuitBorderColor}
                                    />
                                    <Typeahead
                                        id="nombre"
                                        label={COMMON_SEARCH_FORM.PRODUCTOR_NOMBRE}
                                        placeholder={COMMON_SEARCH_FORM.PRODUCTOR_NOMBRE_PLACEHOLDER}
                                        SEARCH_URI={APIS.URLS.PRODUCER_AUTOCOMPLETE_BY_NAME}
                                        selected={this.state.child}
                                        onSelected={(selected) => {
                                            let array = [{ id: selected.id, value: selected.value }];
                                            this.setState({ parent: array, child: array });
                                            this.handleChange({ target: { name: 'cuit', value: selected.id } });
                                        }}
                                        onClean={() => { this.setState({ child: [], cuit: '' }) }}
                                        labelKey={"value"}
                                        errorMsg={this.errorNombre}
                                        borderColor={this.errorNombreBorderColor}
                                    />
                                    <Button type="submit" id="btnSubmit">{SIMULATE_FORM.SUBMIT_BUTTON}</Button>

                                </form>
                               
                            </div>
                            <div className="opcion-form" >
                            
                            <img
                                    src={toolsImg}
                                />
                                <h3>{PAGES.TITLE.OPCIONES_AVANZADAS} </h3>
                                <p>Permite restablecer contraseña, agregar nuevos usuarios, etc.</p>
                                <Button type="button" onClick={this.onClickAdvanced}>Avanzado</Button>
                            </div>
                        </div>
                    </div>
                    
                </section>
            </section>
        )
    }
}

export default withRouter(SimulateProducer);