import React from 'react';
import { string } from 'prop-types';

const Notification = ({ icon, date, text }) => (
  <div className="row notification-tile px-0 mx-2">
    <div className="col-1 d-flex justify-content-center align-items-center p-0">
      <i className={icon} />
    </div>
    <div className="col-11 notification-tile__details">
      <p className="notification-tile__header">
        <small>{date}</small>
      </p>
      <p className="notification-tile__content mb-1">{text}</p>
    </div>
  </div>
);

Notification.propTypes = {
  date: string.isRequired,
  icon: string.isRequired,
  text: string.isRequired,
};

export default Notification;
