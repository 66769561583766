import React, { Component } from 'react'
import { UploadFileRepository, TabsPanel, Loading, TextArea, Button, Checkbox, Input, Alert } from '..';
import { GLOBAL } from '../../Config/global';
import { APPCONFIG } from '../../app.config';
import { DEFAULT_HEADER } from '../../Config/userToken';
import * as QLIBS from '../../qLibsHelpers';
export default class NotificationDefinitionModal extends Component {
    constructor(props) {
        super(props)

		this.tabs = [
			{
				label: 'Definición de notificación por aviso web'
			},
			{
				label: 'Definición de notificación por e-mail'
			}
		];

        this.state = {
            id: this.props.id,
            loadingData: false,
            showForm: false,
            errorMessage: null,
            entity: null,
            activeTab: 0
        }

        this.getNotificacionDefinition = this.getNotificacionDefinition.bind(this);
        this.clearNotificacionDefinition = this.clearNotificacionDefinition.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleNotificationAttach = this.handleNotificationAttach.bind(this);
        this.refreshFilesNotification = this.refreshFilesNotification.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        if (this.state.id === 0) {
            this.clearNotificacionDefinition(); 
        }
        else {
           this.getNotificacionDefinition(this.state.id); 
        }

    }

	getNotificacionDefinition = (id) => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		this.setState({loadingData: true, showForm: false, errorMessage: null, entity: null });

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};			
		return fetch(`${PRODUCERURL}api/notification/getnotificationdefinition?Id=${id}`, requestOptions)
		.then((response) => {

			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then((response) => {
                    const entity = response;
                    this.setState({ loadingData: false, showForm: true, entity: entity });
                })
				.catch((response) => {
                    const message = 'Error procesando datos';
                    this.setState({ loadingData: false, showForm: false, errorMessage: message });
                });
			}
			else {
				const message = `Error procesando solicitud: ${response.statusText}`;
				this.setState({ loadingData: false, showForm: false, errorMessage: message });
			}

		});
	}

    refreshFilesNotification = (id) => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		this.setState({loadingData: true, showForm: false, errorMessage: null });

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};			
		return fetch(`${PRODUCERURL}api/notification/getnotificationdefinition?Id=${id}`, requestOptions)
		.then((response) => {

			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then((response) => {
                    let entity = {...this.state.entity};
                    entity.webLink = response.webLink;
                    this.setState({ loadingData: false, showForm: true, entity: entity });
                })
				.catch(() => {
                    const message = 'Error subiendo archivo';
                    this.setState({ loadingData: false, showForm: true, errorMessage: message });
                });
			}
			else {
				const message = `Error procesando solicitud: ${response.statusText}`;
				this.setState({ loadingData: false, showForm: true, errorMessage: message });
			}

		});
	}

    clearNotificacionDefinition = () => {
        const entity = {
            urlApi: '',
            description: '',
            editMessage: true,
            editDestination: true,
            editScheduler: true,
            webUse: false,
            webTitle: null,
            webText: null,
            webLink: null,
            mailUse: false,
            mailSubject: null,
            mailBody: null,
            enabled: false
        };
        this.setState({ loadingData: false, showForm: true, entity: entity });
    }

    validar() {
        const entity = {...this.state.entity};
        if (!entity.description ||
            entity.webUse && (!entity.webTitle || !entity.webText) ||
            entity.mailUse && (!entity.mailSubject || !entity.mailBody)) {
            this.setState({ errorMessage: 'Debe completar todos los campos obligatorios' });
            return false;
        }

        return true;
    }


    handleSubmit = (isNew) => {

        if (this.validar()) {
            const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

            let data = {...this.state.entity};
            
            //normalizo los campos que no se usan
            if (!data.webUse) {
                data.webTitle = null;
                data.webText = null;
                data.webLink = null;
            }
            else {
                if (data.webLink == null) data.webLink = '';
            }
            if (!data.mailUse) {
                data.mailSubject = null;
                data.mailBody = null;
            }

            this.setState({loadingData: true, showForm: false, errorMessage: null });

            const requestOptions = {
                method: 'POST',
                headers: DEFAULT_HEADER.get(),
                body: JSON.stringify(data)
            };			
            return fetch(`${PRODUCERURL}api/notification/savenotificationdefinition`, requestOptions)
            .then((response) => {

                if (response.ok) {
                    QLIBS.authorizeAction(response)
                    .then((id) => {
                        if (isNew) {
                            this.setState({ id: id, loadingData: false, entity: null, errorMessage: null }, () => {
                                this.getNotificacionDefinition(this.state.id);
                            });
                        }
                        else {
                            this.setState({ loadingData: false, showForm: false, entity: null, errorMessage: null });
                            this.props.dismiss(true);
                        }
                    })
                    .catch((response) => {
                        const message = 'Error procesando datos';
                        this.setState({ loadingData: false, showForm: false, errorMessage: message });
                    });
                }
                else {
                    const message = `Error procesando solicitud: ${response.statusText}`;
                    this.setState({ loadingData: false, showForm: false, errorMessage: message });
                }

            });
        }

    }

    handleInputChange = event => {
        const isCheckbox = event.target.type === "checkbox";

        let inputName = event.target.name ? event.target.name : event.target.id;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;

        let entity = {...this.state.entity};
        switch (inputName) {
            case "ctlDescription":
                entity.description = inputValue;
                break;
            case "ctlWebUse":
                entity.webUse = inputValue;
                break;
            case "ctlWebTitle":
                entity.webTitle = inputValue;
                break;
            case "ctlWebText":
                entity.webText = inputValue;
                break;
            case "ctlWebLink":
                entity.webLink = inputValue;
                break;
            case "ctlMailUse":
                entity.mailUse = inputValue;
                break;
            case "ctlMailSubject":
                entity.mailSubject = inputValue;
                break;
            case "ctlMailBody":
                entity.mailBody = inputValue;
                break;
            case "ctlEnabled":
                entity.enabled = inputValue;
                break;           
            default:
                break;
        }

        this.setState({ entity: entity });
    };


    handleNotificationAttach = () => {
        this.refreshFilesNotification(this.state.id);
    }


    onChangeTab = (index) => {
		this.setState({ activeTab: index });
	}


    render() {
        const { COMMON_MODAL_FORM } = GLOBAL;

        return (
		    <>
                <Loading visible={this.state.loadingData}></Loading>

                <div id="form-notification" className="modal" tabIndex="-1" role="dialog"  aria-modal="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title text-center">Definición de Notificación</h4>
                            </div>
                            <div className="modal-body">

                                {this.state.errorMessage && (<Alert type="error" title="ERROR" message={this.state.errorMessage} noDetail={true} />)}
                                {this.state.showForm && (
                                 <section>
                                     <div className="row pb-4">
                                        <div className="col-12 pt-3 text-left">
                                            <Checkbox
                                                id="ctlEnabled"
                                                text="Habilitar el envio de notificación"
                                                checked={this.state.entity.enabled}
                                                onChange={this.handleInputChange}
                                                smallPrint=""
                                                disabled={(this.state.id == 0)}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <Input
                                                id="ctlDescription"
                                                type="text"
                                                text={"Descripción (*)"}
                                                defaultValue={this.state.entity.description}
                                                classes="mb-0"
                                                maxLength="1000"
                                                disabled={!this.state.entity.editMessage}
                                                onChange={this.handleInputChange}
                                                smallPrint="Es la descripción general de la notificación"
                                            />
                                        </div>
                                     </div>
                                    <div className="row pb-4">
                                        <div className="col-12">
                                        <TabsPanel options={this.tabs} tabActive={this.state.activeTab} onChange={this.onChangeTab}>
											<div id="tab1" className="container option-1 xNotificationTab">
                                                <div className="col-12 pt-3 text-left">
                                                    <Checkbox
                                                        id="ctlWebUse"
                                                        text="Aplicar notificación por aviso web"
                                                        checked={this.state.entity.webUse}
                                                        onChange={this.handleInputChange}
                                                        disabled={!this.state.entity.editMessage}
                                                        smallPrint=""
                                                    />
                                                </div>
												<div className="col-12">
                                                    <Input
                                                        id="ctlWebTitle"
                                                        type="text"
                                                        text={"Título del aviso (**)"}
                                                        defaultValue={this.state.entity.webTitle}
                                                        classes="mb-0"
                                                        maxLength="1000"
                                                        disabled={!(this.state.entity.editMessage && this.state.entity.webUse)}
                                                        onChange={this.handleInputChange}
                                                    />
												</div>
												<div className="col-12 pt-3">
                                                    <label for="ctlWebText">Contenido del aviso (**)</label>
                                                    <TextArea
                                                        id="ctlWebText"
                                                        text={"Escriba aquí el contenido del aviso..."}
                                                        defaultValue={this.state.entity.webText}
                                                        maxLength={1000}
                                                        disabled={!(this.state.entity.editMessage && this.state.entity.webUse)}
                                                        onChange={this.handleInputChange}
                                                    />
												</div>
                                                {this.state.id != 0 && (
												<div className="col-12 pt-3">
                                                    <Input
                                                        id="ctlWebLink"
                                                        type="text"
                                                        text={"Link al documento adjunto"}
                                                        defaultValue={this.state.entity.webLink}
                                                        classes="mb-0"
                                                        maxLength="1000"
                                                        //onChange={this.handleInputChange}
                                                        readOnly={true}
                                                        smallPrint="Es un link al documento adjunto de la notificación"
                                                    />
                                                    <UploadFileRepository
                                                        entity="NotificationAttach"
                                                        entityId={this.state.entity.id.toString()}
                                                        multiple={false}
                                                        callbackDone={() => this.handleNotificationAttach()}
                                                    />
												</div>
                                                )}
											</div>
											<div id="tab2" className="container option-2 xNotificationTab">
                                                <div className="col-12 pt-3 text-left">
                                                    <Checkbox
                                                        id="ctlMailUse"
                                                        text="Aplicar notificación por e-mail"
                                                        checked={this.state.entity.mailUse}
                                                        onChange={this.handleInputChange}
                                                        disabled={!this.state.entity.editMessage}
                                                        smallPrint=""
                                                    />
                                                </div>
												<div className="col-12">
                                                    <Input
                                                        id="ctlMailSubject"
                                                        type="text"
                                                        text={"Asunto del mail (***)"}
                                                        defaultValue={this.state.entity.mailSubject}
                                                        classes="mb-0"
                                                        maxLength="1000"
                                                        disabled={!(this.state.entity.editMessage && this.state.entity.mailUse)}
                                                        onChange={this.handleInputChange}
                                                    />
												</div>
												<div className="col-12 pt-3">
                                                    <label for="ctlMailBody">Cuerpo del mail (***)</label>
                                                    <TextArea
                                                        id="ctlMailBody"
                                                        text={"Escriba aquí el contenido del aviso..."}
                                                        defaultValue={this.state.entity.mailBody}
                                                        maxLength={1000}
                                                        disabled={!(this.state.entity.editMessage && this.state.entity.mailUse)}
                                                        onChange={this.handleInputChange}
                                                    />
												</div>
											</div>
										</TabsPanel>
                                        </div>
                                    </div>
                                    <div className="pb-4 xMensajeAclarativo">
                                        <label className="xMensajeAclarativo">(*) obligatorio</label><br />
                                        <label className="xMensajeAclarativo">(**) obligatorio si aplica notifiación por envio web</label><br />
                                        <label className="xMensajeAclarativo">(***) obligatorio si aplica notifiación por e-mail</label><br />
                                    </div>
                                 </section>
                                )}
                            
                            </div>
                            <div className="modal-footer">
                                <Button type="button" size="medium-lg" fill="outline" color="secondary" data-dismiss="modal" onClick={() => { this.props.dismiss(false) }}>{COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                                {this.state.showForm && this.state.id != 0 && (
                                    <Button type="button" size="medium-lg" fill="outline" onClick={() => { this.handleSubmit(false) }}>{COMMON_MODAL_FORM.SAVE_BUTTON}</Button>
                                )}
                                {this.state.showForm && this.state.id == 0 && (
                                    <Button type="button" size="medium-lg" fill="outline" onClick={() => { this.handleSubmit(true) }}>{COMMON_MODAL_FORM.GO_BUTTON}</Button>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
