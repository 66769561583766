import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import DeudoresXPremioStep1 from '../../Resources/Images/Tutoriales/DeudoresXPremio/DeudoresXPremio-1.jpg';
import DeudoresXPremioStep2 from '../../Resources/Images/Tutoriales/DeudoresXPremio/DeudoresXPremio-2.jpg';

const DeudoresXPremio = props => {
    return (
        <div>
            <ReactWizard
                steps={[
                    {
                        stepName: "PASO 1", component: <StepTutorial image={DeudoresXPremioStep1} text={[
                            'Seleccionar productor',
                            'Seleccionar fecha de consulta',
                            'Hacer click en el botón “Buscar” para generar el listado'
                        ]} />
                    },
                    {
                        stepName: "PASO 2", component: <StepTutorial image={DeudoresXPremioStep2} text={['Al hacer click en el botón “Descargar” podrás exportar el listado a Excel']} />
                    }
                ]}
                navSteps
                title="Paso a paso para acceder al módulo de Deudores por Premio."
                headerTextCenter
                validate
                color="green"
                previousButtonText="Volver"
                nextButtonText="Continuar"
                finishButtonText="Finalizar"
                finishButtonClick={() => { props.onFinish(); }}
            />
        </div>
    )
}

DeudoresXPremio.propTypes = {

}

export default DeudoresXPremio
