/* eslint-disable import/named */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/no-named-as-default */
import React from 'react'
import {Link, Redirect, withRouter} from "react-router-dom";
import {Loading, Alert, Input, Button, FormTitle, Header, BackButton, SubTitle, ConfirmationModal } from "../../Components";
import recoverImage from '../../Resources/Images/FormImages/recover-form.png'
import InputCheck from '../../Resources/Images/Logos/input-check.png';
import InputError from '../../Resources/Images/Logos/input-error.png';
import { GLOBAL, IDENTITY_CONFIG } from "../../Config/global";
import { FORM_ERRORS } from "../../Config/form_errors";

class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      redirect: null
    }

    this.code = "";
    this.formError = false;
    this.formValidated = false;

    this.clicked = false;

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  toggleModal = () => {
    this.clicked = !this.clicked;
    this.setState({ name: this.state.name});
  }

  onConfirm = () => {
    //document.location.href = "/login";
    this.setState({
      redirect: "/login"
    })
  }

  handleKeyDown = event => {
    // Chek which field
    switch(event.target.id){
      case "code":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
      break;

      default:
        // Do Nothing
        break;
    }
  }

  handleChange = event => {
    // Chek which field
    switch(event.target.id){
      case "code":
        if (event.target.value.length >= 8){
          this.codeError = "";
          this.codeErrorIcon = (this.codeError) ? InputError : "";
          this.codeErrorBorderColor = (this.codeError) ? "error" : "success";
        }else{
          this.codeErrorIcon = "";
          this.codeErrorBorderColor = "";
        }
        break;

      default:
        // Do Nothing
        break;
    }

    this.setState({ name: this.state.name});
  }

  handleSubmit = event => {
    const { authority } = IDENTITY_CONFIG;
    const { COMMON_ERRORS } = FORM_ERRORS;

    event.preventDefault();
    const data = new FormData(event.target);

    this.code = event.target.code.value;
    this.formError = false;
    this.formValidated = false;

    this.codeError = ""
    this.codeErrorIcon = ""
    this.codeErrorIconBorderColor = ""

    // First validation
    if (!this.code){
      this.codeError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }

    // Special Validation
    if (!this.codeError && this.code.length < 8){
      this.nombreError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_8;
    }

    this.formError = this.codeError;
    if (this.formError){
      this.codeErrorIcon = (this.codeError) ? InputError : InputCheck;
      this.codeErrorBorderColor = (this.codeError) ? "error" : "success";

      this.loadingForm = false;
      this.setState({ name: this.state.name})
    }else{
      this.loadingForm = true;
      this.setState({ name: this.state.name})

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            code : this.code
        })
      };

      return fetch(authority, requestOptions)
        .then(this.handleResponse)
        .catch(this.handleResponse)
      ;
    }
  }

  handleResponse(response){
    const { COMMON_ERRORS } = FORM_ERRORS;

    if (response && response.ok){
      const data = (response ? response.text() : COMMON_ERRORS.REQUEST_DEFAULT);
      var parsedData;
      if (data){
        try{
          parsedData = JSON.parse(data);
        }catch(e){
          // Not JSON data
        }

        //document.location.href = "./register-success";
        this.setState({
          redirect: "/register-success"
        });
      }else{
        const error = (parsedData && parsedData.error && parsedData.error.message);
        this.formError = (error) ? error : "";
        this.setState({
          redirect : null
        });
      }
    }else{
      this.formError = COMMON_ERRORS.REQUEST_DEFAULT;
      this.setState({
        redirect : null
      })
    }

    this.loadingForm = false;
    this.formValidated = true;
    this.setState({ name: this.state.name})
  }

  render(){
    const { COMPONENTS_FORM } = GLOBAL;
    const { RECOVER_FORM } = GLOBAL;
    const { COMMON_ERRORS } = FORM_ERRORS;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <section>
        <Loading visible={this.loadingForm}></Loading>
        <Header centered/>
        <section className="MainContainer recover">
          <section className="MainContainer__Center container">
            <div className="row MainContainer__Card rounded">
              <div className="col-lg-6 px-0 d-none d-lg-block">
                <img src={ recoverImage } className="img-fluid" alt="Recover Form" />
              </div>
              <div className="col-lg-1 recover__Back d-md-block d-none">
                <BackButton />
              </div>
              <div className="col-lg-4 my-auto">
                {
                  (this.formError) &&
                  <div id="form-error-message" className="row my-2">
                    <div className="col-12">
                      <Alert
                        icon="times"
                        message={ COMPONENTS_FORM.ALERT_ERROR_MSG }
                        title={ COMPONENTS_FORM.ALERT_ERROR_TITLE }
                        type="errorMessage"
                      />
                    </div>
                  </div>
                }
                {
                  (this.formValidated && !this.formError) &&
                  <div id="form-error-message" className="row my-2">
                    <div className="col-12">
                      <Alert
                        icon="times"
                        message=""
                        title={ COMMON_ERRORS.REQUEST_DEFAULT }
                        type="errorMessage"
                      />
                    </div>
                  </div>
                }
                <form id="form-recover-code" onSubmit={this.handleSubmit} className="recover__Form">
                  { this.clicked && <ConfirmationModal onDismiss={ this.toggleModal } onConfirm={ this.onConfirm } /> }
                  <FormTitle
                    text={RECOVER_FORM.TITLE_CONFIRM}
                  />
                  <SubTitle>{ RECOVER_FORM.MESSAGE_CONFIRM }</SubTitle>
                  <div className="code-Content">
                    <p className="bold text-center"></p>
                  </div>
                  <Input
                    id="code"
                    type="text"
                    text={RECOVER_FORM.CODE_INPUT}
                    defaultValue=""
                    maxLength="10"
                    errorMsg={ this.codeError }
                    borderColor={ this.codeErrorBorderColor }
                    imgSrc={ this.codeErrorIcon }
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                  />
                  <div className="row">
                    <div className="col-7">
                      <p className="smaller-text">{ RECOVER_FORM.MAIL_QUESTION }</p>
                    </div>
                    <div className="col-5 d-flex justify-content-end pl-0">
                      <Link to="/recover-password" className="link smaller-text">{ RECOVER_FORM.SEND_AGAIN }</Link>
                    </div>
                  </div>
                  <div className="formContainer__formContent-Button-Container">
                    <div className="container px-0">
                      <div className="row">
                        <div className="col-6">
                          <Button type="button" fill="outline" color="secondary" onClick={this.toggleModal}>{ RECOVER_FORM.CANCEL_BUTTON }</Button>
                        </div>
                        <div className="col-6">
                          <Button type="submit">{ RECOVER_FORM.CONTINUE_BUTTON }</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-1 recover__Back d-md-block d-none" />
            </div>
          </section>
        </section>
        {/*<Footer/>*/}
      </section>
    );
  }
}

export default withRouter(RecoverPassword)
