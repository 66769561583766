/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { func, string } from 'prop-types';
import { GLOBAL } from '../../../../Config/global';

const Actions = ({ onDelete, onDownload, onEdit, onViewDetail, onCustom, customIcon, customTitle, content }) => {
  const { COMPONENTS_FORM: { CRUD_ACTIONS } } = GLOBAL;
  return (
    <div className={content?.length > 0 ? 'custom-actions-wrapper' : 'actions-wrapper'}>
      {onEdit && (<><a title={CRUD_ACTIONS.UPDATE} onClick={onEdit}><i className="fas fa-edit" /></a>&nbsp;</>)}
      {onDelete && (<><a title={CRUD_ACTIONS.DELETE} onClick={onDelete}><i className="fas fa-trash-alt" /></a>&nbsp;</>)}
      {onDownload && (<><a title={CRUD_ACTIONS.CUSTOM.DOWNLOAD} onClick={onDownload}><i className="fas fa-file-download" /></a>&nbsp;</>)}
      {onViewDetail && (<><a title={CRUD_ACTIONS.CUSTOM.VIEW_DETAIL} onClick={onViewDetail}><i className="fas fa-info" /></a>&nbsp;</>)}
      {onCustom && (
        <>
          <a > {content}</a>
          <i title={customTitle} onClick={onCustom} className={`fas fa-${customIcon}`} />          
        </>)}
    </div>
  )
}

Actions.propTypes = {
  onDelete: func,
  onDownload: func,
  onEdit: func,
  onViewDetail: func,
  onCustom: func,
  customIcon: string,
  customTitle: string
};

export default Actions;