/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { object } from 'prop-types';
import { Header, IconCard, Carousel, ConfirmationModal, Breadcrumb } from "../../Components";
import { GLOBAL } from '../../Config/global';
import { HOME_DATA } from '../../Config/home-data';

const Terminos = () => {
  const { TERMS } = GLOBAL;

  return (
    <section>
      <Header />

      <section className="MainContainer">
        <section className="container mb-0">
          <br></br>
          <div className="row">
            <div className="sidebar-container offset-md-2 col-md-8">
             <br/>
              <center><b>{ TERMS.TITLE }</b></center>
              <hr></hr>
              <p>{ TERMS.SUBTITLE }</p>
              <br/>
              <p>{ TERMS.PARAGRAPH }</p>
              <p><b>1.</b>{ TERMS.ITEM_1 }</p>
              <p><b>2.</b>{ TERMS.ITEM_2 }</p>
              <p><b>3.</b>{ TERMS.ITEM_3 }</p>
              <p><b>4.</b>{ TERMS.ITEM_4 }</p>
              <p><b>5.</b>{ TERMS.ITEM_5 }</p>
              <p><b>6.</b>{ TERMS.ITEM_6 }</p>
              <p><b>7.</b>{ TERMS.ITEM_7 }</p>
              <p><b>8.</b>{ TERMS.ITEM_8 }</p>
              <p><b>9.</b>{ TERMS.ITEM_9 }</p>
              <p><b>10.</b>{ TERMS.ITEM_10 }</p>
              <p><b>11.</b>{ TERMS.ITEM_11 }</p>
              <p><b>12.</b>{ TERMS.ITEM_12 }</p>
              <p><b>13.</b>{ TERMS.ITEM_13 }</p>
              <p><b>14.</b>{ TERMS.ITEM_14 }</p>
              <p><b>15.</b>{ TERMS.ITEM_15 }</p>
              <p><b>16.</b>{ TERMS.ITEM_16 }</p>
              <p><b>17.</b>{ TERMS.ITEM_17 }</p>
              <center><p><b>*****************************</b></p></center>



            </div>

          </div>
        </section>
      </section>

      {/*<Footer />*/}
    </section>
  )
}
export default Terminos;
