import React from 'react';
import { func, string, bool } from 'prop-types';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { GLOBAL } from '../../Config/global';
import { DEFAULT_HEADER } from '../../Config/userToken';

let PER_PAGE = 50;
let SEARCH_URI = '';

function makeAndHandleRequest(query, page = 1) {

    return fetch(`${SEARCH_URI}&q=${query.toUpperCase()}&page=${page}&per_page=50`, {
        method: 'GET',
        headers: DEFAULT_HEADER.get(),
    })
        .then(resp => resp.json())
        .then(({ items, total_count }) => {
            /* eslint-disable-line camelcase */
            const options = items.map(i => ({
                id: i.id,
                value: i.value,
            }));
            //console.log(options);
            return { options, total_count };
        });
}

class Typeahead extends React.Component {
    constructor(props) {
        super(props)
     //   console.log(props);
        this.state = {
            isLoading: false,
            options: [],
            query: '',
            selected: props.selected
        }
    }

    _cache = {};

    render(...props) {

        const { COMMON_SEARCH_FORM } = GLOBAL;

        SEARCH_URI = this.props.SEARCH_URI;

        return (
            <div className="input">
                <label>{this.props.label}</label>
                <AsyncTypeahead
                    {...this.state}
                    id={this.props.id}
                    className={`input-group input-group-${this.props.borderColor} rounded`}
                    labelKey={option => `${option[this.props.labelKey]}`}
                    maxResults={PER_PAGE - 1}
                    minLength={2}
                    onInputChange={this._handleInputChange}
                    onPaginate={this._handlePagination}
                    onSearch={this._handleSearch}
                    onChange={this._handleChange}
                    paginate
                    searchText={COMMON_SEARCH_FORM.SEARCHING_TEXT}
                    emptyLabel={COMMON_SEARCH_FORM.EMPTY_LABEL_TEXT}
                    promptText={COMMON_SEARCH_FORM.PROMPT_TEXT}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    //defaultSelected={selected}
                    selected={this.props.selected}
                    renderMenuItemChildren={option => (
                        <div key={option.id}>
                            <span style={{ fontSize: '14px' }}>{option[this.props.labelKey]}</span>
                        </div>
                    )}
                    useCache={false}
                />
                {this.props.errorMsg && this.props.errorMsg.length ? <span className={`input-span-${this.props.borderColor}`}>{this.props.errorMsg}</span> : null}
            </div>
        );
    }

    _handleInputChange = query => {
        let selected = [];
        this.props.onClean()
        this.setState({ query, selected });
    };

    _handlePagination = (e, shownResults) => {
        const { query } = this.state;
        const cachedQuery = this._cache[query];

        // Don't make another request if:
        // - the cached results exceed the shown results
        // - we've already fetched all possible results
        if (
            cachedQuery.options.length > shownResults ||
            cachedQuery.options.length === cachedQuery.total_count
        ) {
            return;
        }

        this.setState({ isLoading: true });

        const page = cachedQuery.page + 1;

        makeAndHandleRequest(query, page).then(resp => {
            const options = cachedQuery.options.concat(resp.options);
            this._cache[query] = { ...cachedQuery, options, page };
            this.setState({
                isLoading: false,
                options,
            });
        });
    };

    _handleSearch = query => {
        if (this._cache[query]) {
            this.setState({ options: this._cache[query].options });
            return;
        }

        this.setState({ isLoading: true });
        makeAndHandleRequest(query).then(resp => {
            this._cache[query] = { ...resp, page: 1 };
            this.setState({
                isLoading: false,
                options: resp.options,
            });
        });
    };

    _handleChange = selected => {
        if (selected.length) {
            this.props.onSelected(selected[0]);
        }
    }
}

Typeahead.propTypes = {
    id: string.isRequired,
    label: string.isRequired,
    placeholder: string.isRequired,
    labelKey: string.isRequired,
    borderColor: string.isRequired,
    errorMsg: string.isRequired,
    onSelected: func,
    onClean: func,
    disabled: bool
};

Typeahead.defaultProps = {
    label: '',
    placeholder: '',
    labelKey: 'value',
    borderColor: '',
    errorMsg: '',
    disabled: false,
    onSelected: () => {},
    onClean: () => {}
  };

export default Typeahead;