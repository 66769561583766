import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import RechazoStep1 from '../../Resources/Images/Tutoriales/Rechazo/Rechazo-1.jpg';
import RechazoStep2 from '../../Resources/Images/Tutoriales/Rechazo/Rechazo-2.jpg';

const Rechazo = props => {
    return (
        <div>
            <ReactWizard
                steps={[
                    {
                        stepName: "PASO 1", component: <StepTutorial image={RechazoStep1} text={['Seleccionar los filtros correspondientes antes de realizar la búsqueda', 'Presione el boton Buscar']} />
                    },
                    {
                        stepName: "PASO 2", component: <StepTutorial image={RechazoStep2} text={['Al hacer click en el botón “Descargar” podrás exportar el listado de rechazos a Excel']} />
                    }
                ]}
                navSteps
                title="Paso a paso para utilizar la búsqueda de Rechazos."
                headerTextCenter
                validate
                color="green"
                previousButtonText="Volver"
                nextButtonText="Continuar"
                finishButtonText="Finalizar"
                finishButtonClick={() => { props.onFinish(); }}
            />
        </div>
    )
}

Rechazo.propTypes = {

}

export default Rechazo