/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
import React, { useState } from "react";
import { any, bool, string } from "prop-types";

const renderArrowIcon = () => (
  <i className="fas fa-chevron-down" />
);

function NavMenuItem({ link, children, fontSize, labelComponent, multiple, props }) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <React.Fragment>
      <div
        {...props}
        // type="button"
        className="nav-menu__item px-1"
        onMouseEnter={() => setIsDropdownVisible(!isDropdownVisible)}
        onMouseLeave={() => setIsDropdownVisible(!isDropdownVisible)}
      >
        {labelComponent}
        {multiple ? renderArrowIcon() : null}
        {multiple && isDropdownVisible && children}
      </div>
    </React.Fragment>
  );
}

NavMenuItem.propTypes = {
  children: any,
  labelComponent: any,
  multiple: bool,
  fontSize: string,
  link: string
};

NavMenuItem.defaultProps = {
  children: null,
  labelComponent: "",
  multiple: false,
  fontSize: '14',
  link: ''
};

export default NavMenuItem;
