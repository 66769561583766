import React, { Component } from 'react'
import { Button, Checkbox, Combobox, DatePicker, Input, Alert, Radio } from '..';
import { GLOBAL } from '../../Config/global';
import { APPCONFIG } from '../../app.config';
import * as QLIBS from '../../qLibsHelpers';
import { APIS } from '../../Config/apis';
import userAuth from '../../Config/userauth';
import moment from "moment";

const PERIODOS = [
    { label: 'Diario', value: 0, disabled: false },
    { label: 'Semanal', value: 1, disabled: false },
    { label: 'Mensual', value: 2, disabled: false }
];

const LEYENDAS_PERIODO = [
    '- Se enviará todos los dias a las 9 AM.',
    '- Se enviará una vez por semana el día lunes a las 9 AM.',
    '- Se enviará una vez por mes el primer lunes a las 9 AM.'
];

export default class SchedulerModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ramos: [],
            productos: [],
            errorMessage: '',
            submitOkMessage: '',
            classAvanzado: '',
            leyendaPeriodo: LEYENDAS_PERIODO[PERIODOS[1].value],
            iconAvanzado: 'fa fa-eye',
            fechas: false,
            habilitado: true,
            periodo: PERIODOS[1].value,
            periodo_label: PERIODOS[1].label,
            sendCriteria: [{
                "dia": "",
                "hora": "",
                "habilitado": false,
            },
            {
                "dia": "",
                "hora": "",
                "habilitado": false,
            },
            {
                "dia": "",
                "hora": "",
                "habilitado": false,
            },
            {
                "dia": "",
                "hora": "",
                "habilitado": false,
            },
            {
                "dia": "",
                "hora": "",
                "habilitado": false,
            },
            {
                "dia": "",
                "hora": "",
                "habilitado": false,
            },
            {
                "dia": "",
                "hora": "",
                "habilitado": false,
            }],
            searchCriteria: [
                {
                    "productores": this.props.productores
                },
                {
                    "fechaDesde": null,
                    "fechaHasta": null,
                    "dias": 60
                },
                {
                    "ramo": null,
                    "producto": null
                },
                {
                    "poliza": null,
                    "certificado": null,
                },
                {
                    "cores": this.props.cores
                }
            ]
        }

        this.handleRadioChange = this.handleRadioChange.bind(this);
    }

    componentDidMount() {
        const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

        QLIBS.fillCombo('ramos', PRODUCERURL + 'api/datos/GetComboValues?e=Ramo_' + this.props.reporte, this, GLOBAL.COMBO_EMPTY_ITEM, true);
        QLIBS.fillCombo('productos', PRODUCERURL + 'api/datos/GetComboValues?e=Producto_' + this.props.reporte, this, GLOBAL.COMBO_EMPTY_ITEM, true);

        let self = this;
        let api = this.props.api;
        QLIBS.fetchDataGET(APIS.URLS.SCHEDULER_GET + '?UserId=' + userAuth.userId() + '&UrlApi=' + api, true)
            .then((response) => {
              //  console.log(response);
                let searchCriteria = self.state.searchCriteria;
                let sendCriteria = self.state.sendCriteria;
                let periodo = response.period;
                let habilitado = response.enabled;
                if (response) {
                    if (response.execution && response.execution.length > 0) {
                        for (let index = 0; index < response.execution.length; index++) {
                            let value = response.execution[index];
                            sendCriteria[value.day].dia = value.day;
                            sendCriteria[value.day].hora = parseInt(value.hour.toString());
                            sendCriteria[value.day].habilitado = true;
                            document.getElementById('chk-' + value.day).checked = true;
                        }
                    }
                    if (response.filter) {
                        searchCriteria[1].fechaDesde = response.filter.fechaDesde ? response.filter.fechaDesde : null;
                        searchCriteria[1].fechaHasta = response.filter.fechaHasta ? response.filter.fechaHasta : null;
                        searchCriteria[1].dias = response.filter.dias ? response.filter.dias.toString() : null;
                        searchCriteria[2].ramo = response.filter.ramo ? response.filter.ramo.toString() : null;
                        searchCriteria[2].producto = response.filter.producto ? response.filter.producto.toString() : null;
                        searchCriteria[3].poliza = response.filter.poliza ? response.filter.poliza.toString() : null;
                        searchCriteria[3].certificado = response.filter.certificado ? response.filter.certificado.toString() : null;
                    }
                }

                self.setState({
                    searchCriteria,
                    sendCriteria,
                    periodo,
                    habilitado,
                    fechas: !(searchCriteria[1].fechaDesde == null && searchCriteria[1].fechaHasta == null)
                },
                    () => {
                        document.getElementById('nro_certificado_popup').value = response.filter.certificado ? response.filter.certificado.toString() : null;
                        document.getElementById('nro_poliza_popup').value = response.filter.poliza ? response.filter.poliza.toString() : null;
                        document.getElementById('dias').value = response.filter.dias ? response.filter.dias.toString() : null;
                        document.getElementById('fecha_emision_desde_popup').value = response.filter.fechaDesde ? moment(response.filter.fechaDesde).format("DD/MM/YYYY") : null;
                        document.getElementById('fecha_emision_hasta_popup').value = response.filter.fechaHasta ? moment(response.filter.fechaHasta).format("DD/MM/YYYY") : null;
                        document.getElementById('chk-fechas').checked = this.state.fechas;
                        document.getElementById('habilitado').checked = this.state.habilitado;
                      //  console.log(self.state);
                    });
            });
    }

    handleComboChange = (selectedOption, event, index) => {
        let inputName = event.name;
        let inputValue = selectedOption.value;
        let searchCriteria = this.state.searchCriteria;
        let sendCriteria = this.state.sendCriteria;
        switch (inputName) {
            case "ramos":
                searchCriteria[2].ramo = inputValue;
                break;
            case "productos":
                searchCriteria[2].producto = inputValue;
                break;
            case "cbx-" + index:
                sendCriteria[index].dia = index;
                sendCriteria[index].hora = inputValue;
                break
            default:
                break;
        }
        this.setState({ searchCriteria, sendCriteria });
    };

    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";

        let inputName = event.target.name ? event.target.name : event.target.id;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;

        let searchCriteria = this.state.searchCriteria;
        let habilitado = this.state.habilitado;

        switch (inputName) {
            case "fecha_emision_desde_popup":
                searchCriteria[1].fechaDesde = inputValue;
                break;
            case "fecha_emision_hasta_popup":
                searchCriteria[1].fechaHasta = inputValue;
                break;
            case "dias":
                searchCriteria[1].dias = inputValue;
                break;
            case "nro_poliza_popup":
                searchCriteria[3].poliza = inputValue;
                break;
            case "nro_certificado_popup":
                searchCriteria[3].certificado = inputValue;
                break;
            case "habilitado":
                habilitado = inputValue;
                break;
            default:
                break;
        }
        this.setState({ searchCriteria: searchCriteria, habilitado: habilitado }, () => {
            document.getElementById('habilitado').checked = this.state.habilitado;
            
        });
    };

    handleDayChange = (e, index) => {
        this.setState(prevState => {
            let item = Object.assign({}, prevState.sendCriteria);
            item[index].habilitado = !item[index].habilitado;
            return { item };
        });
    }

    handleDatesChange = () => {
        this.setState(prevState => {
            let entityCopy = Object.assign({}, prevState.searchCriteria);
            let value = !this.state.fechas;
            if (value) {
                entityCopy[1].dias = null;
            }
            else {
                entityCopy[1].fechaDesde = null;
                entityCopy[1].fechaHasta = null;
            }
            return { entityCopy };
        });
        this.setState({ fechas: !this.state.fechas }, () => {
            document.getElementById('dias').value = null;
            document.getElementById('fecha_emision_desde_popup').value = null;
            document.getElementById('fecha_emision_hasta_popup').value = null;
          //  console.log(this.state)
        });
    }

    handleRadioChange = (e, v) => {
        let sendCriteria = this.state.sendCriteria;
        let value = e.target.value;

        for (let index = 0; index < 7; index++) {
            sendCriteria[index].dia = index;
            sendCriteria[index].hora = "";
            sendCriteria[index].habilitado = false;
            document.getElementById('chk-' + index).checked = false;
        }

        switch (value) {
            case '0': // diario
                for (let index = 0; index < 7; index++) {
                    sendCriteria[index].dia = index;
                    sendCriteria[index].hora = 9;
                    sendCriteria[index].habilitado = true;
                    document.getElementById('chk-' + index).checked = true;
                }
                break;

            case '1': // semanal
            case '2': // mensual
                sendCriteria[1].dia = 0;
                sendCriteria[1].hora = 9;
                sendCriteria[1].habilitado = true;
                document.getElementById('chk-' + 1).checked = true;
                break;

            default:
                break;
        }
        this.setState({ periodo: parseInt(value), periodo_label: PERIODOS[parseInt(value)].label, sendCriteria: sendCriteria, leyendaPeriodo: LEYENDAS_PERIODO[parseInt(value)] }, () => { console.log(this.state) });
    }

    validar() {


        if (this.state.fechas && (!this.state.searchCriteria[1].fechaDesde || !this.state.searchCriteria[1].fechaHasta)) {
            this.setState({ errorMessage: 'Es requerido seleccionar fecha desde/hasta.' });
            return false;
        }
        else if (!this.state.fechas && !this.state.searchCriteria[1].dias) {
            this.setState({ errorMessage: 'Es requerido ingresar cantidad de días.' });
            return false;
        }

        if (this.state.periodo === 0 && this.state.sendCriteria.filter(function (f) { return f.habilitado && f.hora }).length !== 7) {
            this.setState({ errorMessage: 'Es requerido seleccionar todos los dias con su horario seleccionado.' });
            return false;
        }

        if (this.state.periodo === 2 && this.state.sendCriteria.filter(function (f) { return f.habilitado && f.hora }).length !== 1) {
            this.setState({ errorMessage: 'Es requerido seleccionar un día y horario para realizar el envio mensual.' });
            return false;
        }
        else if (this.state.sendCriteria.filter(function (f) { return f.habilitado && f.hora }).length === 0) {
            this.setState({ errorMessage: 'Es requerido seleccionar al menos 1 día y horario.' });
            return false;
        }

        return true;
    }

    handleSubmit = () => {
        if (this.validar()) {
            let data = {
                'UrlApi': this.props.api,
                'UserId': userAuth.userId(),
                'Period': this.state.periodo,
                'Enabled': this.state.habilitado,
                'Executions': this.state.sendCriteria
                    .filter(function (f) { return f.habilitado && f.hora })
                    .map(function (obj) {
                        return {
                            'Day': obj.dia,
                            'Hour': obj.hora
                        };
                    }),
                'Filters': {
                    'ProducerIds': this.state.searchCriteria[0].productores,
                    'Ramo': this.state.searchCriteria[2].ramo,
                    'Producto': this.state.searchCriteria[2].producto,
                    'Dias': !this.state.fechas ? this.state.searchCriteria[1].dias : null,
                    'FechaDesde': this.state.fechas ? this.state.searchCriteria[1].fechaDesde : null,
                    'FechaHasta': this.state.fechas ? this.state.searchCriteria[1].fechaHasta : null,
                    'Poliza': this.state.searchCriteria[3].poliza,
                    'Certificado': this.state.searchCriteria[3].certificado,
                    'Cores': this.state.searchCriteria[4].cores,
                }
            };
           // console.log(data);

            let props = this.props;
            QLIBS.fetchDataPOST(APIS.URLS.SCHEDULER_SAVE, data, true)
                .then((response) => {
                    if (response) {
                        this.setState({ submitOkMessage: 'Usted ha programado un envío ' + PERIODOS[this.state.periodo].label + ' que recibira por correo electrónico, para desactivar o modificar esta funcionalidad hagalo desde el boton Programar Envio' });
                        setTimeout(function () { props.dismiss(); }, 5000);
                    } else {
                        this.setState({ errorMessage: '' });
                    }

                });
        }
    }

    showHideAvanzado = () => {
        if (this.state.classAvanzado === '') {
            this.setState({ classAvanzado: 'avanzado', iconAvanzado: 'fa fa-eye-slash' });
        }
        else {
            this.setState({ classAvanzado: '', iconAvanzado: 'fa fa-eye' });
        }
    }

    render() {
        const { COMMON_MODAL_FORM, COMMON_SEARCH_FORM } = GLOBAL;
        return (
            <div className="modal" tabIndex="-1" role="dialog" aria-hidden="false" id="form-scheduler">
                <div className="modal-fullscreen-xl" role="document">
                    <div className="modal-content" id="form-scheduler">
                        <div>
                            <button type="button" className="close positioning" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={() => { this.props.dismiss() }}>&times;</span>
                            </button>
                            <h5 className="modal-title text-center">CONFIGURAR SCHEDULER</h5>
                        </div>
                        <div className="modal-body" id="form-scheduler">
                            {this.state.submitOkMessage.length > 0 && (<Alert type="success" title={GLOBAL.COMPONENTS_FORM.ALERT_SUCCESS_MSG} message={this.state.submitOkMessage} noDetail={true} />)}
                            {this.state.errorMessage.length > 0 && (<Alert type="error" title="ERROR" message={this.state.errorMessage} noDetail={true} />)}
                            <div className="row mt-2">
                                <div className="col-md-2">
                                    <Checkbox id="habilitado" text="Habilitado" defaultChecked={this.state.habilitado} onChange={this.handleChange} />
                                </div>
                                <div className="col-md-6">
                                    <Radio name="periodo" options={PERIODOS} selectedOption={this.state.periodo} inline={true} onChange={this.handleRadioChange}
                                        smallPrint={this.state.leyendaPeriodo + `\n (Los dias y horarios podran ser modificados en la seccion AVANZADO).`} />
                                </div>
                                <div className="col-md-4">
                                    <Button type="button" id="btnAvanzado" onClick={() => { this.showHideAvanzado() }}><i className={this.state.iconAvanzado}></i>&nbsp;Avanzado</Button>
                                </div>
                            </div>
                            <section id="seconddiv" className={` mt-2 ${this.state.classAvanzado}`}>
                                <div className="row">
                                    <div className="col-md-5">
                                        <h4> Envio de reporte <small className="text-muted">Selección de días y horarios</small></h4><br />
                                        <table id="diasTable" key="diasTable" className="table">
                                            <tbody>
                                                {["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"].map((day, dindex) => (
                                                    <tr>
                                                        <td>
                                                            <Checkbox
                                                                id={"chk-" + dindex}
                                                                text={day}
                                                                checked={this.state.sendCriteria[dindex].habilitado}
                                                                onChange={(e) => { this.handleDayChange(e, dindex) }} />
                                                        </td>
                                                        <td>
                                                            <Combobox
                                                                id={"cbx-" + dindex}
                                                                isDisabled={!this.state.sendCriteria[dindex].habilitado}
                                                                options={Array.from(new Array(24), (val, index) => index + 0).map(function (hour, hindex) {
                                                                    return {
                                                                        label: String(hour).padStart(2, '0') + ":00",
                                                                        value: hour,
                                                                    }
                                                                })}
                                                                onChange={(v, e) => { this.handleComboChange(v, e, dindex); }}
                                                                defaultValue={this.state.sendCriteria[dindex].hora}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-7">
                                        <h4> Filtros <small className="text-muted">Selección de filtros fijos</small></h4><br />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Combobox
                                                    id="ramos"
                                                    label={COMMON_SEARCH_FORM.RAMO}
                                                    options={this.state.ramos.map((item) => ({ value: item.id, label: item.value }))}
                                                    onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                    defaultValue={this.state.searchCriteria[2].ramo}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Combobox
                                                    id="productos"
                                                    label={COMMON_SEARCH_FORM.PRODUCTO}
                                                    options={this.state.productos.map((item) => ({ value: item.id, label: item.value }))}
                                                    onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                    defaultValue={this.state.searchCriteria[2].producto}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Checkbox
                                                    id={"chk-fechas"}
                                                    text="Fecha Desde/Hasta"
                                                    checked={this.state.fechas}
                                                    onChange={() => { this.handleDatesChange() }}
                                                    smallPrint="Ingrese un nro de dias o fechas desde/hasta"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    id="dias"
                                                    type="text"
                                                    text={"Cantidad dias"}
                                                    defaultValue={this.state.searchCriteria[1].dias}
                                                    classes="mb-0"
                                                    maxLength="9"
                                                    disabled={this.state.fechas}
                                                    onChange={this.handleChange}
                                                    smallPrint="Es la cantidad de días que se tomará como referencia para filtrar datos por fecha desde/hasta"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <label>{"Fecha Desde"}</label>
                                                <DatePicker
                                                    id="fecha_emision_desde_popup"
                                                    defaultValue={(this.state.searchCriteria[1].fechaDesde) ? this.state.searchCriteria[1].fechaDesde.toString() : ""}
                                                    maxLength="10"
                                                    noDefaultValue="true"
                                                    onChange={this.handleChange}
                                                    disabled={!this.state.fechas}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>{"Fecha Hasta"}</label>
                                                <DatePicker
                                                    id="fecha_emision_hasta_popup"
                                                    defaultValue={(this.state.searchCriteria[1].fechaHasta) ? this.state.searchCriteria[1].fechaHasta.toString() : ""}
                                                    maxLength="10"
                                                    noDefaultValue="true"
                                                    onChange={this.handleChange}
                                                    disabled={!this.state.fechas}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <Input
                                                    id="nro_poliza_popup"
                                                    type="text"
                                                    text={COMMON_SEARCH_FORM.NRO_POLIZA}
                                                    defaultValue={this.state.searchCriteria[3].poliza}
                                                    classes="mb-0"
                                                    maxLength="50"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    id="nro_certificado_popup"
                                                    type="text"
                                                    text={COMMON_SEARCH_FORM.NRO_CERTIFICADO}
                                                    defaultValue={this.state.searchCriteria[3].certificado}
                                                    classes="mb-0"
                                                    maxLength="50"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="modal-footer">
                            <Button type="button" size="medium-lg" fill="outline" onClick={() => { this.handleSubmit() }}>{COMMON_MODAL_FORM.SAVE_BUTTON}</Button>
                            <Button type="button" size="medium-lg" color="secondary" data-dismiss="modal" onClick={() => { this.props.dismiss() }}>{COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
