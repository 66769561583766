import React from 'react';
import { func, oneOf, string } from 'prop-types';
import Button from '../Button';
import { GLOBAL } from '../../Config/global';
import Input from '../Input';
const { MODAL } = GLOBAL;
let user = {};

const RecoverPassWordModal = ({ icon, status, onConfirm, onDismiss, title, email,name }) => {


    return (
        <div className="modal modalNewUser" tabIndex="-1" role="dialog" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div>
                        <button type="button" className="close positioning" data-dismiss="modalNewUser" aria-label="Close">
                            <span aria-hidden="true" onClick={onDismiss}>&times;</span>
                        </button>
                        {status === 'warning' && <i className={`modal-icon ${icon}`}></i>}
                        <h5 className="modal-title text-center">{title}</h5>
                    </div>
                    <div className="modal-body p-4 mb-4">
                        <div className='row'>
                            <div className='col'>
                                <Input
                                    id="name"
                                    type="text"
                                    value={name}
                                    text='Nombre y apellido'
                                    maxLength="13"
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        
                        <div className='row'>
                            <div className='col'>
                                <Input
                                    id="email"
                                    isRequired='true'
                                    type="email"
                                    text="Email del usuario"
                                    value={email}
                                    maxLength="100"
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        
                    </div>
                    <div className="modal-actions m-0">
                        <Button customStyles={{ minWidth: '120px' }} type="button" color="secondary" fill="outline" size="smaller-outline" data-dismiss="modalNewUser" onClick={onDismiss}>Cancelar</Button>
                        <Button customStyles={{ minWidth: '120px', border: 'none' }} type="button" size="smaller" color={status} onClick={() => { onConfirm(user) }} >Restablecer</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

RecoverPassWordModal.propTypes = {
    onConfirm: func.isRequired,
    onDismiss: func.isRequired,
    title: string,
    status: oneOf(['primary', 'warning', 'error', 'success']),
    icon: string
};

RecoverPassWordModal.defaultProps = {
    status: 'success',
    title: MODAL.TITLE,
    icon: 'fas fa-exclamation-circle'
};

export default RecoverPassWordModal;