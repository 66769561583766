/* eslint-disable import/named */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/no-named-as-default */
import React from "react";
import {Link, Redirect, withRouter} from "react-router-dom";
import registerImage from "../../Resources/Images/FormImages/register-form.png";
import { Loading, Alert, Input, Checkbox, BackButton, Button, Header, FormTitle, SubTitle} from "../../Components";
import InputCheck from '../../Resources/Images/Logos/input-check.png';
import InputError from '../../Resources/Images/Logos/input-error.png';
import { GLOBAL, IDENTITY_CONFIG } from "../../Config/global";
import { FORM_ERRORS } from "../../Config/form_errors";
import Script from "react-load-script"
import { ValidarCuit } from "../../Shared/Utils/form_validation";

class RegisterSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      redirect: null
    }

    this.cuit = "";
    this.password = "";
    this.rememberMe = false;

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  handleKeyDown = event => {
    // Chek which field
    switch(event.target.id){
      case "password":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      default:
        // Do Nothing
        break;
    }
  }

  handleChange = event => {
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { SUCCESS_REGISTER_FORM, LOGIN_FORM } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;

    // Chek which field
    switch(event.target.id){
      case "cuit":
        // Remove "-"
        this.cuit = (event.target.value) ? event.target.value.replace(/-/g, "") : "";

        // Validate data
        this.cuitValid = ValidarCuit(this.cuit);
        if (this.cuitValid){
          this.cuitError = "";
          this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
          this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
        }else{
          this.cuitErrorIcon = "";
          this.cuitErrorBorderColor = "";
        }
        this.setState({ name: this.state.name})
        break;

      case "password":
        this.passwordErrorIcon = "";
        this.passwordErrorBorderColor = "";
        this.setState({ name: this.state.name})
        break;

      default:
        // Do Nothing
        break;
    }
  }

  handleSubmit = event => {
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { SUCCESS_REGISTER_FORM, LOGIN_FORM } = GLOBAL;
    const { authority } = IDENTITY_CONFIG;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;

    event.preventDefault();
    const data = new FormData(event.target);

    this.cuit = event.target.cuit.value;
    this.password = event.target.password.value;
    this.rememberMe = event.target.remember.checked;

    this.cuitError = "";
    this.passwordError = "";
    this.formError = "";

    this.cuitErrorIcon = "";
    this.passwordErrorIcon = "";
    this.cuitErrorBorderColor = "";
    this.passwordErrorBorderColor = "";

    this.loadingForm = false;

    // First validation
    if (!this.cuit || this.cuit.length < 11){
      this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.password){
      this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }

    if (!this.cuitError){
      // Remove "-"
      this.cuit = (this.cuit) ? this.cuit.replace(/-/g, "") : "";

      // Validate data
      this.cuitValid = ValidarCuit(this.cuit);
      if (!this.cuitValid){
        this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;
      }
    }

    if (!this.passwordError){
      if (this.password.length < 6){
        this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
      }
    }

    if (this.cuitError || this.passwordError){
      this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
      this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
      this.passwordErrorIcon = (this.passwordError) ? InputError : "";
      this.passwordErrorBorderColor = (this.passwordError) ? "error" : "success";
      this.loadingForm = false;
      this.setState({ name: this.state.name})
    }else{
      this.cuitErrorIcon = InputCheck;
      this.cuitErrorBorderColor = "success";
      this.loadingForm = true;
      this.setState({ name: this.state.name})

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            Cuit : this.cuit,
            Password : this.password,
            RememberMe : this.rememberMe
        })
      };

      return fetch(authority, requestOptions)
        .then(this.handleResponse)
        .catch(this.handleResponse)
      ;
    }
  }

  handleResponse(response){
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { SUCCESS_REGISTER_FORM, LOGIN_FORM } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;

    if (response && response.ok){
      const data = (response ? response.text() : COMMON_ERRORS.REQUEST_DEFAULT);
      var parseData;
      if (data){
        try{
          parseData = JSON.parse(data);
        }catch(e){
          // Not JSON data
        }
        //document.location.href = "../master-page";
        this.setState({
          redirect: "/master-page"
        })
      }else{
        const error = (parseData && parseData.error && parseData.error.message);
        this.formError = (error) ? error : "";
      }
    }else{
      this.formError = COMMON_ERRORS.REQUEST_DEFAULT;
    }

    this.loadingForm = false;
    this.setState({ name: this.state.name})
  }

  render(){
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { SUCCESS_REGISTER_FORM, LOGIN_FORM } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <section>
		<Loading visible={this.loadingForm}></Loading>
        <Header centered />
        <section className="MainContainer register-success">
          <div className="container MainContainer__Center">
            <div className="row MainContainer__Card rounded">
              <div className="col-lg-6 px-0 d-none d-lg-block">
                <img src={ registerImage } className="img-fluid" alt="Madre con sus hijos" />
              </div>
              <div className="col-lg-1 register__Back d-md-block d-none">
                <BackButton />
              </div>
              <div className="col-lg-4 my-auto">
                {
                  (this.cuitError || this.passwordError) &&
                  <div id="form-error-message" className="row my-2">
                    <div className="col-12">
                      <Alert
                        icon="times"
                        message={ COMPONENTS_FORM.ALERT_ERROR_MSG }
                        title={ COMPONENTS_FORM.ALERT_ERROR_TITLE }
                        type="errorMessage"
                      />
                    </div>
                  </div>
                }
                {
                  (this.formError) &&
                  <div id="form-error-message" className="row my-2">
                    <div className="col-12">
                      <Alert
                        icon="times"
                        message=""
                        title={ COMMON_ERRORS.REQUEST_DEFAULT }
                        type="errorMessage"
                      />
                    </div>
                  </div>
                }
                <form id="form-register-success" onSubmit={ this.handleSubmit } className="register-success__Form">
                  <FormTitle
                    customClass="register-success__FormTitle"
                    text={ SUCCESS_REGISTER_FORM.TITLE }
                  />
                  <SubTitle>{ SUCCESS_REGISTER_FORM.SUBTITLE }</SubTitle>
                  <Input
                    id="cuit"
                    type="text"
                    text={ LOGIN_FORM.CUIT }
                    defaultValue=""
                    mask="99-99999999-9"
                    borderColor={ this.cuitErrorBorderColor }
                    imgSrc={ this.cuitErrorIcon }
                    errorMsg={ this.cuitError }
                    onChange={this.handleChange}
                  />
                  <Input
                    id="password"
                    type="password"
                    text={ LOGIN_FORM.PASSWORD }
                    defaultValue=""
                    maxLength="15"
                    borderColor={ this.passwordErrorBorderColor }
                    imgSrc={ this.passwordErrorIcon }
                    errorMsg={ this.passwordError }
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                  />
                  <div className="row checkbox-margin">
                    <div className="col-6 pr-0">
                    <Checkbox
                        id="remember"
                        fontSize="smaller-text"
                        text={LOGIN_FORM.REMEMBER_PASSWORD}
                      />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <Link to="/recover-password" className="link smaller-text">{ LOGIN_FORM.RECOVER_PASSWORD }</Link>
                    </div>
                  </div>
                  <Button type="submit">{LOGIN_FORM.BUTTON_TEXT}</Button>
                </form>
              </div>
              <div className="col-lg-1 register__Back d-md-block d-none" />
            </div>
          </div>
        </section>
        {/*<Footer/>*/}
      </section>
    );
  }
}

export default withRouter(RegisterSuccess);
