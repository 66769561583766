import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import CertifRetencionesStep1 from '../../Resources/Images/Tutoriales/CertifRetenciones/CertifRetenciones-1.jpg';
import CertifRetencionesStep2 from '../../Resources/Images/Tutoriales/CertifRetenciones/CertifRetenciones-2.jpg';

const CertifRetenciones = props => {
    return (
        <div>
            <ReactWizard
                 steps={[
                    {
                        stepName: "PASO 1", component: <StepTutorial image={CertifRetencionesStep1} text={[
                            'Hacer clic en “Ingresar” para ser redirigido al portal de ebroker para acceder a la información solicitada.',
                        ]} />
                    },
                    {
                        stepName: "PASO 2", component: <StepTutorial image={CertifRetencionesStep2} text={[' Accede al portal de ebroker volviendo a ingresar tus credenciales']} />
                    }
                ]}
                navSteps
                title="Paso a paso para acceder al módulo de Certificados de Retenciones."
                headerTextCenter
                validate
                color="green"
                previousButtonText="Volver"
                nextButtonText="Continuar"
                finishButtonText="Finalizar"
                finishButtonClick={() => { props.onFinish(); }}
            />
        </div>
    )
}

CertifRetenciones.propTypes = {

}

export default CertifRetenciones