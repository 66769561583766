import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import CanalesStep1 from '../../Resources/Images/Tutoriales/Caucion/Caucion-1.jpg';


const Caucion = props => {
    return (
        <div>
            <ReactWizard
                steps={[
                    {
                        stepName: "PASO 1", component: <StepTutorial image={CanalesStep1} text={['Hacer clic en “Caución” para ser redirigido al portal']} />
                    }
                ]}
                navSteps
                title="Paso a paso para acceder al módulo de Caución."
                headerTextCenter
                validate
                color="green"
                previousButtonText="Volver"
                nextButtonText="Continuar"
                finishButtonText="Finalizar"
                finishButtonClick={() => { props.onFinish(); }}
            />
        </div>
    )
}

Caucion.propTypes = {

}

export default Caucion
