import React from "react";
import { Link } from "react-router-dom";
import { bool, string, func } from 'prop-types';

const Checkbox = ({ disabled, checked, fontSize, id, text, className, link, linkText, onChange, smallPrint }) => {
  const labelClass = `custom-control-label ${fontSize}`;

  return (
    <div className="custom-control radio custom-checkbox">
      <input
        type="checkbox"
        className={`${className} row custom-control-input`}
        id={id}
        disabled={disabled}
        defaultChecked={checked}
        onChange={onChange}
      />
      {link.length ? (
        <label className={labelClass} htmlFor={id}>
          {text} <Link to={link}>{linkText}</Link>
        </label>
      ) : (
        <label className={labelClass} htmlFor={id}>
          {text}
        </label>
      )}
      {smallPrint.length ? <><br/><span>{smallPrint}</span></> : null}
    </div>
  );
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  fontSize: "",
  link: "",
  linkText: "",
  className: "",
  smallPrint: ""
};

Checkbox.propTypes = {
  checked: bool,
  //id: string.isRequired,
  disabled: bool,
  fontSize: string,
  link: string,
  linkText: string,
  text: string,
  className: string,
  onChange: func,
  smallPrint: string
};

export default Checkbox;
