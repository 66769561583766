import React from "react";
import { any, bool, oneOf } from "prop-types";

const NavDropdownMenu = ({ children, nested, menuPlacement, className }) => {
  return (
    <ul className={`${className ? className: 'nav-dropdown-menu'}  p-0 ${nested ? '-nested' : ''} ${ menuPlacement }`}>
      {children}
    </ul>
  )
};

NavDropdownMenu.propTypes = {
  children: any,
  nested: bool,
  menuPlacement: oneOf(['left', 'right'])
};

NavDropdownMenu.defaultProps = {
  children: null,
  nested: false,
  menuPlacement: 'right'
};

export default NavDropdownMenu;
