const loadProducerCode = (producers) => {
    let arr = []; 
    //fix error cuando viene vacio del localStorage
    if (!Array.isArray(producers))
        return arr;
    // recorro todos los cores que vienen del local storage
    producers.forEach(function (core, index) {
        // toma todos los producerCodes sin limitar los childs internos
        if (Array.isArray(core.ProducerCodes)) {
            let newItem = core.ProducerCodes.map(function mapper (item) {			
                return {
                    id:  item.Code,
                    core: item.Core,
                    text: `(${item.Code.replace('X', '')}) ${item.Name}`,
                    items: Array.isArray(item.Children) ? item.Children.map(mapper) : []
                }
            });            
            // concateno para respetar la estructura de datos original del componente
            arr = arr.concat(newItem);
        }
    });		

    return arr;
  }

  export { loadProducerCode };