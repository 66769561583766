import React from 'react';
import { object } from 'prop-types';
import { Breadcrumb, Header, RoleCard } from "../../Components";
import { GLOBAL } from "../../Config/global";

const User = ({ location: { pathname } }) => {
  const { USER_OPTIONS } = GLOBAL;

  return (
    <section>
      <Header centered />
      <section className="MainContainer user">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Breadcrumb currentPage={ pathname } />
            </div>
          </div>
        </div>
        <div className="container MainContainer__Center shorter">
          <div className="row">
            <div className="col-12">
              <h2 className="MainContainer__MainTitle user-title text-center">
              { USER_OPTIONS.PLATFORM }
              </h2>
            </div>
          </div>
          <div className="row d-block d-md-none">
            <div className="col-12 text-center">
              <p className="user__Note">
              { USER_OPTIONS.SMALLPRINT }
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center justify-content-md-end">
              <RoleCard
                img={ USER_OPTIONS.ADMIN.img }
                title={ USER_OPTIONS.ADMIN.title }
              />
            </div>
            <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
              <RoleCard
                img= { USER_OPTIONS.USER.img }
                title={ USER_OPTIONS.USER.title }
              />
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col-md-12 text-center">
              <h4 className="user__Subtitle">
              { USER_OPTIONS.SMALLPRINT }
              </h4>
            </div>
          </div>
        </div>
      </section>
      {/*<Footer  />*/}
    </section>
  )
}

User.propTypes = {
  location: object.isRequired
};

export default User;
