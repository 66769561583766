/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { object, string } from "prop-types";
import { GLOBAL } from "../../Config/global";
import { MOCK_DATA } from "../../Config/mock-data";

import {
  Table as TableComponent,
  Actions,
  CellInput,
  ConfirmationModal
} from "..";
import { requestData } from "../Table/utils";

const PrimaryTable = ({ className, history }) => {
  const { TABLES } = MOCK_DATA;

  const [editingRow, setEditingRow] = useState(null);
  const [selection, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [tableElementRef, setTableElementRef] = useState(null);
  const [rowModel, setRowModel] = useState({
    loading: false,
    pages: null,
    data: TABLES.makeData(),
    sorted: []
  });

  const toggleAllSelection = () => {
    if (selectAll) {
      const wrappedInstance = tableElementRef.current.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;

      setSelection(
        currentRecords.map(record => `select-${record._original.id}`)
      );
    } else {
      setSelection([]);
    }
  };

  const toggleSingleSelection = key => {
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      setSelection([
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ]);
    } else {
      setSelection([...selection, key]);
    }
  };

  const isSelected = key => selection.includes(`select-${key}`);

  const isEditing = key => editingRow && editingRow.index === key;

  const onToggleRowEdit = row => {
    setEditingRow(editingRow && editingRow.index === row.index ? null : row);
  };

  const defaultSortingMethod = (filter, row) => {
    return row._original[filter.id]
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  };

  const handleCellKeyStroke = (event, row) =>
    event.which === 13 && onToggleRowEdit(row);

  const renderEditableCell = row =>
    editingRow && editingRow.index === row.index && row.column.editable ? (
      <CellInput
        onKeyDown={e => handleCellKeyStroke(e, row)}
        //onChange={event => console.log(event.target.value)}
        type="text"
        defaultValue={row.value}
      />
    ) : (
      row.value
    );

  const renderSelectInputComponent = ({
    checked,
    id,
    onClick,
    row: { id: rowId }
  }) =>
    rowId % 2 === 0 && ( // EXAMPLE, only even rows have checkbox selection enabled
      <input
        type="checkbox"
        defaultChecked={checked}
        onClick={() => onClick(id)}
      />
    );

  const getSelectedRowProps = rowInfo =>
    rowInfo && isSelected(rowInfo.original.id)
      ? "-selected"
      : rowInfo && selection.length && !isSelected(rowInfo.original.id)
      ? "-disabled"
      : "";

  const getEditingRowProps = rowInfo =>
    rowInfo && isEditing(rowInfo.index)
      ? "-editing"
      : rowInfo && editingRow && !isEditing(rowInfo.index)
      ? "-disabled"
      : "";

  const getEditableColumnProps = (state, rowInfo) => ({
    className: "-editable"
  });

  const getRowProps = (state, rowInfo) => ({
    className: `${getSelectedRowProps(rowInfo)} ${getEditingRowProps(rowInfo)}`
  });

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleExportModal = () => {
    setShowExportModal(!showExportModal);
  };

  const onSuccessRedirect = () => {
    history.push("/table-success");
  };

  const handleFetchData = (state, instance) => {
    setRowModel({
      ...rowModel,
      loading: true
    });

    requestData(
      instance.state.pageSize,
      instance.state.page,
      instance.state.sorted,
      instance.state.filtered
    ).then(res => {
      return setRowModel({
        ...rowModel,
        data: res.rows,
        pages: res.pages,
        loading: false
      });
    });
  };

  const handleSortedChange = (newSort, column) => {
    return setRowModel({
      ...rowModel,
      sorted: newSort
    });
  };

  useEffect(() => {
    toggleAllSelection();
  }, [selectAll, toggleAllSelection]);

  return (
    <>
      {showDeleteModal && (
        <ConfirmationModal
          message={GLOBAL.TABLE_VIEW.DELETE_MODAL_BODY}
          status="warning"
          title={GLOBAL.TABLE_VIEW.DELETE_MODAL_TITLE}
          onDismiss={toggleDeleteModal}
          onConfirm={onSuccessRedirect}
        />
      )}
      {showExportModal && (
        <ConfirmationModal
          message={GLOBAL.TABLE_VIEW.EXPORT_MODAL_BODY}
          title={GLOBAL.TABLE_VIEW.EXPORT_MODAL_TITLE}
          status="primary"
          onDismiss={toggleExportModal}
          onConfirm={onSuccessRedirect}
        />
      )}
      <TableComponent
        className={className}
        data={rowModel.data}
        defaultSortingMethod={defaultSortingMethod}
        handleToggleAllSelection={toggleAllSelection}
        handleToggleSingleSelection={toggleSingleSelection}
        handleSetSelectAll={setSelectAll}
        selectAll={selectAll}
        isSelected={isSelected}
        getRowProps={getRowProps}
        selectInputComponent={renderSelectInputComponent}
        getTableRef={setTableElementRef}
        manual
        loading={rowModel.loading}
        sorted={rowModel.sorted}
        pages={rowModel.pages}
        onSortedChange={handleSortedChange}
        onFetchData={handleFetchData}
        columns={[
          {
            Header: "DNI",
            accessor: "dni",
            editable: true,
            getProps: getEditableColumnProps,
            Cell: row => renderEditableCell(row),
            resizable: true
          },
          {
            Header: "Nombre",
            accessor: "name",
            editable: true,
            getProps: getEditableColumnProps,
            Cell: row => renderEditableCell(row),
            resizable: true
          },
          {
            Header: "Apellido",
            accessor: "lastName",
            getProps: getEditableColumnProps,
            editable: true,
            Cell: row => renderEditableCell(row),
            resizable: true
          },
          {
            Header: "Fecha",
            accessor: "age",
            editable: true,
            getProps: getEditableColumnProps,
            Cell: row => renderEditableCell(row),
            resizable: true
          },
          {
            Header: "Certificado",
            width: 150,
            accessor: "cert",
            expander: true,
            filterable: true,
            sortable: true,
            Cell: row => renderEditableCell(row),
            getProps: getEditableColumnProps,
            resizable: true,
            // eslint-disable-next-line react/no-multi-comp
            Expander: ({ isExpanded, row: { _original }, column: { id } }) => {
              return (
                <div className="inline-expander">
                  {isExpanded ? (
                    <span>
                      <i className="fas fa-caret-down" /> {_original[id]}
                    </span>
                  ) : (
                    <span>
                      <i className="fas fa-caret-right" /> {_original[id]}
                    </span>
                  )}
                </div>
              );
            },
            // eslint-disable-next-line react/no-multi-comp
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="">Seleccionar</option>
                <option value="Opción 1">Opción 1</option>
                <option value="Opción 2">Opción 2</option>
                <option value="Opción 3">Opción 3</option>
                <option value="Opción 4">Opción 4</option>
              </select>
            )
          },
          {
            Header: "Acciones",
            accessor: "actions",
            width: 100,
            style: {
              display: "flex",
              alignItems: "center"
            },
            filterable: false,
            sortable: false,
            resizable: true,
            Cell: row => (
              <Actions
                onDelete={toggleDeleteModal}
                onEdit={() => onToggleRowEdit(row)}
                onDownload={toggleExportModal}
              />
            )
          }
        ]}
      />
    </>
  );
};

PrimaryTable.propTypes = {
  className: string,
  history: object.isRequired
};

PrimaryTable.defaultProps = {
  className: "-striped -highlight"
};

export default PrimaryTable;
