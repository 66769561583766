import { bool, number, string } from "prop-types";
import React from "react";

const TextArea = ({ noResize, rowNum, text, disabled, defaultValue, maxLength, onChange, id }) => {
  return (
    <textarea
      id={id}
      className={`input-group rounded text-area ${noResize ? "fixed-size" : ""}`}
      placeholder={text}
      rows={rowNum}
      disabled={disabled}
      defaultValue={defaultValue}
      maxLength={maxLength}
      onChange={onChange}
    />
  );
};

TextArea.defaultProps = {
  noResize: false,
  disabled: false,
  rowNum: 6,
  defaultValue: "",
  maxLength: null
};

TextArea.propTypes = {
  disabled: bool,
  noResize: bool,
  rowNum: number,
  text: string.isRequired,
  maxLength: number
};

export default TextArea;
