import React, { useState } from "react";
import { array, number, string } from "prop-types";
import { Notification } from "..";

const NotificationsSection = ({ displayCount, icon, unreadNotifications }) => {
  const [displayedNotifications, setDisplayedNotifications] = useState(displayCount);

  const handleClick = () => {
    setDisplayedNotifications(displayedNotifications + displayCount);
  }

  return (
    <>
      {unreadNotifications.map(({date, text}, index) => (
        index < displayedNotifications && (
          <Notification
            icon={icon}
            date={date}
            text={text}
          />
      )))}
      {displayedNotifications < unreadNotifications.length && (
        <div className="w-100 text-center pt-2">
          <p className="link" onClick={handleClick}>
            Mostrar más <i className="fas fa-chevron-down" />
          </p>
        </div>
      )}
    </>
  );
};

NotificationsSection.propTypes = {
  displayCount: number,
  icon: string,
  unreadNotifications: array.isRequired,
};

NotificationsSection.defaultProps = {
  displayCount: 4,
  icon: "fas fa-info-circle",
};

export default NotificationsSection;
