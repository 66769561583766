import React from 'react';
import { func, oneOf, string } from 'prop-types';
import Button from '../Button';
import { GLOBAL } from '../../Config/global';

const { MODAL } = GLOBAL;

const ConfirmationModal = ({ icon, status, onConfirm, onDismiss,onCancel, message, title }) => {

  return (
    <div className="modal" tabIndex="-1" role="dialog" aria-hidden="false">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div>
            <button type="button" className="close positioning" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" onClick={onCancel}>&times;</span>
            </button>
            {status === 'warning' && <i className={`modal-icon ${icon}`}></i>}
            <h5 className="modal-title text-center">{title}</h5>
          </div>
          <div className="modal-body p-0 mb-1">
            <p>{message}</p>
          </div>
          <div className="modal-actions m-0">
            <Button customStyles={{ minWidth: '120px' }} type="button" color="secondary" fill="outline" size="smaller-outline" data-dismiss="modal" onClick={onDismiss}>{MODAL.CANCEL_BUTTON}</Button>
            <Button customStyles={{ minWidth: '120px', border: 'none' }} type="button" size="smaller" color={status} onClick={onConfirm} >{MODAL.CONFIRM_BUTTON}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmationModal.propTypes = {
  onConfirm: func.isRequired,
  onDismiss: func.isRequired,
  onCancel: func,
  message: string,
  title: string,
  status: oneOf(['primary', 'warning', 'error', 'success']),
  icon: string
};

ConfirmationModal.defaultProps = {
  status: 'success',
  message: MODAL.BODY,
  title: MODAL.TITLE,
  icon: 'fas fa-exclamation-circle'
};

export default ConfirmationModal;