import React from "react";
import moment from "moment";
import "moment/locale/es";

export const selectYears = startYear =>
  Array.from({ length: 102 }, (v, index) => {
    // eslint-disable-next-line no-param-reassign
    index += startYear;

    return index;
  });

const renderSelect = ({ month, onMonthSelect, onYearSelect }, startYear) => {
  return (
    <div className="select-position">
      <div>
        <select
          className="date-select"
          value={month.month()}
          onChange={e => {
            onMonthSelect(month, e.target.value);
          }}
        >
          {moment.months().map((label, value) => (
            <option key={value} value={value}>
              {label[0].toUpperCase() + label.slice(1)}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          className="date-select"
          value={month.year()}
          onChange={e => {
            onYearSelect(month, e.target.value);
          }}
        >
          {selectYears(startYear).map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

// Current year minus legal age (18) minus 100 years
// minus 1 year (to account for index starting from 0)}
export const startYear = new Date().getFullYear() - 119;
export const renderSelectBirthday = ({ ...props }) =>
  renderSelect({ ...props }, startYear);
export const renderSelectDate = ({ ...props }) =>
  renderSelect({ ...props }, 1980);
