import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import GestionTramitesStep1 from '../../Resources/Images/Tutoriales/GestionTramites/GestionTramites-1.jpg';

const GestionTramites = props => {
    return (
        <div>
            <ReactWizard
                steps={[
                    {
                        stepName: "PASO 1", component: <StepTutorial image={GestionTramitesStep1} text={[
                            'Seleccionar el sector y tipo de trámite a realizar',
                            'Escribir un mensaje y el asunto del mismo',
                            'Si es necesario, agregar un archivo adjunto',
                            'Si se desea, seleccionar “Recibir copia en mi e-mail”',
                            'Hacer click en “Enviar consulta” para que sea recibida por el sector correspondiente'
                        ]} />
                    }
                ]}
                navSteps
                title="Paso a paso para acceder al módulo de Gestión de Trámites."
                headerTextCenter
                validate
                color="green"
                previousButtonText="Volver"
                nextButtonText="Continuar"
                finishButtonText="Finalizar"
                finishButtonClick={() => { props.onFinish(); }}
            />
        </div>
    )
}

GestionTramites.propTypes = {

}

export default GestionTramites