import React from 'react';
import { truncate } from '../../../Shared/Utils';

const TotalesPago = ({ props }) => {
    const getTotalPagado = (importePagado, ibOsMunip, ganancias) => {
        
        const importePagadoNro = parseFloat(importePagado.replace(/[^0-9,-]+/g, '').replace(',', '.'));
        const ibOsMunipNro =     parseFloat(ibOsMunip.replace(/[^0-9,-]+/g, '').replace(',', '.'));
        const gananciasNro = parseFloat(ganancias.replace(/[^0-9,-]+/g, '').replace(',', '.'));
        const suma = importePagadoNro + ibOsMunipNro + gananciasNro;
        return truncate(suma,2);
    }

    return (
        <>
            <span className='sumatoriaPagos'>
                <b>TOTAL</b>
                <p>$ {getTotalPagado(props.importePagado, props.ibOsMunicip.props.content, props.ganancias.props?.content ?? '$0')}</p>
            </span>
            <span className='sumatoriaPagos'>
                <b>Retención IB-OS-Municipal</b>
                <p>{props.ibOsMunicip?.props?.content}</p>
            </span>
            <span className='sumatoriaPagos'>
                <b>Retención Ganancias</b>
                <p>{props.ganancias?.props?.content ?? '$ 0'}</p>
            </span>
            <span className='sumatoriaPagos'>
                <b>TOTAL PAGADO</b>
                <p>{props.importePagado}</p>
            </span>
        </>

    );
}
export default TotalesPago;