import React, { Component } from 'react'
import { Loading, Button, Combobox, Alert } from '..';
import { GLOBAL } from '../../Config/global';
import { APPCONFIG } from '../../app.config';
import { DEFAULT_HEADER } from '../../Config/userToken';
import * as QLIBS from '../../qLibsHelpers';
export default class NotificationDefinitionDestinationModal extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            loadingData: false,
            showForm: false,
            errorMessage: null,
            entity: null,
            listGroup: [],
            listProducers: []
        }

        this.clearNotificationDefinitionDestination = this.clearNotificationDefinitionDestination.bind(this);
        this.handleComboChange = this.handleComboChange.bind(this);
        this.searchGroup = this.searchGroup.bind(this);
        this.searchProducers = this.searchProducers.bind(this);
    }

    componentDidMount() {

        this.searchGroup();
        this.searchProducers();
        this.clearNotificationDefinitionDestination();

    }

    clearNotificationDefinitionDestination = () => {
        const entity = {
            typeDestination: 1,
            GroupId: 0,
            CUIT: 0
        };
        this.setState({ loadingData: false, showForm: true, entity: entity });
    }

    
	searchGroup = async () => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		this.searchResultsError = "";

		this.setState({loadingData: true });

		// Parse data to send
		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};				
		return fetch(`${PRODUCERURL}api/notification/SearchGroup?id=0`, requestOptions)
		.then((response) => {
			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then(response => {
                    if (Array.isArray(response)) {
                        var list = [{ value: 0, label: "Seleccione un grupo" }, ...response];
                        this.setState({loadingData: false, listGroup: list });
                    }
                });
			}
			else {
				this.searchResultsError = `Error procesando solicitud: ${response.statusText}`;
				this.setState({loadingData: false, showGrid: false });
			}

		  })
	}

    searchProducers = async () => {

		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		this.searchResultsError = "";

		this.setState({loadingData: true });

		// Parse data to send
		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};				
		return fetch(`${PRODUCERURL}api/notification/SearchProducers?id=0`, requestOptions)
		.then((response) => {
			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then(response => {
                    if (Array.isArray(response)) {
                        var list = [{ value: 0, label: "Seleccione un productor" }, ...response];
                        this.setState({loadingData: false, listProducers: list });
                    }
                });
			}
			else {
				this.searchResultsError = `Error procesando solicitud: ${response.statusText}`;
				this.setState({loadingData: false, showGrid: false });
			}

		  })
	}

    validar() {
        return ((this.state.entity.typeDestination === 1 && this.state.entity.GroupId !== 0) ||
                (this.state.entity.typeDestination === 2 && this.state.entity.CUIT !== 0))
    }


    handleSubmit = () => {

        if (this.validar()) {
            const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

            let data = {
                id: 0, //siempre es alta
                NotificationDefinitionId: this.props.ParentId,
                GroupId: (this.state.entity.typeDestination === 1) ? this.state.entity.GroupId : null,
                CUIT: (this.state.entity.typeDestination === 2) ? this.state.entity.CUIT : null
            };
            this.setState({loadingData: true, showForm: false, errorMessage: null });

            const requestOptions = {
                method: 'POST',
                headers: DEFAULT_HEADER.get(),
                body: JSON.stringify(data)
            };			
            return fetch(`${PRODUCERURL}api/notification/SaveNotificationDefinitionDestination`, requestOptions)
            .then((response) => {

                if (response.ok) {
                    QLIBS.authorizeAction(response)
                    .then((response) => {
                        this.setState({ loadingData: false, showForm: false, entity: null, errorMessage: null });
                        this.props.dismiss(true);
                    })
                    .catch((response) => {
                        const message = 'Error procesando datos';
                        this.setState({ loadingData: false, showForm: false, errorMessage: message });
                    });
                }
                else {
                    const message = `Error procesando solicitud: ${response.statusText}`;
                    this.setState({ loadingData: false, showForm: false, errorMessage: message });
                }

            });
        }

    }

    handleComboChange = (selectedOption, event, index) => {
        let inputName = event.name;
        let inputValue = selectedOption.value;

        let entity = Object.assign({}, this.state.entity);
        
        switch (inputName) {
            case "ctlTypeDestination":
                entity.typeDestination = inputValue;
                break;
            case "ctlGroup":
                entity.GroupId = inputValue;
                break;
            case "ctlProducers":
                entity.CUIT = inputValue;
                break;
            default:
                break;
        }

        this.setState({ entity: entity });
        
    }

    render() {
        const { COMMON_MODAL_FORM } = GLOBAL;

        return (
		    <>
                <Loading visible={this.state.loadingData}></Loading>

                <div id="form-notification-definition-destination" className="modal" tabIndex="-1" role="dialog"  aria-modal="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title text-center">Destinatario de ejecución</h4>
                            </div>
                            <div className="modal-body">

                                {this.state.errorMessage && (<Alert type="error" title="ERROR" message={this.state.errorMessage} noDetail={true} />)}
                                {this.state.showForm && (
                                 <section>
                                     <div className="row pb-1 pt-4">
                                        <div className="col-4">
                                            <Combobox
                                                id="ctlTypeDestination"
                                                label={"Tipo de destinatario"}
                                                options={[
                                                    { value: 1, label: "Grupo" },
                                                    { value: 2, label: "Productor" }
                                                ]}
                                                onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                defaultValue={this.state.entity.typeDestination}
                                            />
                                        </div>
                                        <div className="col-8">
                                            {this.state.entity.typeDestination === 1 && (
                                                <Combobox
                                                    id="ctlGroup"
                                                    label={"Grupo"}
                                                    options={this.state.listGroup}
                                                    onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                    defaultValue={this.state.entity.GroupId}
                                                />
                                            )}
                                            {this.state.entity.typeDestination === 2 && (
                                                <Combobox
                                                    id="ctlProducers"
                                                    label={"Productor"}
                                                    options={this.state.listProducers}
                                                    onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                    defaultValue={this.state.entity.CUIT}
                                                />
                                            )}
                                        </div>
                                    </div>
                                 </section>
                                )}
                            
                            </div>
                            <div className="modal-footer">
                                {this.state.showForm && (
                                    <Button type="button" size="medium-lg" fill="outline" onClick={() => { this.handleSubmit() }}>{COMMON_MODAL_FORM.SAVE_BUTTON}</Button>
                                )}
                                <Button type="button" size="medium-lg" fill="outline" color="secondary" data-dismiss="modal" onClick={() => { this.props.dismiss(false) }}>{COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
