/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import loginImageDesktop from "../../Resources/Images/FormImages/pantalla-login.jpg";
import loginImageMobile from "../../Resources/Images/FormImages/pantalla-login.jpg";
import { Loading, Alert, Input, Button, FormTitle, Header, Checkbox } from "../../Components";
import InputCheck from '../../Resources/Images/Logos/input-check.png';
import InputError from '../../Resources/Images/Logos/input-error.png';
import { GLOBAL } from "../../Config/global";
import { FORM_ERRORS } from "../../Config/form_errors";
import { APPCONFIG } from '../../app.config';
import userAuth from "../../Config/userauth";
import Cookies from 'universal-cookie';
import { USER_TOKEN } from '../../Config/userToken';
import { checkPassword, ValidarCuit } from "../../Shared/Utils/form_validation";
import { ErrorLevel } from "../../Components/Alert/errorLevel";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.confirm = (new URLSearchParams(window.location.search).has("confirm"));
    this.recover = (new URLSearchParams(window.location.search).has("recover-success"));

    this.state = {
      name: '',
      redirect: null,
      showAlert : false
    };

    const cookies = new Cookies();
    let cookie = cookies.get('cookie-origenes');
    if (cookie) {
      //this.rememberMe = true;
      //this.setState({ name: this.state.name});
      this.userInfo = {
        cuit: cookie.cuit,
        password: cookie.password,
        rememberMe: cookie.rememberMe
      };
    } else {
      this.userInfo = { cuit: '', password: '' };
    }

    this.cuit = "";
    this.password = "";
    this.rememberMe = false;
    this.submitForm = false;

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  handleKeyDown = event => {
    // Chek which field
    if (event.target.id === "password") {
      if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
        event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
        (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
        event.preventDefault();
      }
    } else {
    }
  };

  handleChange = event => {
    // Chek which field
    switch (event.target.id) {
      case "cuit":
        this.cuit = this.cuit.trim();
        if(parseInt.cuit){
          this.cuit = (event.target.value) ? event.target.value.replace(/-/g, "") : "";
          if (this.cuit.length === 11 && ValidarCuit(this.cuit)) {
            this.cuitError = "";
            this.cuitErrorBorderColor = "success";
            this.cuitErrorIcon = InputCheck;
          } else {
            this.cuitError = "";
            this.cuitErrorBorderColor = "";
            this.cuitErrorIcon = "";
          }
        }
        
        break;

      case "password":
        if (event.target.value.length >= 6 && checkPassword(event.target.value)) {
          this.passwordError = "";
          this.passwordErrorIcon = InputCheck;
          this.passwordErrorBorderColor = "success";
        } else {
          this.passwordError = "";
          this.passwordErrorIcon = "";
          this.passwordErrorBorderColor = "";
        }
        break;

      default:
        // Do Nothing
        break;
    }

    this.setState({ name: this.state.name })
  };

  handleInputBlur = event => {


    const { COMMON_ERRORS } = FORM_ERRORS;
    // Chek which field

    switch (event.target.id) {
      case "cuit":
        // Remove "-"
        this.cuit = (event.target.value) ? event.target.value.replace(/-/g, "") : "";
        this.cuit = this.cuit.trim();
        if(!parseInt.cuit){
            console.log("Es un email");
        }
        else if (event.target.value.length <= 0) {
          this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
          this.cuitErrorIcon = InputError;
          this.cuitErrorBorderColor = "error";
        } else {
          if (this.cuit.length === 11 && ValidarCuit(this.cuit)) {
            this.cuitError = "";
            this.cuitErrorBorderColor = "success";
            this.cuitErrorIcon = InputCheck;
          } else {
            // Validate data
            this.cuitValid = ValidarCuit(this.cuit);
            this.cuitErrorIcon = InputError;
            this.cuitErrorBorderColor = "error";
            this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;

          }
        }
        break;
      case "password":
        if (event.target.value.length <= 0) {
          this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
          this.passwordErrorIcon = InputError;
          this.passwordErrorBorderColor = "error";
        } else {
          if (event.target.value.length < 6) {
            this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
            this.passwordErrorIcon = InputError;
            this.passwordErrorBorderColor = "error";
          } else if (!checkPassword(event.target.value)) {
            this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
            this.passwordErrorIcon = InputError;
            this.passwordErrorBorderColor = "error";
          } else {
            this.passwordError = "";
            this.passwordErrorIcon = InputCheck;
            this.passwordErrorBorderColor = "success";
          }
        }
        break;
      default:
        // Do Nothing
        break;
    }

    this.setState({ name: this.state.name });
  };

  handleSubmit = event => {

    const { APPNAME } = GLOBAL;
    const { COMMON_ERRORS } = FORM_ERRORS;
    const { SECURITYURL } = APPCONFIG.ENDPOINTS;

    event.preventDefault();
    new FormData(event.target);

    this.confirm = false;
    this.recover = false;
    this.submitForm = true;
    this.cuit = event.target.cuit.value;
    this.password = event.target.password.value;
    this.rememberMe = event.target.remember.checked;

    this.cuitError = "";
    this.passwordError = "";
    this.formError = "";
    this.formErrorLevel = ErrorLevel.Error;

    this.cuitErrorIcon = "";
    this.passwordErrorIcon = "";
    this.cuitErrorBorderColor = "";
    this.passwordErrorBorderColor = "";

    this.loadingForm = false;

    if (!this.cuit || (parseInt(this.cuit) && this.cuit.length < 11)) {
      this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.password) {
      this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }

    if (!this.cuitError) {
       // Validate data
      if(parseInt(this.cuit)){
        this.cuit = (this.cuit) ? this.cuit.replace(/-/g, "") : "";
        this.cuitValid = ValidarCuit(this.cuit);
      }
      else
        this.cuitValid = true;
      
      if (!this.cuitValid) {
        this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;
      }
    }

    if (!this.passwordError) {
      if (this.password.length < 6) {
        this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
      }
    }

    if (this.cuitError || this.passwordError) {
      this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
      this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
      this.passwordErrorIcon = (this.passwordError) ? InputError : "";
      this.passwordErrorBorderColor = (this.passwordError) ? "error" : "success";
      this.loadingForm = false;
      this.setState({ name: this.state.name })
    } else {
      this.cuitErrorIcon = InputCheck;
      this.cuitErrorBorderColor = "success";
      this.loadingForm = true;
      this.setState({ name: this.state.name });

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          Username: this.cuit,
          Password: this.password,
          RememberMe: this.rememberMe,
          Application: APPNAME
        })
      };

      return fetch(SECURITYURL + "account/login", requestOptions)
        .then((response) => {
          return response.json()
        })
        .then(this.handleResponse)
        .catch(this.handleResponse);
    }
  };

  handleResponse(response) {
    const cookies = new Cookies();
    if(response){
      this.responseBase = response;
    }
    if (response.success) {

      if (response.isLogon) {
        if (this.rememberMe) {
          cookies.set('cookie-origenes', { cuit: this.cuit, password: this.password, rememberMe: this.rememberMe });
        } else {
          cookies.remove('cookie-origenes');
        }
        userAuth.authenticate(response.userInfo, () => {
          if (response.authenticationResult && response.authenticationResult.success) {
            USER_TOKEN.set({ token: response.authenticationResult.token, refreshToken: response.authenticationResult.refreshToken });
          }
        });

        //verifica los datos del usuario y asegura que exista el cache de los mismos
        var self = this;
        var api = APPCONFIG.ENDPOINTS.PRODUCERURL + "api/login/CheckUserInfo?userId=" + response.user.id;
        fetch(api)
        .then(() => {
            this.loadingForm = false;
            self.setState({ redirect: "/home" });
        })
        .catch((error) => {
            this.loadingForm = false;
            console.log(error);
        });
      }
      else{
        this.loadingForm = false;
        this.setState({ showAlert : true})
      }
    }
    else{
      this.loadingForm = false;
      this.setState({ showAlert : true})
    }
  }

  onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      document.getElementById("btnSubmit").click();
    }
  }

  render() {
    const { LOGIN_FORM, LOGIN } = GLOBAL;
    const mailNotReceived = {
      color: '#76777A',
      fontSize: '11px'
    };
    const mailNotReceivedAnchor = {
      color: '#00945E',
      fontSize: '12px'
    };

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (

      <section>

        <Loading visible={this.loadingForm} />
        <Header />
        <section className="MainContainer login">
          <section className="container MainContainer__Center">
            <div className="row MainContainer__Card rounded">
              <div className="col-md-6 d-none d-md-block px-0">
                <div className="img-area">
                  <img
                    src={loginImageDesktop}
                    className="img-fluid rounded-left"
                    alt={LOGIN.TITLE}
                  />
                  <div className="img-text">
                    <h6 className="title">{LOGIN.TITLE}</h6>
                    <h6>{LOGIN.PORTAL}</h6>
                  </div>
                </div>
              </div>
              <div className="col-12 d-block d-md-none px-0">
                <div className="img-area">
                  <img
                    src={loginImageMobile}
                    className="img-fluid"
                    alt={LOGIN.TITLE}
                  />
                  <div className="img-text">
                    <h1 className="title">{LOGIN.TITLE}</h1>
                    <h2 className="welcome-text">{LOGIN.SUBTITLE}</h2>
                  </div>
                </div>
              </div>
              <div className="col-md-6 my-auto">
                {
                  (this.state.showAlert && this.responseBase.businessExceptions) &&
                  <div id="form-error-message" className="col-12 my-2">
                    <div className="col-12">
                      <Alert
                        icon="times"
                        message={this.responseBase.businessExceptions[0]?.message}
                        title={this.responseBase.businessExceptions[0]?.title}
                        level={this.responseBase.level}
                        noDetail={true}
                      />
                    </div>
                  </div>
                }
                <form id="login_form" onSubmit={this.handleSubmit} onKeyDown={this.onKeyDown} className="login__Form">
                  <FormTitle text={LOGIN_FORM.LOG_IN} />
                  <Input
                    id="cuit"
                    type="text"
                    text={LOGIN_FORM.CUIT}
                    defaultValue={this.userInfo.cuit}
                    borderColor={this.cuitErrorBorderColor}
                    imgSrc={this.cuitErrorIcon}
                    errorMsg={this.cuitError}
                    onBlur={this.handleInputBlur}
                    onChange={this.handleChange}
                  />
                  <Input
                    id="password"
                    type="password"
                    text={LOGIN_FORM.PASSWORD}
                    defaultValue={this.userInfo.password}
                    maxLength="15"
                    borderColor={this.passwordErrorBorderColor}
                    imgSrc={this.passwordErrorIcon}
                    errorMsg={this.passwordError}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.handleInputBlur}
                    onChange={this.handleChange}
                  />
                  <div className="row checkbox-margin">
                    <div className="col-6 pr-0">
                      <Checkbox
                        id="remember"
                        checked={this.userInfo.rememberMe}
                        fontSize="smaller-text"
                        text={LOGIN_FORM.REMEMBER_PASSWORD}
                      />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <Link to="/recover-password" className="link smaller-text">
                        {LOGIN_FORM.RECOVER_PASSWORD}
                      </Link>
                    </div>
                  </div>
                  <Button type="submit" id="btnSubmit">{LOGIN_FORM.BUTTON_TEXT}</Button>
                  <div className="text-center text-padding text-link-label">
                    <p>
                      {LOGIN_FORM.MISSING_ACCOUNT}{" "}
                      <Link to="/register" className="link">
                        {LOGIN_FORM.REGISTER}
                      </Link>
                    </p>
                  </div>
                  <div className="text-center text-padding text-link-label">
                    <p className="link-login">
                      {"¿Dudas para"}{" "}
                      <Link to="/tutoriales?seccion=como-registrarse" className="link" target="_blank">
                        registrarse
                      </Link>{" o "}<Link to="/tutoriales?seccion=como-ingresar" className="link" target="_blank">
                        acceder
                      </Link>{"?"}
                    </p>
                    <p className="infoLife">{LOGIN_FORM.INFO_RETIRO_WEB} <a  className="link-login" href="https://productores.origenes.com.ar">ingresando aquí.</a></p>
                  </div>
                  {
                    (this.formErrorLevel === ErrorLevel.Error) &&
                    <div className="container">
                      <div className="row">
                        <div>
                          <span style={mailNotReceived}> {LOGIN_FORM.MAILNOTRECEIVED} </span>
                          <a href="/recover-account" style={mailNotReceivedAnchor} > click aqui</a>
                        </div>
                      </div>
                    </div>
                  }
                </form>
              </div>
            </div>
          </section>
        </section>

      </section>
    );
  }
}

export default withRouter(Login);
