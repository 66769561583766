import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { array, number, string } from 'prop-types';
import { GLOBAL } from '../../Config/global';

const NotificationPanel = ({ link, notificationCount, unreadNotifications }) => {
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const showDropdown = () => {
    setDropdownMenu(!dropdownMenu);
  }

  const { NOTIFICATIONS_PANEL } = GLOBAL;

  return (
    <>
      {dropdownMenu && (
        <section className="menu rounded">
          <div className="menu-title rounded-top">
            { NOTIFICATIONS_PANEL.TITLE }
          </div>
          <ul>
            {unreadNotifications.map(notif => (
              <li key={notif}>{ notif }</li>
            ))}
          </ul>
          <div className="text-center menu-link">
            <Link to={link} className="link-text">{ NOTIFICATIONS_PANEL.SEE_MORE }</Link>
          </div>
        </section>
      )}
      <span className="notification" onClick={showDropdown}>
        <i className="far fa-bell notification-icon"></i>
        <button className="notification-badge">{ notificationCount }</button>
      </span>
    </>
  );
}

NotificationPanel.defaultProps = {
  notificationCount: 0,
};

NotificationPanel.propTypes = {
  notificationCount: number,
  unreadNotifications: array.isRequired,
  link: string.isRequired,
};

export default NotificationPanel;
