import React from 'react';
import { string } from "prop-types";
import { DEFAULT_HEADER } from '../../Config/userToken';
import { APPCONFIG } from '../../app.config';
export default class LabelSection extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      text: null,
      error: null
    };

    this.handleResponseOk = this.handleResponseOk.bind(this);
    this.handleResponseError = this.handleResponseError.bind(this);
  }

  componentDidMount() {

    this.loadLabelSection();

  }

  loadLabelSection() {
    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
    const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};
    
    return fetch(PRODUCERURL + `api/Label/getLabelSection?Section=${this.props.section}&Type=${this.props.type}`, requestOptions)
      .then((response) => {
        if (response.ok)
          this.handleResponseOk(response);
        else
          this.handleResponseError(response);
      })
  }


  handleResponseOk(response) {
    response.json()
    .then((data) => {
      var textHtml = (data.Text && data.Text.length > 0) ? data.Text.replace(/\n/g, '<br />') : '';
      this.setState({ text: textHtml });
    });
  }

  handleResponseError(response) {
    response.text()
    .then((error) => {
      this.setState({ error: error });
    });
  }


  render() {

    return (
      <div className="label-section-info-container">
        {this.state && !this.state.error && this.state.text &&
          <div className={`label-section-campo-texto ${this.props.className}`} dangerouslySetInnerHTML={{ __html: this.state.text }} />
        }
        {this.state && this.state.error &&
          <span className="label-section-campo-error">{this.state.error}</span>
        }
      </div>
    );

  }

};

LabelSection.propTypes = {
  section: string.isRequired,
  type: string.isRequired,
  className: string
}

LabelSection.defaultProps = {
  className: ''
}
