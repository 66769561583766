import React, { Component } from 'react'
import { Loading, Button, Combobox, DatePicker, Alert } from '..';
import { GLOBAL } from '../../Config/global';
import { APPCONFIG } from '../../app.config';
import { DEFAULT_HEADER } from '../../Config/userToken';
import * as QLIBS from '../../qLibsHelpers';
import moment from "moment";

const DiaSemanaEnum = [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ];

export default class SchedulerProcessModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingData: false,
            showForm: false,
            errorMessage: null,
            entity: null,
            values: {
                PeriodHora: "00:00",
                PeriodSemanal: 0,
                PeriodMensual: 1,
                PeriodFijo: new Date()
            }
        }

        this.getSchedulerProcess = this.getSchedulerProcess.bind(this);
        this.clearSchedulerProcess = this.clearSchedulerProcess.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleComboChange = this.handleComboChange.bind(this);
        this.removeExecution = this.removeExecution.bind(this);
        this.addExecution = this.addExecution.bind(this);
    }

    componentDidMount() {

        let id = this.props.id;
        if (id === 0) {
            this.clearSchedulerProcess(); 
        }
        else {
           this.getSchedulerProcess(id); 
        }

    }

	getSchedulerProcess = (id) => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		this.setState({loadingData: true, showForm: false, errorMessage: null, entity: null });

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};			
		return fetch(`${PRODUCERURL}api/notification/GetSchedulerProcess?id=${id}`, requestOptions)
		.then((response) => {

			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then((response) => {
                    const entity = response;
                    this.setState({ loadingData: false, showForm: true, entity: entity });
                })
				.catch((response) => {
                    const message = 'Error procesando datos';
                    this.setState({ loadingData: false, showForm: false, errorMessage: message });
                });
			}
			else {
				const message = `Error procesando solicitud: ${response.statusText}`;
				this.setState({ loadingData: false, showForm: false, errorMessage: message });
			}

		});
	}

    clearSchedulerProcess = () => {
        const entity = {
            id: 0,
            UrlApi: this.props.UrlApi,
            UserId: '',
            Period: 0,
            Enabled: true,
            Type: 'NotificationGeneration',
            Executions: [],
            Filters: {}

        };
        this.setState({ loadingData: false, showForm: true, entity: entity });
    }

    validar() {

        return true;
    }


    handleSubmit = () => {

        if (this.validar()) {
            const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

            let data = {...this.state.entity};
            this.setState({loadingData: true, showForm: false, errorMessage: null });

            const requestOptions = {
                method: 'POST',
                headers: DEFAULT_HEADER.get(),
                body: JSON.stringify(data)
            };			
            return fetch(`${PRODUCERURL}api/scheduler/SaveSchedulerProcess`, requestOptions)
            .then((response) => {

                if (response.ok) {
                    QLIBS.authorizeAction(response)
                    .then((response) => {
                        this.setState({ loadingData: false, showForm: false, entity: null, errorMessage: null });
                        this.props.dismiss(true);
                    })
                    .catch((response) => {
                        const message = 'Error procesando datos';
                        this.setState({ loadingData: false, showForm: false, errorMessage: message });
                    });
                }
                else {
                    const message = `Error procesando solicitud: ${response.statusText}`;
                    this.setState({ loadingData: false, showForm: false, errorMessage: message });
                }

            });
        }

    }

    handleInputChange = event => {
        const isCheckbox = event.target.type === "checkbox";

        let inputName = event.target.name ? event.target.name : event.target.id;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;

        let values = Object.assign({}, this.state.values);

        switch (inputName) {
            case "ctlPeriodFijo":
                values.PeriodFijo = new Date(inputValue);
                break;
            default:
                break;
        }

        this.setState({ values: values });
    }

    handleComboChange = (selectedOption, event, index) => {
        let inputName = event.name;
        let inputValue = selectedOption.value;

        let entity = Object.assign({}, this.state.entity);
        let values = Object.assign({}, this.state.values);
        
        switch (inputName) {
            case "ctlPeriod":
                entity.Period = inputValue;
                break;
            case "ctlPeriodSemanal":
                values.PeriodSemanal = inputValue;
                break;
            case "ctlPeriodMensual":
                values.PeriodMensual = inputValue;
                break;
            case "ctlPeriodHora":
                values.PeriodHora = inputValue;
                break;
            default:
                break;
        }

        this.setState({ entity: entity, values: values });
        
    }

    removeExecution = (index) => {
        let entity = {...this.state.entity};
        entity.Executions = entity.Executions.filter((f, i) => i !== index);
        this.setState({ entity: entity });
    }

    addExecution = () => {
        const execution = {
            Hora: this.state.values.PeriodHora,
            DiaSemana: (this.state.entity.Period === 1) ? this.state.values.PeriodSemanal : 0,
            DiaMes: (this.state.entity.Period === 2) ? this.state.values.PeriodMensual : 0,
            DiaFijo: (this.state.entity.Period === 3) ? moment(this.state.values.PeriodFijo).format("DD/MM/YYYY") : null
        };

        if (this.state.entity.Period == 3 && execution.DiaFijo == null) {
            return;
        }

        let entity = Object.assign({}, this.state.entity);
        
        entity.Executions = [execution, ...entity.Executions];
        this.setState({ entity: entity });
    }

    render() {
        const { COMMON_MODAL_FORM } = GLOBAL;

        return (
		    <>
                <Loading visible={this.state.loadingData}></Loading>

                <div id="form-scheduler-process" className="modal" tabIndex="-1" role="dialog"  aria-modal="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title text-center">Calendario de ejecución</h4>
                            </div>
                            <div className="modal-body">

                                {this.state.errorMessage && (<Alert type="error" title="ERROR" message={this.state.errorMessage} noDetail={true} />)}
                                {this.state.showForm && (
                                 <section>
                                     <div className="row pb-1 pt-4">
                                        <div className="col-12">
                                            <Combobox
                                                id="ctlPeriod"
                                                label={"Tipo de Periodo"}
                                                options={[
                                                    { value: 0, label: "Diario" },
                                                    { value: 1, label: "Semanal" },
                                                    { value: 2, label: "Mensal" },
                                                    { value: 3, label: "Fecha Fija" }
                                                ]}
                                                isDisabled={this.state.entity.Executions.length > 0}
                                                onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                defaultValue={this.state.entity.Period}
                                            />
                                        </div>
                                    </div>
                                    <div className="row pb-1">
                                        <div className="col-4">
                                        {this.state.entity.Period == 1 && (
                                            <Combobox
                                                id="ctlPeriodSemanal"
                                                label={"Día de la semana"}
                                                options={[
                                                    { value: 0, label: "Domingo" },
                                                    { value: 1, label: "Lunes" },
                                                    { value: 2, label: "Martes" },
                                                    { value: 3, label: "Miércoles" },
                                                    { value: 4, label: "Jueves" },
                                                    { value: 5, label: "Viernes" },
                                                    { value: 6, label: "Sábado" }
                                                ]}
                                                onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                defaultValue={this.state.values.PeriodSemanal}
                                            />
                                        )}
                                        {this.state.entity.Period == 2 && (
                                            <Combobox
                                                id="ctlPeriodMensual"
                                                label={"Día del mes"}
                                                options={Array.from(new Array(31), (val, index) => index + 1).map((day) => {
                                                    return {
                                                        label: String(day).padStart(2, '0'),
                                                        value: day,
                                                    }
                                                })}
                                                onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                defaultValue={this.state.values.PeriodMensual}
                                            />
                                        )}
                                        {this.state.entity.Period == 3 && (
                                         <div>
                                            <label className="pb-10px">{"Fecha"}</label>
                                            <DatePicker
                                                id="ctlPeriodFijo"
                                                maxLength="10"
                                                noDefaultValue="true"
                                                onChange={this.handleInputChange}
                                                defaultValue={this.state.values.PeriodFijo.toString()}
                                            />
                                         </div>
                                        )}
                                        </div>
                                        <div className="col-4">
                                            <Combobox
                                                id="ctlPeriodHora"
                                                label={"Hora"}
                                                options={Array.from(new Array(24), (val, index) => index).map((hour) => {
                                                    return {
                                                        label: String(hour).padStart(2, '0') + ":00",
                                                        value: String(hour).padStart(2, '0') + ":00",
                                                    }
                                                })}
                                                onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                defaultValue={this.state.values.PeriodHora}
                                            />
                                        </div>
                                        <div className="col-4 pt-35px">
                                            <Button type="button" size="icon" fill="outline" onClick={() => { this.addExecution() }}>Agregar</Button>
                                        </div>
                                    </div>
                                    <div className="row pb-1">
                                        <div className="col-12">
                                            <div className="container contenedor-scheduler-process">
                                            {this.state.entity.Executions.map((exe, index) => {

                                                    return (
                                                        <div key={index}
                                                            id={"exe_" + index} 
                                                            className="item-scheduler-process"
                                                        >
                                                            <div className="col-md-10 item-descripcion-scheduler-process">
                                                                {(this.state.entity.Period === 0 && <span>Todos los días a las {exe.Hora}hs.</span>)}
                                                                {(this.state.entity.Period === 1 && <span>Todos los {DiaSemanaEnum[exe.DiaSemana]} a las {exe.Hora}hs.</span>)}
                                                                {(this.state.entity.Period === 2 && <span>Todos los {exe.DiaMes} de cada mes a las {exe.Hora}hs.</span>)}
                                                                {(this.state.entity.Period === 3 && <span>El día {exe.DiaFijo} a las {exe.Hora}hs.</span>)}
                                                            </div>
                                                            <div className="col-md-2 item-accion-scheduler-process">
                                                                {(this.state.entity.Executions.length > 1 &&
                                                                    <button onClick={() => { this.removeExecution(index) }} title="quitar ejecución"><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );

                                                })
                                            }
                                            </div>
                                        </div>
                                     </div>
                                 </section>
                                )}
                            
                            </div>
                            <div className="modal-footer">
                                {this.state.showForm && (
                                    <Button type="button" size="medium-lg" fill="outline" onClick={() => { this.handleSubmit() }}>{COMMON_MODAL_FORM.SAVE_BUTTON}</Button>
                                )}
                                <Button type="button" size="medium-lg" fill="outline" color="secondary" data-dismiss="modal" onClick={() => { this.props.dismiss(false) }}>{COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
