import React from 'react';
import { Link } from 'react-router-dom';
import { GLOBAL } from '../../Config/global';
import { MOCK_DATA } from '../../Config/mock-data';
import { BackButton, Button, Checkbox, FormTitle, Input, Header, SubTitle } from "../../Components";
import recoverSuccessImage from '../../Resources/Images/FormImages/login-form.png';

const RecoverSuccess = () => {
  const handleSubmit = event => {
    event.preventDefault();
  }

  const { RECOVER_SUCCESS, LOGIN_FORM } = GLOBAL;
  const { LOGIN_FORM: RECOVER_DATA } = MOCK_DATA;

  return (
    <section>
      <Header centered />
      <section className="MainContainer recover-success">
        <section className="container MainContainer__Center">
          <div className="row MainContainer__Card rounded">
            <div className="col-lg-6 px-0 d-none d-lg-block">
              <img src={ recoverSuccessImage } className="img-fluid" alt="Hermanos en un campo" />
            </div>
            <div className="col-lg-1 recover-reset__Back d-md-block d-none">
              <BackButton />
            </div>
            <div className="col-lg-4 my-auto">
              <form onSubmit={ handleSubmit } className="recover-success__Form px-0">
                <FormTitle
                  text={RECOVER_SUCCESS.TITLE}
                />
                <SubTitle>{ RECOVER_SUCCESS.TITLE_MESSAGE }</SubTitle>
                <Input
                  text={RECOVER_SUCCESS.USER}
                  type="text"
                  defaultValue={RECOVER_DATA.USER}
                />
                <Input
                  text={RECOVER_SUCCESS.PASSWORD}
                  type="password"
                  defaultValue={RECOVER_DATA.PASSWORD}
                />
                <div className="row checkbox-margin">
                  <div className="col-6">
                    <Checkbox
                      fontSize="smaller-text"
                      text={LOGIN_FORM.REMEMBER_PASSWORD}
                    />
                  </div>
                  <div className="col-6 d-flex justify-content-end pl-0">
                    <Link to="/recover-password" className="link smaller-text">{ LOGIN_FORM.RECOVER_PASSWORD }</Link>
                  </div>
                </div>
                <Link to="/user">
                  <Button>{ RECOVER_SUCCESS.BUTTON_TEXT }</Button>
                </Link>
                <div className="text-center text-padding text-link-label">
                  <p>
                    { LOGIN_FORM.MISSING_ACCOUNT }{" "}
                    <Link to="/register" className="link">{ LOGIN_FORM.REGISTER }</Link>
                  </p>
                </div>
              </form>
            </div>
            <div className="col-lg-1 recover__Back d-md-block d-none" />
          </div>
        </section>
      </section>
      {/*<Footer/>*/}
    </section>
  )
}

export default RecoverSuccess;
