import React from "react";
import { Alert, Header } from "../../Components";
import { GLOBAL } from "../../Config/global";

const RegisterValidation = () => {
  const { REGISTER_VALIDATION} = GLOBAL;

  return (
    <section>
      <Header showBackButton={false} />
      <section className="form form-container mb-5">
        <div className="container">
          <div className="row mb-2 mt-4">
            <div className="col-12">
              <Alert
                type="success"
                icon="check"
                title=""
                message={REGISTER_VALIDATION.MESSAGE}
              />
              <h1 className="form-success-title">
                {REGISTER_VALIDATION.TITLE}
              </h1>
              <div className="row mb-5">
                <div className="col-md-5">
                  {REGISTER_VALIDATION.SUBTITLE}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<Footer  />*/}
    </section>
  );
};

export default RegisterValidation;
