import React from 'react';
import { Actions, Alert, Breadcrumb, Button, Loading, Table, ViewsModal } from '../../Components';
import { APPCONFIG } from '../../app.config';
import { DEFAULT_HEADER } from '../../Config/userToken';
import userAuth from '../../Config/userauth';
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from "../../Config/global";
import { truncate } from '../../Shared/Utils';
import moment from 'moment';
import PagoLiquidacionesDrillDown from './partials/drillDown';
import { TipoRegistro } from './partials/tipoRegistro';
import { APIS } from '../../Config/apis';
import { ErrorLevel } from '../../Components/Alert/errorLevel';
import { TipoCertificado } from '../../Shared/Utils/Models/TipoCertificadoEnum';

class PagosYLiquidaciones extends React.Component {
    constructor(props) {
        super(props);

        this.page = 0;
        this.pageSize = 10;

        this.state = {
            filterTabActive: 3,
            data: [],
            filters: [],
            sort: [],
        }
        this.onChangeTab = this.onChangeTab.bind(this);
        this.baseTableColumns = this.createBaseTableColumns();
        this.handleSaveViewModal = this.handleSaveViewModal.bind(this);
        this.listTableColumns = this.createListTableColumns();

    }

    handleSaveViewModal(newListColumns) {
        const tempTableColumns = this.createBaseTableColumns();

        this.baseTableColumns = newListColumns.map(c => {
            let col = tempTableColumns.find(f => f.name === c.name);
            col.show = c.show;
            return col;
        });

        this.listTableColumns = this.createListTableColumns();
    }

    getFilter() {
        let filters = this.state.filters
        return filters
    }
    getSort() {
        let sort = this.state.sort
        return sort
    }
    onChangeTab = index => {
        this.setState({ name: this.state.name, filterTabActive: index, filtersSelected: (index > 0) });
    };
    applySort = (event) => {

        if (event.length > 0) {
            this.setState({
                sort: event,
            }, this.handleSubmit);
        }
        else {
            this.setState({
                sort: {},
            }, this.handleSubmit);
        }
    }
    handleChange = event => {
        switch (event.target.id) {
            case "nro_poliza":
                this.poliza = event.target.value
                break;

            default:
                break;
        }
    }

    createBaseTableColumns() {
        const { REPORT_FORMAT } = GLOBAL;
        let newTableColumns = [];
        let columns = {
            "fecha": { title: "Fecha", tooltip: 'Fecha Efectiva de Pago / Fecha de Liquidación', field: 'Fecha', format: REPORT_FORMAT.FECHA, show: true, width: 120 },
            "tipoRegistro": { title: "Tipo", tooltip: 'Tipo de movimiento', field: 'TipoRegistro', format: REPORT_FORMAT.TEXTO, show: true, width: 100 },
            "numeroSolicitudPago": { title: "Referencia", tooltip: 'Número de solicitud de pago', field: 'Referencia', show: true, width: 120 },
            "tipoPago": { title: "Tipo de pago", field: 'TipoPago', format: REPORT_FORMAT.TEXTO, show: true, width: 100 },
            "importePagado": { title: "Importe Pagado", field: 'ImportePagado', format: REPORT_FORMAT.MONEDA, show: true },
            "importeBruto": { title: "Comisión Total", field: 'ComisionTotal', format: REPORT_FORMAT.MONEDA, show: true },
            "importeNeto": { title: "Comisión Neta", field: 'ComisionNeto', format: REPORT_FORMAT.MONEDA, show: true },
            "importeIVANoDeducible": { title: "IVA 21%", field: 'ImporteIVANoDeducible', format: REPORT_FORMAT.MONEDA, show: true },
            "importeIVACreditoFiscal": { title: "IVA 3%", field: 'ImporteIVACreditoFiscal', format: REPORT_FORMAT.MONEDA, show: true },
            "ibOsMunicip": { title: "IB-OS-Municip", tooltip: 'Certificado de Retención de IB-OS-Municip', sortable: false, format: REPORT_FORMAT.MONEDA, show: true, width: 150, filterable: false },
            "ganancias": { title: "Ganancias", tooltip: 'Certificado de Retención Ganancias', sortable: false, format: REPORT_FORMAT.MONEDA, show: true, width: 150, filterable: false },
        };
        let cols = QLIBS.getTableColumns(columns);
        cols.forEach((c) => { newTableColumns.push(c) });
        return newTableColumns;
    }

    downloadItem = (item, tipoCertificado) => {
        this.getCertificado(item, tipoCertificado)
    }

    handleResponseOk = (response, fileName) => {
        let self = this;
        response.blob()
            .then(function (blob) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            })
            .then(() => {
                self.setState({ certificadoGenerado: true, loadingData: false, item: self.itemInit, showImprimeAsegurados: false });
            });
    }

    handleResponseError = (response) => {
        let self = this;
        self.showResults = true;
        self.searchResultsError = "Error al intentar descargar el certificado.";
        response.text()
            .then(function (message) {
                self.setState({
                    loadingData: false,
                    error: message,
                });

            })
    }

    getCertificado = (event, tipoCertificado) => {
        this.setState({ loadingData: true });
        let fileName = `${TipoCertificado[tipoCertificado]}_${event.numero}_Certificado.pdf`
        const requestOptions = {
            method: 'GET',
            headers: DEFAULT_HEADER.get(),
        };
        const PRODUCERURL = APIS.URLS.DOWNLOAD_CERTIFICADO_PAGOSYLIQ;

        fetch(`${PRODUCERURL}?cuit=${event.cuit}&numeroPago=${event.referencia}&tipoCertificado=${TipoCertificado[tipoCertificado]}`, requestOptions)
            .then(response => {
                if (response.ok)
                    this.handleResponseOk(response, fileName);
                else
                    this.handleResponseError(response);
            })
            .catch(error => {
                this.setState({ error: `Error: ${error.message}` });
            });
    }

    handleSubmit = async (event) => {
        if (event) event.preventDefault();

        const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
        let sort = this.state.sort.length === 0 ? null : this.state.sort[0];
        const requestOptions = {
            method: 'POST',
            headers: DEFAULT_HEADER.get(),
            body: JSON.stringify({
                producerCuit: userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer(),
                next: this.pageSize,
                poliza: this.poliza > 0 ? this.poliza : null,
                offset: this.page * this.pageSize,
                gridFilters: this.state.filters,
                sort: sort,
            })
        };

        var url = new URL(`${PRODUCERURL}api/Settlement/PagosYLiquidaciones`);
        this.setState({ loadingData: true });
        const x = await fetch(url, requestOptions);
        const response = await x.json();
        if (response) {
            this.totalItems = response.total;
            this.pages = Math.ceil(response.total / this.pageSize);
            if (Array.isArray(response.data)) {
                const formatData = response.data.map((item) => {
                    return {
                        numero: item.numero,
                        fecha: moment(item.fecha).format("DD/MM/YYYY"),
                        tipoRegistro: item.tipoRegistro === TipoRegistro.Liquidacion ? 'Liquidación' : 'Pago',
                        numeroSolicitudPago: item.tipoRegistro === TipoRegistro.Pago ? item.referencia : '',
                        importePagado: item.importePagado ? `$ ${truncate(item.importePagado, 2)}` : '',
                        importeBruto: item.tipoRegistro === TipoRegistro.Liquidacion ? `$ ${truncate(item.comisionTotal, 2)}` : '',
                        importeNeto: item.comisionNeto ? `$ ${truncate(item.comisionNeto, 2)}` : '',
                        importeIVACreditoFiscal: item.ivA3 ? `$ ${truncate(item.ivA3, 2)}` : '',
                        importeIVANoDeducible: item.ivA21 ? `$ ${truncate(item.ivA21, 2)}` : '',
                        ibOsMunicip: item.tipoRegistro === TipoRegistro.Pago ? (
                            <Actions
                                customTitle='Generar certificado'
                                customIcon="file-download"
                                onCustom={() => this.downloadItem(item, TipoCertificado.IIBBObraSocialMunicipal)}
                                content={`$ ${truncate(item.ibOsMunicip, 2)}`}
                            />
                        ) : '',
                        referencia: item.referencia,
                        tipoPago: item.tipoRegistro === TipoRegistro.Pago ? item.tipoPago : '',
                        ganancias: item.tipoRegistro === TipoRegistro.Pago && item.ganancias > 0 ? (
                            <Actions
                                customTitle='Generar certificado'
                                customIcon="file-download"
                                onCustom={() => this.downloadItem(item, TipoCertificado.Ganancias)}
                                content={`$ ${truncate(item.ganancias, 2)}`}
                            />
                        ) : ''
                    }
                });
                this.setState({ data: formatData, loadingData: false });
            };

        } else {
            this.setState({ loadingData: false });
        }
    }

    onKeyDown = (event) => {
        if (event.key === 'Enter' && this.state.filters) {
            this.handleSubmit()
        }
    }
    createListTableColumns() {
        const newTableColumns = [...this.baseTableColumns.filter(f => f.show)];
        return newTableColumns;
    }

    async componentDidMount() {
        this.handleSubmit()

    }
    createExcelReportColumns() {
        const excelReportColumns = this.baseTableColumns.filter(f => f.show).map(c => {
            return {
                Title: c.title,
                Field: c.field,
                Summarize: c.summarize,
                Format: c.format
            }
        });
        return excelReportColumns;
    }
    applyFilter = (event) => {
        if (event.length > 0) {
            this.page = 0
            this.setState({
                filters: event,
            }, this.handleSubmit);
        }
        else {
            this.setState({
                filters: [],
            }, this.handleSubmit);
        }
    }

    changeSize = async (size) => {
        this.pageSize = size;
        this.handleSubmit()
    }

    changePage = async (page) => {
        this.page = page;
        this.handleSubmit()
    }

    handleExportSubmit(event) {
        const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

        event.preventDefault();
        this.setState({ loadingData: true });

        const requestOptions = {
            method: 'POST',
            headers: DEFAULT_HEADER.get(),
            body: JSON.stringify({
                producerCuit: userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer(),
                filters: this.state.searchCriteriaText?.replace(/['"]+/g, "\\'"),
                columns: JSON.stringify(this.createExcelReportColumns())
            })
        };

        fetch(`${PRODUCERURL}api/Settlement/PagosYLiquidaciones/Export`, requestOptions)
            .then(function (resp) {
                if (resp.ok)
                    return resp.blob();
            })
            .then(function (blob) {
                if (blob) {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "PagosYLiquidaciones.xlsx";
                    link.click();
                }
            })
            .then(() => {
                this.setState({ loadingData: false });
            })
    };

    clearFiltros = () => {
        this.page = 0;
        this.setState({ filters: [], sort: [] },
            () => {
                this.handleSubmit()
            })

    }
    render(...props) {
        const { TABLE_VIEW } = GLOBAL;
        const { COMMON_SEARCH_FORM } = GLOBAL;
        return (
            <>{this.state.showViewsModal && <ViewsModal
                listColumns={this.baseTableColumns}
                onDismiss={() => { this.setState({ showViewsModal: false }) }}
                onSave={(listColumns) => { this.handleSaveViewModal(listColumns) }}
            />}
                <section className="col-12">
                    <div className="row pagosLiquidaciones">
                        <div className="col-12">
                            <Breadcrumb currentPage="/pagos-liquidaciones" />
                        </div>
                        <div className="form-container container table-view col-md-12 mt-2">
                            <div id="comisiones_search_form_1"
                                // onSubmit={this.handleSubmit} 
                                //  onKeyDown={this.onKeyDown} 
                                className="register__Form" noValidate>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="search-buttons">
                                                    <Button type="submit" id="btnSubmit" classes="to-bottom button-small search-button"><span className="fa fa-search"> </span> {COMMON_SEARCH_FORM.SEARCH_BUTTON}</Button>
                                                </div>
                                                <div className="search-buttons">
                                                    <Button type="button" classes="to-bottom button-small search-button" onClick={this.clearFiltros}><span className="fa fa-filter"> </span> {COMMON_SEARCH_FORM.CLEAR_FILTERS}</Button>
                                                </div>
                                                {(this.state.data.length > 0) && (
                                                    <div className="search-buttons">
                                                        <Button type="submit" onClick={event => this.handleExportSubmit(event)} size="small">
                                                            <i className="fas fa-file-download mx-2" /> {TABLE_VIEW.EXPORT_TEXT}</Button>
                                                    </div>
                                                )}
                                                <div className="search-buttons">
                                                    <Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showViewsModal: true }) }}><span className="fa fa-table"> </span> {COMMON_SEARCH_FORM.VIEW_BUTTON}</Button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {this.state.error && <div id="form-error-message" className="row my-2">
                                    <div className="col-12">
                                        <Alert
                                            icon="times"
                                            message=""
                                            title={this.searchResultsError}
                                            errorInfo={this.searchResultsError}
                                            level={ErrorLevel.Error}
                                        />
                                    </div>
                                </div>}
                                <div className="row my-3">

                                    <div className="col-12 px-3 table-comisiones">
                                        {userAuth.getGiccpData()?.descripcionTipoDePago === 'Directo' && (
                                            <div>
                                                <p>Facturas pendientes = {userAuth.getGiccpData().cantidadFacturasPendientes}</p>
                                                <p>Facturas pendientes permitidas = {userAuth.getGiccpData().cantidadFacturasPendientesPermitidas}</p>
                                            </div>
                                        )}
                                        <Table
                                            loading={this.state.loadingData}
                                            onKeyDown={this.onKeyDown}
                                            columns={this.listTableColumns}
                                            sorted={this.getSort()}
                                            onSortedChange={this.applySort}
                                            filter={this.getFilter()}
                                            data={this.state.data}
                                            manual={true}
                                            onFilterChange={this.applyFilter}
                                            page={this.page}
                                            pageSize={this.pageSize}
                                            onPageSizeChange={this.changeSize}
                                            pages={this.pages}
                                            expandable={true}
                                            changePage={this.changePage}
                                            subComponent={props => (<PagoLiquidacionesDrillDown width={(window.innerWidth - 120)} {...props} />)} {...props} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    };
}
export default PagosYLiquidaciones;