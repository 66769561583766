import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '..';
import { string } from 'prop-types';
import { GLOBAL } from '../../Config/global';

const ImageCard = ({ description, imgSrc, title, link }) => (
  <div className="image-card rounded">
    <img className="img-fluid" src={imgSrc} alt="" />
    <section className="text-section">
      <h4 className="image-card-title">{ title }</h4>
      <p className="image-card-desc">{ description }</p>
      <Link to={link}>
        <Button size="medium" customStyles={{ padding: '11px 41px' }}>{GLOBAL.COMPONENTS_FORM.IMAGECARD_SEE_MORE}</Button>
      </Link>
    </section>
  </div>
);

ImageCard.propTypes = {
  imgSrc: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  link: string.isRequired,
};

export default ImageCard;
