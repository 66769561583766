import React, { Component } from 'react'
import { Alert, Button, Input, DatePicker, Checkbox, FileUpload, Carousel, Combobox } from '../../Components'
import { FORM_ERRORS } from '../../Config/form_errors';
import { GLOBAL } from '../../Config/global'
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import userAuth from '../../Config/userauth';
import moment from "moment";
import ImageUploader from 'react-images-upload';
import CarouselDImageOne from '../../Resources/Images/FormImages/home-img.jpg';
import { FilePond } from 'react-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

const TYPES = {
    WEB: 0,
    EMAIL: 1
};

export class NovedadesModal extends Component {
    constructor(props) {
        super(props);

        let today = new Date();
        let result = new Date();
        result.setDate(today.getDate() + 7);

        const { entityChild, onDismiss, type, orderMax } = props;
        this.state = {
            onDismiss: onDismiss,
            entityChild: {
                Id: entityChild ? entityChild.Id : 0,
                Type: entityChild ? entityChild.Type : type,
                Title: entityChild && entityChild.Title !== '-' ? entityChild.Title : null,
                SubTitle: entityChild && entityChild.SubTitle !== '-' ? entityChild.SubTitle : null,
                Text: entityChild && entityChild.Text !== '-' ? entityChild.Text : null,
                UntilDate: entityChild ? moment(entityChild.UntilDate, "DD/MM/YYYY") : moment(result, "DD/MM/YYYY"),
                CreationDate: entityChild ? moment(entityChild.CreationDate, "DD/MM/YYYY") : moment(today, "DD/MM/YYYY"),
                HTML: entityChild ? entityChild.HTML : null,
                Image: entityChild ? entityChild.Image : null,
                DefaultImage: entityChild ? entityChild.DefaultImage : false,
                BackgroundSize: entityChild ? (entityChild.BackgroundSize === 'cover' ? true : false) : false,
                Order: entityChild && entityChild.Order.toString() !== '-' ? entityChild.Order : 0,
                URL: entityChild ? entityChild.URL : null,
            },

            preview: false,
            isHTML: false,
            submitOkMessage: '',
            errorMessage: '',

            errorMsg_title: '',
            errorMsg_subtitle: '',
            errorMsg_text: '',
            errorMsg_UntilDate: '',
            errorMsg_CreationDate: '',
            errorMsg_URL: '',

            errorBorder_title: '',
            errorBorder_subtitle: '',
            errorBorder_text: '',
            errorBorder_UntilDate: '',
            errorBorder_CreationDate: '',
            errorBorder_URL: '',

            emailTest: '',
            errorMsg_emailTest: '',
            errorBorder_errorMsg: '',

            baseImage: '',

            orderOptions: [],
            orderMax: orderMax,

            files: [],

            showNews: false
        }
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount() {
        document.getElementById('fileoutput').textContent = (this.state.entityChild.HTML !== null) ? this.state.entityChild.HTML : '';
        this.setState({
            preview: this.state.entityChild.Type === TYPES.EMAIL && this.state.entityChild.HTML !== null && this.state.entityChild.HTML.length > 0,
            isHTML: this.state.entityChild.HTML !== null && this.state.entityChild.HTML.length > 0
        }, () => {
            if (document.getElementById('isHTML')) {
                document.getElementById('isHTML').checked = this.state.isHTML;
            }
            if (document.getElementById('DefaultImage')) {
                document.getElementById('DefaultImage').checked = this.state.entityChild.DefaultImage;
            }
        });

        var self = this;
        var xhr = new XMLHttpRequest()
        xhr.open("GET", CarouselDImageOne);
        xhr.responseType = "blob";
        xhr.send();
        xhr.addEventListener("load", function () {
            var reader = new FileReader();
            reader.readAsDataURL(xhr.response);
            reader.addEventListener("loadend", function () {
                self.setState({ baseImage: reader.result });
            });
        });

        if (this.state.entityChild.Type === TYPES.WEB) {
            let options = this.state.orderOptions;
            for (let index = 1; index <= (this.state.orderMax + 1); index++) {
                options.push({ label: index, value: index });
            }

            let entityCopy = Object.assign({}, this.state.entityChild);
            entityCopy['Order'] = (this.state.entityChild.Id === 0 ? this.state.orderMax + 1 : Number(this.state.entityChild.Order));
            this.setState({ orderOptions: options, entityChild: entityCopy }, () => {
                if (this.state.entityChild.Order > 0) {
                    this.setState({ showNews: true }, () => { document.getElementById("showNews").checked = true; })
                }
            });
        }
    }

    onDrop(pictureFiles, pictureDataURLs) {
        let entityCopy = Object.assign({}, this.state.entityChild);
        entityCopy['Image'] = pictureDataURLs.length ? pictureDataURLs[0] : null;
        this.setState({ entityChild: entityCopy }, () => { this.validate(); });
    }

    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";
        let inputName = event.target.id ? event.target.id : event.target.name;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;
        let entityCopy = Object.assign({}, this.state.entityChild);
        entityCopy[inputName] = inputValue;
        if (isCheckbox && inputName === "DefaultImage" && inputValue) {
            entityCopy['Image'] = null;
        }
        this.setState({ entityChild: entityCopy }, () => {
            if (document.getElementById('DefaultImage')) {
                document.getElementById('DefaultImage').checked = this.state.entityChild.DefaultImage;
            }
        });
    }

    handleComboChange = (selectedOption, event) => {
        let inputName = event.name;
        let inputValue = selectedOption.value;
        let entityCopy = Object.assign({}, this.state.entityChild);
        entityCopy[inputName] = inputValue;
        this.setState({ entityChild: entityCopy });
    };

    isURL(str) {
        var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
        var url = new RegExp(urlRegex, 'i');
        return str.length < 2083 && url.test(str);
    }

    validate() {
        const { COMMON_ERRORS } = FORM_ERRORS;

        let errorMsg_UntilDate = '', errorBorder_UntilDate = '', errorMessage = '', errorMsg_title = '', errorBorder_title = '', errorMsg_CreationDate = '', errorBorder_CreationDate = '', errorMsg_URL = '', errorBorder_URL = '';
        let errors = false;
        let result = false;

        if (this.state.entityChild.Type === TYPES.WEB) {
            if (!this.state.entityChild.CreationDate) {
                errorMsg_CreationDate = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
                errors = true;
            }
            if (this.state.entityChild.URL && !this.isURL(this.state.entityChild.URL)) {
                errorMsg_URL = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.FIELD_INVALID;
                errors = true;
            }
            if (!this.state.entityChild.UntilDate) {
                errorMsg_UntilDate = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
                errors = true;
            }
            else {
                var date = null;
                if (typeof this.state.entityChild.UntilDate === 'string' || this.state.entityChild.UntilDate instanceof String) {
                    date = new Date(this.state.entityChild.UntilDate);
                } else {
                    date = this.state.entityChild.UntilDate.toDate();
                }

                if (date <= new Date()) {
                    errorMsg_UntilDate = COMMON_ERRORS.INITIAL_MARKER + 'Debe ingresar una fecha mayor del dia de hoy';
                    errors = true;
                }
            }

            if (this.state.entityChild.Title === null && this.state.entityChild.SubTitle === null && this.state.entityChild.Text === null && !this.state.entityChild.Image) {
                errorMessage = COMMON_ERRORS.INITIAL_MARKER + 'Es requerido ingresar un titulo, subtitulo y texto con imagen, o solo una imagen';
                errors = true;
            }

            errorBorder_UntilDate = (errorMsg_UntilDate) ? "error" : "success";
            errorBorder_CreationDate = (errorMsg_CreationDate) ? "error" : "success";
            errorBorder_URL = (errorMsg_URL) ? "error" : "success";
        }
        else {
            if (this.state.isHTML === true) {
                let files = document.querySelector('#file').files;
                if (files.length !== 1) {
                    errorMessage = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FILE
                    errors = true;
                }
            }
            else {
                if (this.state.entityChild.SubTitle === null && this.state.entityChild.Text === null && !this.state.entityChild.Image) {
                    errorMessage = COMMON_ERRORS.INITIAL_MARKER + 'Es requerido ingresar subtitulo y texto con imagen, o solo una imagen';
                    errors = true;
                }
            }

            if (this.state.entityChild.Title === null) {
                errorMsg_title = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
                errors = true;
            }
            errorBorder_title = (errorMsg_title) ? "error" : "success";
        }

        this.setState({ errorMsg_UntilDate, errorBorder_UntilDate, errorMessage, errorMsg_title, errorMsg_CreationDate, errorMsg_URL, errorBorder_title, errorBorder_CreationDate, errorBorder_URL });
        if (!errors) {
            result = true;
        }
        return result;
    }

    save() {
        if (this.validate()) {
            let data = this.getData();
            // 
            data['Base64'] = [];
            data['Archivos'] = [];
            this.state.files.forEach(function (file, index) {
                // 
                var fr = new FileReader();
                fr.onload = () => {
                    data.Archivos.push(file.name);
                    data.Base64.push(fr.result);
                };
                fr.readAsDataURL(file);
            });
            let self = this;
            setTimeout(function () {
                QLIBS.fetchDataPOST(data.Id ? APIS.URLS.NEWS_UPDATE : APIS.URLS.NEWS_CREATE, data, true)
                    .then(self.handleResponse)
                    .catch(self.handleResponse);
            }, 2000);
        }
    }

    getData() {
        // 
        return {
            'Id': this.state.entityChild.Id,
            'Type': this.state.entityChild.Type,
            'Title': this.state.entityChild.Title,
            'SubTitle': this.state.entityChild.SubTitle,
            'Text': this.state.entityChild.Text,
            'CreationDate': this.state.entityChild.CreationDate,
            'UntilDate': this.state.entityChild.Type === TYPES.WEB ? this.state.entityChild.UntilDate : null,
            'Image': this.state.entityChild.Image,
            'HTML': this.state.isHTML === true && document.getElementById('fileoutput').textContent ? document.getElementById('fileoutput').textContent : null,
            'DefaultImage': this.state.entityChild.DefaultImage,
            'BackgroundSize': this.state.entityChild.Type === TYPES.WEB ? (this.state.entityChild.BackgroundSize ? 'cover' : 'contain') : null,
            'Order': this.state.entityChild.Type === TYPES.WEB ? (this.state.showNews === true ? this.state.entityChild.Order : 0) : null,
            'URL': this.state.entityChild.Type === TYPES.WEB ? this.state.entityChild.URL : null,
            'User': userAuth.simulatorUserId()
        };
    }

    handleResponse = (response) => {
        if (typeof response === 'object') {
            this.setState({ errorMessage: response.message });
        } else {
            this.state.onDismiss(response);
        }
    }

    sendTestEmail = () => {
        const { COMMON_ERRORS } = FORM_ERRORS;
        let errorMsg_emailTest = '';
        let errorBorder_errorMsg = '';
        let valid = true;

        if (this.state.emailTest === '') {
            errorMsg_emailTest = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            valid = false;
        }
        else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.emailTest))) {
            errorMsg_emailTest = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMAIL_INVALID;
            valid = false;
        }

        errorBorder_errorMsg = (errorMsg_emailTest) ? "error" : "success";

        this.setState({ errorMsg_emailTest, errorBorder_errorMsg });

        if (valid) {
            if (this.validate()) {
                let data = {
                    'Email': this.state.emailTest,
                    'News': this.state.isHTML === false ? {
                        'Id': this.state.entityChild.Id,
                        'Title': this.state.entityChild.Title,
                        'SubTitle': this.state.entityChild.SubTitle,
                        'Text': this.state.entityChild.Text,
                        'Image': (this.state.entityChild.DefaultImage ? this.state.baseImage : (this.state.entityChild.Image ? this.state.entityChild.Image : null)),
                        'HTML': null,
                        'DefaultImage': this.state.entityChild.DefaultImage,
                        'BackgroundSize': this.state.entityChild.BackgroundSize ? 'cover' : 'contain',
                        'URL': this.state.entityChild.URL,
                    } : {
                            'Id': this.state.entityChild.Id,
                            'Title': this.state.entityChild.Title,
                            'SubTitle': null,
                            'Text': null,
                            'Image': null,
                            'HTML': (document.getElementById('fileoutput').textContent ? document.getElementById('fileoutput').textContent : null),
                            'DefaultImage': false,
                        }
                };

                data.News['Base64'] = [];
                data.News['Archivos'] = [];
                this.state.files.forEach(function (file, index) {
                    // 
                    var fr = new FileReader();
                    fr.onload = () => {
                        data.News.Archivos.push(file.name);
                        data.News.Base64.push(fr.result);
                    };
                    fr.readAsDataURL(file);
                });

                let self = this;
                setTimeout(function () {
                    QLIBS.fetchDataPOST(APIS.URLS.SEND_NEWS, data, true)
                        .then((response) => {
                            if (response) {
                                self.setState({ submitOkMessage: "El e-mail se ha enviado correctamente." }, () => {
                                    setTimeout(function () {
                                        self.setState({ submitOkMessage: '' });
                                    }, 5000);
                                });
                            }
                        });
                }, 2000);
            }
        }
    }

    render() {

        const { COMPONENTS_FORM } = GLOBAL;

        return (
            <div className="modal" tabIndex="-1" role="dialog" aria-hidden="false" id="modal-novedades">
                <div className="modal-fullscreen-xl" role="document">
                    <div className="modal-content">
                        <div>
                            <button type="button" className="close positioning" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={this.state.onDismiss}>&times;</span>
                            </button>
                            <h5 className="modal-title text-center">{`ADMINISTRAR AVISO ${this.state.entityChild.Type === TYPES.WEB ? "WEB" : "POR CORREO"}`}</h5>
                        </div>
                        <div className="modal-body p-0 mb-1">
                            <pre id="fileoutput" style={{ display: 'none' }}></pre>
                            <section id="form" style={{ display: this.state.preview === false ? 'block' : 'none' }}>
                                <>
                                    <div className="row mb-2">
                                        {this.state.errorMessage && (<div className="col-12"><Alert type="error" icon="exclamation" title={COMPONENTS_FORM.ALERT_ERROR_TITLE} message={this.state.errorMessage} noDetail={true} /></div>)}
                                        {this.state.submitOkMessage && (<div className="col-12"><Alert title={this.state.submitOkMessage} message={''} /></div>)}
                                    </div>
                                    {this.state.entityChild.Type === TYPES.EMAIL ? (
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <Checkbox id="isHTML" text="¿Subis un HTML?" onChange={(event) => {
                                                            const isCheckbox = event.target.type === "checkbox";
                                                            let inputName = event.target.id ? event.target.id : event.target.name;
                                                            let inputValue = isCheckbox ? event.target.checked : event.target.value;
                                                            this.setState({ [inputName]: inputValue }, () => {
                                                                if (document.getElementById('file')) {
                                                                    document.getElementById('file')
                                                                        .addEventListener('change', function () {
                                                                            var fr = new FileReader();
                                                                            fr.onload = function () {
                                                                                document.getElementById('fileoutput').textContent = fr.result;
                                                                            }
                                                                            fr.readAsText(this.files[0]);
                                                                        });
                                                                }
                                                            })
                                                        }} checked={this.state.isHTML} />
                                                    </div>
                                                    {this.state.isHTML === false && (
                                                        <div className="col-md-3">
                                                            <Checkbox id="DefaultImage" text="Incluir imagen por defecto" onChange={this.handleChange}
                                                                checked={this.state.entityChild.defaultImage} />
                                                        </div>
                                                    )}
                                                </div>
                                                {this.state.isHTML === true ? (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <Input
                                                                    id="Title"
                                                                    type="text"
                                                                    text={"Asunto"}
                                                                    defaultValue={this.state.entityChild.Title}
                                                                    classes="mb-4"
                                                                    maxLength="50"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    errorMsg={this.state.errorMsg_title}
                                                                    borderColor={this.state.errorBorder_title}
                                                                />
                                                            </div>
                                                            <div className="col-8 mt-4">
                                                                <div className="d-flex">
                                                                    <FileUpload />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <Input
                                                                    type="email"
                                                                    id="emailTest"
                                                                    text="E-mail"
                                                                    placeholder="Ingresar un correo electrónico válido"
                                                                    errorMsg={this.state.errorMsg_emailTest}
                                                                    borderColor={this.state.errorBorder_errorMsg}
                                                                    onChange={(event) => {
                                                                        let inputName = event.target.id ? event.target.id : event.target.name;
                                                                        this.setState({ [inputName]: event.target.value });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <Button type="button" id="btnTest" onClick={() => { this.sendTestEmail(); }}>Enviar e-mail de prueba</Button>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                        <>
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Input
                                                                        id="Title"
                                                                        type="text"
                                                                        text={"Asunto"}
                                                                        defaultValue={this.state.entityChild.Title}
                                                                        classes="mb-4"
                                                                        maxLength="50"
                                                                        onChange={(e) => this.handleChange(e)}
                                                                        errorMsg={this.state.errorMsg_title}
                                                                        borderColor={this.state.errorBorder_title}
                                                                    />
                                                                </div>
                                                                <div className="col-4">
                                                                    <Input
                                                                        id="SubTitle"
                                                                        type="text"
                                                                        text={"Titulo"}
                                                                        defaultValue={this.state.entityChild.SubTitle}
                                                                        classes="mb-4"
                                                                        maxLength="100"
                                                                        onChange={(e) => this.handleChange(e)}
                                                                        errorMsg={this.state.errorMsg_subtitle}
                                                                        borderColor={this.state.errorBorder_subtitle}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <div className={this.state.errorMsg_text.length ? 'input input-span' : 'input'}>
                                                                        <label htmlFor={'Text'}>{'Texto'}</label>
                                                                        <div className={`input-group input-group-${this.state.errorBorder_text} rounded`}>
                                                                            <textarea className="form-control" id="Text" onChange={(e) => this.handleChange(e)} maxLength={2000} rows="9" defaultValue={this.state.entityChild.Text}></textarea>
                                                                        </div>
                                                                        {this.state.errorMsg_text.length ? <span className={`input-span-${this.state.errorBorder_text}`}>{this.state.errorMsg_text}</span> : null}
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <ImageUploader
                                                                        name="Image"
                                                                        withIcon={true}
                                                                        withPreview={true}
                                                                        buttonText={COMPONENTS_FORM.CHOOSE_IMAGE}
                                                                        onChange={this.onDrop}
                                                                        imgExtension={['.jpg', '.png']}
                                                                        maxFileSize={5242880}
                                                                        label={"Tamaño máximo de archivo: 5mb, accepted: jpg, png"}
                                                                        fileSizeError={"El tamaño del archivo es demasiado grande"}
                                                                        fileTypeError={"No es compatible con la extensión de archivo"}
                                                                        singleImage={true}
                                                                        defaultImages={this.state.entityChild.Image ? [this.state.entityChild.Image] : []}
                                                                    />
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <Input
                                                                                type="email"
                                                                                id="emailTest"
                                                                                text="E-mail"
                                                                                placeholder="Ingresar un correo electrónico válido"
                                                                                errorMsg={this.state.errorMsg_emailTest}
                                                                                borderColor={this.state.errorBorder_errorMsg}
                                                                                onChange={(event) => {
                                                                                    let inputName = event.target.id ? event.target.id : event.target.name;
                                                                                    this.setState({ [inputName]: event.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <Button type="button" id="btnTest" onClick={() => { this.sendTestEmail(); }}>Enviar e-mail de prueba</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                            <div className="col-md-4">
                                                <label>Agregar archivos adjuntos al e-mail a enviar</label>
                                                <FilePond
                                                    files={this.state.files}
                                                    allowMultiple={true}
                                                    labelIdle='<span className="filepond--label-action">Haga click para buscar o arrastre y suelte un archivo</span>'
                                                    allowImagePreview={false}
                                                    onupdatefiles={(fileItems) => {
                                                        // 
                                                        this.setState({
                                                            files: fileItems.map(fileItem => fileItem.file)
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                            <>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <Input
                                                            id="Title"
                                                            type="text"
                                                            text={"Asunto"}
                                                            defaultValue={this.state.entityChild.Title}
                                                            classes="mb-4"
                                                            maxLength="50"
                                                            onChange={(e) => this.handleChange(e)}
                                                            errorMsg={this.state.errorMsg_title}
                                                            borderColor={this.state.errorBorder_title}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <Input
                                                            id="SubTitle"
                                                            type="text"
                                                            text={"Titulo"}
                                                            defaultValue={this.state.entityChild.SubTitle}
                                                            classes="mb-4"
                                                            maxLength="100"
                                                            onChange={(e) => this.handleChange(e)}
                                                            errorMsg={this.state.errorMsg_subtitle}
                                                            borderColor={this.state.errorBorder_subtitle}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <Checkbox id="BackgroundSize" text="Visualizar imagen de forma horizontal" checked={this.state.entityChild.BackgroundSize} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className={this.state.errorMsg_text.length ? 'input input-span' : 'input'}>
                                                            <label htmlFor={'Text'}>{'Texto'}</label>
                                                            <div className={`input-group input-group-${this.state.errorBorder_text} rounded`}>
                                                                <textarea className="form-control" id="Text" onChange={(e) => this.handleChange(e)} maxLength={2000} rows="9" defaultValue={this.state.entityChild.Text}></textarea>
                                                            </div>
                                                            {this.state.errorMsg_text.length ? <span className={`input-span-${this.state.errorBorder_text}`}>{this.state.errorMsg_text}</span> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <ImageUploader
                                                            name="Image"
                                                            withIcon={true}
                                                            withPreview={true}
                                                            buttonText={COMPONENTS_FORM.CHOOSE_IMAGE}
                                                            onChange={this.onDrop}
                                                            imgExtension={['.jpg', '.png']}
                                                            maxFileSize={5242880}
                                                            label={"Tamaño máximo de archivo: 5mb, accepted: jpg, png"}
                                                            fileSizeError={"El tamaño del archivo es demasiado grande"}
                                                            fileTypeError={"No es compatible con la extensión de archivo"}
                                                            singleImage={true}
                                                            defaultImages={this.state.entityChild.Image ? [this.state.entityChild.Image] : []}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="row mb-2">
                                                            <label>Fecha de inicio</label>
                                                            <DatePicker
                                                                id="CreationDate"
                                                                defaultValue={this.state.entityChild.CreationDate !== null ? this.state.entityChild.CreationDate.toString() : ""}
                                                                maxLength="10"
                                                                noDefaultValue="true"
                                                                onChange={this.handleChange}
                                                                errorMsg={this.state.errorMsg_CreationDate}
                                                            />
                                                            {this.state.errorMsg_CreationDate.length ? <span className={`input-span-${this.state.errorBorder_CreationDate}`}>{this.state.errorMsg_CreationDate}</span> : null}
                                                        </div>
                                                        <div className="row mb-2">
                                                            <label>Fecha de finalización</label>
                                                            <DatePicker
                                                                id="UntilDate"
                                                                defaultValue={this.state.entityChild.UntilDate !== null ? this.state.entityChild.UntilDate.toString() : ""}
                                                                maxLength="10"
                                                                noDefaultValue="true"
                                                                onChange={this.handleChange}
                                                                errorMsg={this.state.errorMsg_UntilDate}
                                                            />
                                                            {this.state.errorMsg_UntilDate.length ? <span className={`input-span-${this.state.errorBorder_UntilDate}`}>{this.state.errorMsg_UntilDate}</span> : null}
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col-6">
                                                                <Checkbox id="showNews" text="Mostrar aviso" checked={this.state.showNews} onChange={() => { this.setState({ showNews: !this.state.showNews }); }} />
                                                            </div>
                                                            <div className="col-6">
                                                                <Combobox id="Order" label="Orden visualización" onChange={(v, e) => { this.handleComboChange(v, e); }} options={this.state.orderOptions} defaultValue={this.state.entityChild.Order} isDisabled={!this.state.showNews} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Input type="text" id="URL" text="Enlace al hacer click (debe comenzar con http:// o https://)" defaultValue={this.state.entityChild.URL} onChange={this.handleChange} errorMsg={this.state.errorMsg_URL} borderColor={this.state.errorBorder_URL} />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </>
                            </section>
                            <section id="preview" style={{ display: (this.state.preview === true ? 'block' : 'none'), height: 'auto' }}>
                                {this.state.entityChild.Type === TYPES.WEB ? (
                                    <div className="col-12 px-0 img-container">
                                        <Carousel
                                            displayImages={this.state.entityChild.Image ? ['data:image/gif;base64,' + this.state.entityChild.Image.split(',')[1]] : [CarouselDImageOne]}
                                            titles={this.state.entityChild.SubTitle ? [this.state.entityChild.SubTitle] : ['']}
                                            subtitles={this.state.entityChild.Text ? [this.state.entityChild.Text] : ['']}
                                            links={this.state.entityChild.URL ? [this.state.entityChild.URL] : ['']}
                                            size="full"
                                            backgroundSize={this.state.entityChild.BackgroundSize ? ['cover'] : ['contain']}
                                        />
                                    </div>
                                ) : (
                                        <div style={{ overflowY: 'scroll', maxHeight: '420px' }} dangerouslySetInnerHTML={{ __html: document.getElementById('fileoutput') && document.getElementById('fileoutput').textContent ? document.getElementById('fileoutput').textContent : '<div></div>' }} />
                                    )}
                            </section>
                        </div>
                        <div className="modal-footer">
                            {((this.state.isHTML || this.state.entityChild.Type === TYPES.WEB) && this.state.preview === false) && (
                                <Button type="button" size="medium-lg" color="secondary" fill="outline" onClick={() => { if (this.validate()) { this.setState({ preview: true }); } }}>Vista preliminar</Button>
                            )}
                            {((this.state.isHTML || this.state.entityChild.Type === TYPES.WEB) && this.state.preview) && (
                                <Button type="button" size="medium-lg" color="secondary" fill="outline" onClick={() => { this.setState({ preview: false }) }}>Volver</Button>
                            )}
                            <Button type="button" size="medium-lg" color="secondary" fill="outline" data-dismiss="modal" onClick={this.state.onDismiss}>{GLOBAL.COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                            <Button type="button" size="medium-lg" onClick={() => { this.save() }}>{GLOBAL.COMMON_MODAL_FORM.SAVE_BUTTON}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NovedadesModal