const FORM_ERRORS = {
  COMMON_ERRORS: {
    INITIAL_MARKER: "> ",
    EMPTY_FIELD: "Por favor completar este campo",
    EMPTY_FIELD_LONG: "Este campo es obligatorio. Por favor completar este campo",
    EMPTY_IMAGE: "Por favor seleccionar una imagen",
    EMPTY_FILE: "Por favor seleccionar un archivo",
    FIELD_MIN_CHARACTERS_2: "El campo debe poseer al menos 2 caracteres",
    FIELD_MIN_CHARACTERS_3: "El campo debe poseer al menos 3 caracteres",
    FIELD_MIN_CHARACTERS_4: "El campo debe poseer al menos 4 caracteres",
    FIELD_MIN_CHARACTERS_5: "El campo debe poseer al menos 5 caracteres",
    FIELD_MIN_CHARACTERS_6: "El campo debe poseer al menos 6 caracteres",
    FIELD_MIN_CHARACTERS_7: "El campo debe poseer al menos 7 caracteres",
    FIELD_MIN_CHARACTERS_8: "El campo debe poseer al menos 8 caracteres",
    FIELD_MIN_CHARACTERS_9: "El campo debe poseer al menos 9 caracteres",
    FIELD_MIN_CHARACTERS_10: "El campo debe poseer al menos 10 caracteres",
    FIELD_MIN_CHARACTERS_11: "El campo debe poseer al menos 11 caracteres",
    FIELD_MIN_CHARACTERS_25: "El campo debe poseer al menos 25 caracteres",
    FIELD_INVALID: "El valor ingresado es inválido",
    DNI_INVALID: "DNI inválido",
    CUIT_INVALID: "El CUIT ingresado es inválido",
    EMAIL_INVALID: "El e-mail ingresado es inválido",
    SELECT_OPTION: "Seleccioná una opción",
    PASSWORD_ISEMPTY: "Ingrese su contraseña actual",
    PASSWORD_INVALID_PATTERN: "La contraseña debe contener al menos una letra mayúscula y un número",
    PASSWORD_DONT_MATCH: "La contraseña y confirmación no coinciden",
    PASSWORD_INVALID: "La contraseña ingresada es incorrecta",
    TERMS_UNCHECKED: "Debes aceptar los Términos y Condiciones",
    TIMEOUT: "No se enviar la información. Reintente nuevamente!",
    REQUEST_DEFAULT: "Por favor intente nuevamente más tarde",
    PASSWORDDONTMATCH: "La contraseña y confirmación no coinciden"
  },
  CUSTOM_ERRORS: {
    ENVIO_FACTURA_ERROR: "Debe ingresar al menos 1 factura, seleccionando Empresa, Rama, Mes de Liquidación, Importe a facturar, y al 1 o más archivos adjuntos.",
    SIMULAR_PRODUCTOR_NOMBRE_ERROR: "El nombre o razón social es requerido",
    NRO_FACTURA_ERROR: "El valor ingresado es incorrecto. Solo se admiten guiones medios, espacios, barras / y números"
  }
};

export {
  FORM_ERRORS
};
