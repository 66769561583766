import React from 'react'
import { bool, string } from 'prop-types';
import InputMask from 'react-input-mask';

const Input = ({ type, id, text, defaultValue, value, placeholder, smallPrint, errorMsg, disabled, classes, maxLength, mask, borderColor, imgSrc, readOnly, onKeyDown, onChange, onBlur, onKeyPress }) => {
  var mainInputClass = (errorMsg.length || smallPrint.length) ? 'input input-span' : 'input';
  if (classes) {
    mainInputClass += " " + classes;
  }

  return (
    <div className={mainInputClass}>
      <label htmlFor={id}>{text}</label>
      <div className={`input-group input-group-${borderColor} rounded`}>
        {
          mask ?
            <InputMask className="form-control" type={type} defaultValue={defaultValue} id={id} name={id} disabled={disabled} mask={mask} maskChar=" " onChange={onChange} onKeyDown={onKeyDown} onBlur={onBlur} />
            :
            <input className="form-control" type={type} defaultValue={defaultValue} id={id} name={id} disabled={disabled} value={value} maxLength={maxLength} placeholder={placeholder} readOnly={readOnly} onChange={onChange} onKeyDown={onKeyDown} onBlur={onBlur} onKeyPress={onKeyPress} />
        }
        {
          imgSrc.length ?
            <span className="input-group-append">
              <div className="input-group-text bg-transparent">
                <img src={imgSrc} alt={borderColor} />
              </div>
            </span>
            :
            null
        }
      </div>
      {errorMsg.length ? <span className={`input-span-${borderColor}`}>{errorMsg}</span> : null}
      {smallPrint.length && !errorMsg.length ? <span>{smallPrint}</span> : null}
    </div>
  )
}

Input.defaultProps = {
  defaultValue: '',
  classes: '',
  errorMsg: '',
  smallPrint: '',
  imgSrc: '',
  borderColor: '',
  type: 'text',
  placeholder: '',
  disabled: false,
  maxLength: '',
  mask: '',
  readOnly: false,
};

Input.propTypes = {
  id: string.isRequired,
  text: string.isRequired,
  type: string,
  defaultValue: string,
  classes: string,
  errorMsg: string,
  smallPrint: string,
  imgSrc: string,
  borderColor: string,
  disabled: bool,
  placeholder: string,
  maxLength: string,
  mask: string,
  readOnly: bool
};


export default Input;