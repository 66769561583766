import React from "react";
import { bool, string } from "prop-types";
import ProfileIcon from "../../Resources/Images/Logos/profile-lg.png";

function ProfileBadge({ image, inHeader, height, width }) {
  let img = image === 'null' || image === null || image === "undefined" ? ProfileIcon : image;
  return (
    <img
      alt="Foto de perfil"
      src={img}
      className={`${ inHeader? 'profile-image' : ''}`}
      height={height}
      width={width}
    />
  );
}

ProfileBadge.propTypes = {
  image: string,
  height: string,
  width: string,
  inHeader: bool,
};

ProfileBadge.defaultProps = {
  image: ProfileIcon,
  height: 35,
  width: 35,
  inHeader: false,
};

export default ProfileBadge;
