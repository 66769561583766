import React from 'react';
import {  Breadcrumb, Button, Input, Loading, Table, TabsPanel, ViewsModal } from '../../Components';
import { APPCONFIG } from '../../app.config';
import { DEFAULT_HEADER } from '../../Config/userToken';
import userAuth from '../../Config/userauth';
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from "../../Config/global";
import { truncate } from '../../Shared/Utils';
import moment from 'moment';
class ComisionesPendientes extends React.Component {
    constructor(props) {
        super(props);
        this.filterTabs = [
            {
                label: 'Póliza'
            },
            {
                label: 'Grupo'
            }
        ];
        this.page = 0;
		this.pageSize = 10;

        this.state = {
            filterTabActive: 3,
            data: [],
            filters: [],
			sort: [],
        }
        this.onChangeTab = this.onChangeTab.bind(this);
        this.baseTableColumns = this.createBaseTableColumns();
        this.handleSaveViewModal = this.handleSaveViewModal.bind(this);
        this.listTableColumns = this.createListTableColumns();
    }

	handleSaveViewModal(newListColumns) {
		const tempTableColumns = this.createBaseTableColumns();

		this.baseTableColumns = newListColumns.map(c => {
			let col = tempTableColumns.find(f => f.name === c.name);
			col.show = c.show;
			return col;
		});

		this.listTableColumns = this.createListTableColumns();
	}
    getFilter() {
        let filters = this.state.filters
        return filters
    }
    getSort() {
        let sort = this.state.sort
        return sort
    }
    onChangeTab = index => {
        this.setState({ name: this.state.name, filterTabActive: index, filtersSelected: (index > 0) });
    };
	applySort = (event) => {
        
		if (event.length > 0) {
			this.setState({
				sort: event,
			}, () => this.handleSubmit());
		}
		else {
			this.setState({
				sort: {},
			}, this.handleSubmit);
		}
	}
    handleChange = event => {
        switch (event.target.id) {
            case "nro_poliza":
				this.poliza = event.target.value
				break;

			default:
				break;
        }
    }
    createBaseTableColumns() {
        const { REPORT_FORMAT } = GLOBAL;
        let newTableColumns = [];
        let columns = {
            "fechaAplicacion": { title: "Fecha aplicación",field:'FechaAplicacion',format: REPORT_FORMAT.FECHA, show: true },
            "producto": { title: "Producto",field:'Producto',format: REPORT_FORMAT.TEXTO, show: true },
            "poliza": { title: "Póliza", field:'Poliza',format: REPORT_FORMAT.TEXTO,show: true },
            "asegurado": { title: "Asegurado",field:'Asegurado',format: REPORT_FORMAT.TEXTO, show: true },
            "grupo": { title: "Grupo",field:'Grupo',format: REPORT_FORMAT.TEXTO, show: true },
            "certificado": { title: "Certificado",field:'Certificado', format: REPORT_FORMAT.TEXTO,show: true },
            "endoso": { title: "Factura/Recibo",field:'Endoso', show: true },
            "jurisdiccion": { title: "Jurisdicción",field:'Jurisdiccion',format: REPORT_FORMAT.TEXTO, show: true },
            "premioCobrado": { title: "Premio cobrado",format: REPORT_FORMAT.MONEDA, field:'PremioCobrado', show: true }, 
            "primaNeta": { title: "Prima neta",field:'PrimaNeta',format: REPORT_FORMAT.MONEDA, show: true },  
            "comisionTotal": { title: "Comisión total", field:'ComisionTotal',format: REPORT_FORMAT.MONEDA,show: true },
            "comisionBruto": { title: "Comisión bruta (IVA incluído)",field:'ComisionBruto',format: REPORT_FORMAT.MONEDA, show: true },    
            "descripcion": { title: "Descripcion",field:'Descripcion',format: REPORT_FORMAT.TEXTO, show: true },
        };
        // Add Columns to Table
        let cols = QLIBS.getTableColumns(columns);
        cols.forEach((c) => { newTableColumns.push(c) });
        return newTableColumns;
    }

    handleSubmit = async (event) => {
        if (event) event.preventDefault();
        const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
        let sort = this.state.sort.length === 0 ? null : this.state.sort[0];
        const requestOptions = {
            method: 'POST',
            headers: DEFAULT_HEADER.get(),
            body: JSON.stringify({
                producerCuit: userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer(),
                next: this.pageSize,
                poliza : this.poliza > 0 ? this.poliza : null,
                offset: this.page * this.pageSize,
                gridFilters: this.state.filters,
                sort: sort,
            })
        };
   
        var url = new URL(`${PRODUCERURL}api/Settlement/ComisionesPendientes`);
        this.setState({ loadingData: true });
        const x = await fetch(url, requestOptions);
        const response = await x.json();
        if (response) {
            this.totalItems = response.total;
            this.pages = Math.ceil(response.total / this.pageSize);
            if (Array.isArray(response.data)){
                const formatData = response.data.map(function (item) {
                    return {
                        primaNeta: `$ ${truncate(item.primaNeta, 2)}`,
                        premioCobrado: `$ ${truncate(item.premioCobrado, 2)}`, 
                        poliza: item.poliza,
                        asegurado : item.asegurado,
                        grupo : item.grupo,
                        certificado : item.certificado,
                        endoso : item.endoso,
                        descripcion : item.descripcion,
                        producto : item.producto,
                        jurisdiccion : item.jurisdiccion,
                        fechaAplicacion : moment(item.fechaAplicacion).format("DD/MM/YYYY"),
                        comisionTotal :  `$ ${truncate(item.comisionTotal, 2)}`,
                        comisionBruto :  `$ ${truncate(item.comisionBruto, 2)}`,
                        aporteExtraordinario : item.aporteExtraordinario ? 'Sí': 'No',
                    }
                });
                this.setState({ data: formatData, loadingData: false });
            };
           
        } else {
            this.setState({ loadingData: false });
        }
        
    }
	onKeyDown = (event) => {
		if (event.key === 'Enter' && this.state.searchEnabled) {
			event.preventDefault();
			event.stopPropagation();
			document.getElementById("btnSubmit").click();
		}
	}
    createListTableColumns() {
        const newTableColumns = [...this.baseTableColumns.filter(f => f.show)];
        return newTableColumns;
    }

    async componentDidMount() {
        this.handleSubmit()

    }
    createExcelReportColumns() {
		const excelReportColumns = this.baseTableColumns.filter(f => f.show).map(c => {
			return {
				Title: c.title,
				Field: c.field,
				Summarize: c.summarize,
				Format: c.format
			}
		});
		return excelReportColumns;
	}
    applyFilter = (event) => {
        
		if (event.length > 0) {
			this.page = 0
			this.setState({
				filters: event,
			});
		}
		else {
			this.setState({
				filters: [],
			});
		}
	}
    changeSize = async (size) => {
		this.pageSize = size;
		this.handleSubmit()
	}
	changePage = async (page) => {
		this.page = page;
		this.handleSubmit()
	}

    handleExportSubmit(event) {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		event.preventDefault();
		this.setState({ loadingData: true });

		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({
				producerCuit: userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer(),
				filters: this.state.searchCriteriaText?.replace(/['"]+/g, "\\'"),
				columns: JSON.stringify(this.createExcelReportColumns())
			})
		};

		fetch(`${PRODUCERURL}api/Settlement/ComisionesPendientes/Export`, requestOptions)
			.then(function (resp) {
				if (resp.ok)
					return resp.blob();
			})
			.then(function (blob) {
				if (blob) {
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = "ComisionesPendientes.xlsx";
					link.click();
				}
			})
			.then(() => {
				this.setState({ loadingData: false });
			})
	};
    clearFiltros = () => {
        this.page = 0;
        this.setState({filters: [],sort: []},
            () => {
                this.handleSubmit()
        })
       
    }
    render(...props) {
        const { TABLE_VIEW } = GLOBAL;
		const { COMMON_SEARCH_FORM } = GLOBAL;
        return (
            <>{this.state.showViewsModal && <ViewsModal
					listColumns={this.baseTableColumns}
					onDismiss={() => { this.setState({ showViewsModal: false }) }}
					onSave={(listColumns) => { this.handleSaveViewModal(listColumns) }}
				/>}
                <Loading visible={this.state.loadingData}></Loading>
                <section className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb currentPage="/comisiones-pendientes" />
                        </div>

                        <div className="form-container container table-view col-md-12 mt-2">
                            <form id="comisiones_search_form_1" onSubmit={this.handleSubmit} onKeyDown={this.onKeyDown} className="register__Form" noValidate>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mt-3">
										<div className="col-12">
											<div className="search-buttons">
												<Button type="submit" id="btnSubmit" classes="to-bottom button-small search-button"><span className="fa fa-search"> </span> {COMMON_SEARCH_FORM.SEARCH_BUTTON}</Button>
											</div>
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" onClick={this.clearFiltros}><span className="fa fa-filter"> </span> {COMMON_SEARCH_FORM.CLEAR_FILTERS}</Button>
											</div>
											{(this.state.data.length > 0) && (
												<div className="search-buttons">
													<Button type="submit" onClick={event => this.handleExportSubmit(event)} size="small">
														<i className="fas fa-file-download mx-2" /> {TABLE_VIEW.EXPORT_TEXT}</Button>
												</div>
											)}
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showViewsModal: true }) }}><span className="fa fa-table"> </span> {COMMON_SEARCH_FORM.VIEW_BUTTON}</Button>
											</div>

										</div>
									</div>
                                    </div>

                                </div>
                                <div className="row my-3">
                                    <div className="col-12 px-3 table-comisiones">
                                        <Table
                                            columns={this.listTableColumns}
                                            sorted={this.getSort()}
                                            onSortedChange={this.applySort}
                                            filter={this.getFilter()}
                                            data={this.state.data}
                                            manual={true}
                                            onFilterChange={this.applyFilter}
                                            page={this.page} 
                                            pageSize={this.pageSize}
                                            onPageSizeChange={this.changeSize}
                                            pages={this.pages}
                                            expandable={false}
                                            changePage={this.changePage}
                                            {...props} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </>
        )
    };
}
export default ComisionesPendientes;