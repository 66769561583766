import React from 'react';
import { bool, string, func } from "prop-types";
import { FileUploadMultiple, Button, Loading } from '..';
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { APPCONFIG } from '../../app.config';


class UploadFileRepository extends React.Component {
 
  constructor(props) {
    super(props);

    this.state = {
      loadingForm: false,
      error: null,
      showModal: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);

    this.refFileUploadMultiple = React.createRef();
  }

  componentDidMount() {

  }

  handleShowBody() {
    this.setState({showModal: true });
  }

  handleHideBody() {
    this.setState({showModal: false });
  }



  handleSubmit() {

    const files = this.refFileUploadMultiple.current.getFiles();
    const listaFileName = files.map(f => { return f.name; });
    const listaFile = files.map(f => { return f.file; });

    this.setState({ loadingForm: true, error: null }, () => {

      QLIBS.fetchDataPOST(APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/FileRepository/Upload', {
          'Entity': this.props.entity,
          'EntityId': this.props.entityId,
          'FileName': listaFileName,
          'File': listaFile,
          'User': userAuth.userId()
      }, true)
      .then(this.handleResponse);

    });

  }

  handleResponse = response => {
     if (response.success) {
         this.setState({ loadingForm: false, error: null, showModal: false }, () => {
            if (this.props.callbackDone !== null) {
              this.props.callbackDone();
            }
         });
     }
     else {
         this.setState({ loadingForm: false, error: response.businessExceptions.map(x => x.message).join(". ") });
     }
 }

  render() {

    return (
      <>
        <Loading visible={this.state.loadingForm}></Loading>
        {this.state.showModal && (
        <div className="modal" tabIndex="-1" role="dialog"  aria-modal="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" aria-hidden="true" onClick={() => this.handleHideBody()}>x</button>
                      <h4 className="modal-title text-center">Subir archivos</h4>
                    </div>
                    <div className="modal-body">

                      <div className='col-md-12 text-center'>
                        <FileUploadMultiple ref={this.refFileUploadMultiple} multiple={this.props.multiple} />
                      </div>
                      
                    </div>
                    <div className="modal-footer">
                      <div className="col-12">
                        <Button type="submit" size="medium-lg" onClick={this.handleSubmit}>Aceptar</Button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        )}
        
        <Button type="button" size="medium-lg" fill="outline" onClick={() => { this.handleShowBody() }}>Subir archivos</Button>  

      </>
    );

  }

}

UploadFileRepository.propTypes = {
  entity: string,
  entityId: string,
  multiple: bool,
  callbackDone: func
}

UploadFileRepository.defaultProps = {
  entity: '',
  entityId: '',
  multiple: true,
  callbackDone: null
}

export default UploadFileRepository;
