import React from 'react';
import userAuth from "../../Config/userauth";
import { DEFAULT_HEADER } from '../../Config/userToken';
import { APPCONFIG } from '../../app.config';
class HomeUserInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userInfo: null,
      userCodes: null
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleResponseInfo = this.handleResponseInfo.bind(this);
    this.handleResponseCodes = this.handleResponseCodes.bind(this);
  }

  componentDidMount() {
    const userId = userAuth.userId();
    this.loadUserInfo(userId);
    this.loadUserCodes(userId);
  }

  loadUserInfo(userId) {
    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
    const requestOptions = {
      method: 'GET',
      headers:  DEFAULT_HEADER.get()
    };

    this.setState({userInfo: null})
    
    return fetch(PRODUCERURL + "api/producer/GetProducerById?userId=" + userId, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then(this.handleResponseInfo);
  }

  loadUserCodes(userId) {
    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		const requestOptions = {
			method: 'GET',
			//headers: { 'Content-Type': 'application/json' }
			headers: DEFAULT_HEADER.get()
		};
    this.setState({userCodes: null})
    return fetch(PRODUCERURL + "api/producer/GetProducerCodes?userId=" + userId, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then(this.handleResponseCodes);
  }


  handleResponseInfo(response) {
    if (response && response.success && response.user) {
      this.setState({ userInfo: response });
    }
  }

  handleResponseCodes(response) {
    if (response && response.length > 0) {
      const vt = response.find(f => f.Core === "VT");
      const vtCode = (vt && vt.ProducerCodes.length > 0) ? vt.ProducerCodes[0].Code : "-";

      const rvt = response.find(f => f.Core === "RVT");
      const rvtCode = (rvt && rvt.ProducerCodes.length > 0) ? rvt.ProducerCodes[0].Code : "-";

      const codes = {
        VT: vtCode,
        RVT: rvtCode
      };

      this.setState({ userCodes: codes });
    }
  }

  handleClick = event => {
    window.open('./user-dashboard-info', '_self');
  }


  render() {

    return (
      <div className="user-info-container">
        <div className="user-info-form">
          <div className="user-info-form-header">
            <div className="col-3"></div>
            <div className="col-6 pt-1">
              <span>Mis datos</span>
            </div>
            <div className="col-3 pt-1 pr-2">
              <button className="pull-right"  onClick={this.handleClick}><i className="fa fa-edit" title="Editar datos"></i></button>
            </div>
          </div>
          {this.state && this.state.userInfo &&
            <div>
              <div className="row pb-3 pt-3">
                <div className="col-2 campo-titulo"><i className="fa fa-user"></i></div>
                <div className="col-10 campo-valor">{this.state.userInfo.name + " "+ this.state.userInfo.lastName}</div>
              </div>
              <div className="row pb-3">
                <div className="col-2 campo-titulo"><i className="fa fa-phone"></i></div>
                <div className="col-10 campo-valor">{this.state.userInfo.telephone}</div>
              </div>
              <div className="row pb-3">
                <div className="col-2 campo-titulo"><i className="fa fa-envelope"></i></div>
                <div className="col-10 campo-valor">{this.state.userInfo.email}</div>
              </div>
            </div>
            }
        </div>
      </div>
    );

  }

};

export default HomeUserInfo;
