export function checkEmail(strEmail) {
	var strRegExp = /^([a-zA-Z0-9_\.\-]){2,}\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]){2,}$/;
	return strRegExp.test(strEmail);
}

export function checkPassword(strPassword) {
	var strRegExp = /[A-Za-z0-9]*[0-9]+[a-z0-9]*[A-Z]+[a-z0-9A-Z]*|[A-Za-z0-9]*[A-Z]+[a-z0-9]*[0-9]+[a-z0-9A-Z]*/;
	return strRegExp.test(strPassword);
}

export function trim(strText) {
	for (let i = 0; i < strText.length; i++) {
		if (strText.charAt(i) == " ")
			strText = strText.substring(i + 1, strText.length);
		else
			break;
	}

	for (let i = strText.length - 1; i >= 0; i = strText.length - 1) {
		if (strText.charAt(i) == " ")
			strText = strText.substring(0, i);
		else
			break;
	}
	return strText;
}

/* Devuelve si una cadena "dd/mm/yyyy" o "dd-mm-yyyy" o "dd.mm.yyyy" es una fecha v&aacute;lida */
export function validateFecha(strValue) {
	// check to see if its in a correct format
	var objRegExp = /^[0-9]{1,2}(-|\/|.)[0-9]{1,2}(-|\/|.)[0-9]{2,4}$/;

	if (!objRegExp.test(strValue)) {
		return false; //doesn't match pattern, bad date
	} else {
		var strSeparator = strValue.substring(2, 3);

		//create a lookup for months not equal to Feb.
		var arrayDate = strValue.split(strSeparator);
		var arrayLookup = {
			'01': 31,
			'03': 31,
			'04': 30,
			'05': 31,
			'06': 30,
			'07': 31,
			'08': 31,
			'09': 30,
			'10': 31,
			'11': 30,
			'12': 31
		}

		var intDay = parseInt(arrayDate[0], 10);
		var intMonth = parseInt(arrayDate[1], 10);
		var intYear = parseInt(arrayDate[2], 10);

		// check if month value and day value agree
		if (arrayLookup[arrayDate[1]] != null) {
			if (intDay <= arrayLookup[arrayDate[1]] && intDay != 0 && intYear > 1920 && intYear < 2020)
				return true;     //found in lookup table, good date
		}

		//check for February (bugfix 20050322)
		//bugfix for parseInt kevin
		//bugfix biss year  O.Jp Voutat
		if (intMonth == 2) {
			var intYear = parseInt(arrayDate[2]);
			if (intDay > 0 && intDay < 29) {
				return true;
			} else if (intDay == 29) {
				if ((intYear % 4 == 0) && (intYear % 100 != 0) || (intYear % 400 == 0)) {
					// year div by 4 and ((not div by 100) or div by 400) ->ok
					return true;
				}
			}
		}
	}

	return false;
}

export function ValidarCuit(cuit) {
	cuit = trim(cuit);

	if (cuit.length != 11) {
		return false;
	}

	var acumulado = 0;
	var digitos = cuit.split("");
	var digito = digitos.pop();

	for (var i = 0; i < digitos.length; i++) {
		acumulado += digitos[9 - i] * (2 + (i % 6));
	}

	var verif = 11 - (acumulado % 11);
	if (verif == 11) {
		verif = 0;
	} else if (verif == 10) {
		verif = 9;
	}

	return digito == verif;
}

export function FormatCuit(value) {
	var cuit = value.match(/(\d{2})(\d{8})(\d{1})/);
	return cuit[1] + "-" + cuit[2] + "-" + cuit[3];
}

export function validarEmail(email) {
	// var objRegExp1 = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@origenes.com.ar$/;
	// var objRegExp2 = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@lifeseguros.com.ar$/;
	let objRegExp1 = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
	return email.length > 0 ? objRegExp1.test(email) : false;
}

export function validarNroFactura(factura) {
	var objRegExp = /^[\d\s\-/\s]+$/;
	return factura.length > 0 ? objRegExp.test(factura) : false;
}