import React, { useState, useEffect } from "react";
import { bool, string, number, func } from "prop-types";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "moment/locale/es";
import "react-dates/lib/css/_datepicker.css";
import { renderSelectDate } from "../../Shared/Utils";
import { GLOBAL } from '../../Config/global';

const { RANGE_DATEPICKER } = GLOBAL.RANGE_DATEPICKER;

const RangeDatePicker = ({
  disabled,
  displayFormat,
  initialStartDate,
  initialEndDate,
  firstDayOfWeek,
  numberOfMonths,
  inputIconPosition,
  onChange,
  isOutsideRange
}) => {
  moment.locale("es");
  const [focusedInput, setFocus] = useState(false);
  const [dates, setDates] = useState({
    endDate: initialEndDate ? moment(initialEndDate, displayFormat) : null,
    startDate: initialStartDate ? moment(initialStartDate, displayFormat) : null
  });

  const handleDatesChange = date => {
    setDates({
      ...dates,
      startDate: date.startDate,
      endDate: date.endDate
    });
  };

  const handleFocusChange = focused => {
    setFocus(!focused ? "startDate" : focused);
  };

  const handleOnClose = () => {
    setFocus(false);
  };

  const PrevArrow = () => {
    return (
      <div className="left-arrow-nav">
        <i className="fas fa-chevron-left"/>
      </div>
    );
  };

  const NextArrow = () => {
    return (
      <div className="right-arrow-nav">
        <i className="fas fa-chevron-right"/>
      </div>
    );
  };

  // Emit selected startDate / endDate formatted as strings instead of Moment objects directly (more user friendly)
  useEffect(() => {
    onChange(
      Object.keys(dates).reduce(
        (accum, key) => ({
          ...accum,
          [key]: dates[key] ? dates[key].format(displayFormat) : dates[key]
        }),
        {}
      )
    );
  }, [dates, displayFormat, onChange]);

  return (
    <DateRangePicker
      disabled={disabled}
      customInputIcon={<i className="far fa-calendar-minus green-icon"/>}
      renderMonthElement={renderSelectDate}
      onDatesChange={handleDatesChange}
      onFocusChange={handleFocusChange}
      firstDayOfWeek={firstDayOfWeek}
      numberOfMonths={numberOfMonths}
      displayFormat={displayFormat}
      startDate={dates.startDate}
      startDatePlaceholderText={ RANGE_DATEPICKER.START_DATE_PLACEHOLDER }
      endDatePlaceholderText={ RANGE_DATEPICKER.END_DATE_PLACEHOLDER }
      startDateId="startDate"
      endDate={dates.endDate}
      endDateId="endDate"
      onClose={handleOnClose}
      navPrev={<PrevArrow />}
      navNext={<NextArrow />}
      isOutsideRange={isOutsideRange}
      focusedInput={focusedInput}
      inputIconPosition={inputIconPosition}
      showDefaultInputIcon
      enableOutsideDays
      hideKeyboardShortcutsPanel
    />
  );
};

RangeDatePicker.propTypes = {
  disabled: bool,
  displayFormat: string,
  initialStartDate: string,
  initialEndDate: string,
  firstDayOfWeek: number,
  numberOfMonths: number,
  inputIconPosition: string,
  onChange: func,
  isOutsideRange: func
};

RangeDatePicker.defaultProps = {
  onChange: () => null,
  isOutsideRange: () => false,
  disabled: false,
  initialEndDate: null,
  initialStartDate: null,
  displayFormat: "DD/MM/YYYY",
  inputIconPosition: "after",
  firstDayOfWeek: 0,
  numberOfMonths: 2
};

export default RangeDatePicker;
