import { DEFAULT_HEADER } from '../../Config/userToken';
import React, { Component } from 'react'
import { Table as TableComponent, Loading, Alert, Breadcrumb, Actions } from '../../Components';
import { GLOBAL } from '../../Config/global';
import * as QLIBS from '../../qLibsHelpers';
import { APPCONFIG } from '../../app.config';
import { APIS } from '../../Config/apis';

export class Repositorio extends Component {

	createBaseTableColumns() {

		let newTableColumns = [];
		let columns = {
			"categoria": { title: "Categoría", field: "categoria", show: true, width: 180 },
			"productor": { title: "Subido por", field: "productor", show: true, width: 250 },
			"fechaCarga": { title: "Fecha de carga", field: "fechaCarga", show: true, width: 200 },
			"inputName": { title: "Archivo ingresado", field: "inputName", show: true },
			"outputName": { title: "Archivo procesado", field: "outputName", show: true },
		};
		// Add Columns to Table
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });
		return newTableColumns;
	}

	createListTableColumns() {
		const newTableColumns = [this.detailsColumn, ...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}

	constructor(props) {
		super(props)

		this.state = {
			tableData: [],
			loadingForm: false,
		}
		this.detailsColumn = {
			Header: "",
			accessor: "actions",
			width: 100,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<>
					<Actions
						onDownload={() => this.download(row)}
					/>
					<Actions
						onDelete={() => this.removeFile(row)}
					/>
				</>
				
			),
			
		};
		this.baseTableColumns = this.createBaseTableColumns();
		this.listTableColumns = this.createListTableColumns();
	}
	removeFile(row){
		const id = row.original.id;
		const URL = APIS.URLS.DELETE_FILE;
		const requestOptions = {
			method: 'DELETE',
			headers: DEFAULT_HEADER.get()
		};
		this.setState({ loadingData: true });
		fetch(`${URL}?Entity=${id}&FileEntity=NominaFile`, requestOptions)
			.then(response => {
				this.removeGridItem(id)
				this.setState({ loadingData: false });
			})
			.catch((error) => {
				this.setState({ loadingData: false });
			});
	}

	download(row) {

		const id = row.original.id;
		const URL = APIS.URLS.DOWNLOAD_FILE;
		const requestOptions = {
			method: 'GET',
			responseType: 'arraybuffer',
			headers: { 'Content-Type': 'blob' },
		};
		
		this.setState({ loadingData: true });
		fetch(`${URL}?Entity=${id}&FileEntity=NominaFile`, requestOptions)
			.then(response => {
				
				if (response.ok) {
					let fileName = 'archivos.zip';
					this.handleResponseOk(response, fileName);
				}
				else
				this.setState({ loadingData: false });
			})
			.catch((error) => {
				this.setState({ loadingData: false });
			});
	}

	removeGridItem= (id) => {
		this.state.tableData.pop(x=>x.id === id);
	}

	handleResponseOk = (response, fileName) => {
		let self = this;
		response.blob()
			.then(function (blob) {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = fileName;
				link.click();
			})
			.then(() => {
				self.setState({ certificadoGenerado: true, loadingData: false, item: self.itemInit });
			});
	}
	getData() {
		this.setState({ name: this.state.name, filterTabActive: -1, loadingData: true });
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};

		fetch(`${PRODUCERURL}api/FileRepository/repositorio/`, requestOptions)
			.then((response) => {
				return QLIBS.authorizeAction(response);
			})
			.then(response => {
				if (Array.isArray(response)) {
					let data = response.map(function (item) {
						return {
							...item,
							inputName: item.inputName
						}
					});
					this.setState({ tableData: data })
				}
				else {
					// show error
				}
			})
			.then(this.setState({ loadingData: false, showGrid: true }))

	}

	async componentDidMount() {
		this.getData();
	}

	render() {
		const { PAGES } = GLOBAL;
		return (
			<>

				<Loading visible={this.state.loadingData}></Loading>

				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/repositorio" />
						</div>
						<div className="col-12">
							<h4 className="form-title">{PAGES.TITLE.REPOSITORIO}</h4>
						</div>
						<div className="form-container container table-view col-md-12 mt-2">

							{this.searchResultsError && (
								<div id="form-error-message" className="row my-2">
									<div className="col-12">
										<Alert
											icon="times"
											message=""
											title={this.searchResultsError}
											errorInfo={this.searchResultsError}
											type="error"
										/>
									</div>
								</div>
							)}

							<div className="row my-3">
								<div className="col-12 px-3">
									<div className="col-12 mb-2">
										{<TableComponent
											columns={this.listTableColumns}
											data={this.state.tableData}
										/>}
									</div>
								</div>
							</div>

						</div>
					</div>
				</section>
			</>
		);
	}
}

export default Repositorio