import React from 'react';
import { func, oneOf, string } from 'prop-types';
import Button from '../Button';
import { GLOBAL } from '../../Config/global';
import Input from '../Input';
import { FORM_DATA } from "../../Config/form_data";
import Select from '../Select';

const { MODAL } = GLOBAL;
const NewUserModal = ({ icon, status, onConfirm, onDismiss, title, producer, user }) => {


    function setData(event) {
        let inputName = event.target.name ? event.target.name : event.target.id;
        let inputValue = event.target.value;
        switch (inputName) {
            case "nombre":
                user.nombre = inputValue;
                break;
            case "apellido":
                user.apellido = inputValue;
                break;
            case "email":
                user.email = inputValue;
                break;
            case "cuit":
                user.cuit = inputValue;
                break;
            case "telephone":
                user.telephone = inputValue;
                break;
            case "compania":
                user.compania = event.value;
                break;
            default:
                break;
        }
    }
    return (
        <div className="modal modalNewUser" tabIndex="-1" role="dialog" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div>
                        <button type="button" className="close positioning" data-dismiss="modalNewUser" aria-label="Close">
                            <span aria-hidden="true" onClick={onDismiss}>&times;</span>
                        </button>
                        {status === 'warning' && <i className={`modal-icon ${icon}`}></i>}
                        <h5 className="modal-title text-center">{title}</h5>
                    </div>
                    <div className="modal-body p-4 mb-4">
                        <div className='row'>
                            <div className='col'>
                                <Input
                                    id="nombre"
                                    type="text"
                                    text='Nombre'
                                    onChange={(data) => { setData(data) }}
                                    maxLength="100"
                                />
                            </div>
                            <div className='col'>
                                <Input
                                    id="apellido"
                                    type="text"
                                    text='Apellido'
                                    maxLength="100"
                                    onChange={(data) => { setData(data) }}
                                />
                            </div>
                        </div>
                        {producer === 'true' &&
                        <div className='row'>
                            <div className='col-6'>
                                <Input
                                    id="cuit"
                                    type="text"
                                    text='CUIT/CUIL'
                                    maxLength='13'
                                    onChange={(data) => { setData(data) }}
                                />
                            </div>
                        </div>
                        }
                        <div className='row'>
                            <div className='col'>
                                <Input
                                    id="email"
                                    isRequired='true'
                                    type="email"
                                    text='Correo electrónico'
                                    maxLength="100"
                                    onChange={(data) => { setData(data) }}
                                />
                            </div>
                        </div>
                        {producer === 'true' &&
                        <div className='row'>
                            <div className='col-6'>
                                <Input
                                    id="telephone"
                                    isRequired='true'
                                    type="phone"
                                    text='Teléfono'
                                    maxLength="100"
                                    onChange={(data) => { setData(data) }}
                                />
                            </div>
                            <div className="col-6">
                                <Select
                                    id="compania"
                                    text={'Compañía'}
                                    defaultValue={FORM_DATA.SELECT_OPTION}
                                    options={FORM_DATA.PHONE_COMPANIES}
                                    onChange={(data) => { setData(data) }}
                                />
                            </div>
                        </div>
}
                    </div>
                    <div className="modal-actions m-0">
                        <Button customStyles={{ minWidth: '120px' }} type="button" color="secondary" fill="outline" size="smaller-outline" data-dismiss="modalNewUser" onClick={onDismiss}>Cancelar</Button>
                        <Button customStyles={{ minWidth: '120px', border: 'none' }} type="button" size="smaller" color={status} onClick={() => { onConfirm(user) }} >Guardar</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

NewUserModal.propTypes = {
    onConfirm: func.isRequired,
    onDismiss: func.isRequired,
    title: string,
    status: oneOf(['primary', 'warning', 'error', 'success']),
    icon: string
};

NewUserModal.defaultProps = {
    status: 'success',
    title: MODAL.TITLE,
    icon: 'fas fa-exclamation-circle'
};

export default NewUserModal;