import React, { Component } from 'react'
import { Table as TableComponent, Loading, CRUDButtons, Alert, Breadcrumb, Actions, TabsPanel, ConfirmationModal, Checkbox } from '../../Components';
import { NovedadesModal } from './modal';
import { SendEmailNews } from './send-email';
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from '../../Config/global';
import moment from "moment";

const TYPES = {
    WEB: 0,
    EMAIL: 1
}

export class Novedades extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingForm: false,
            clickOnAddNewRecord: false,
            submitOk: false,
            submitError: false,
            formError: '',
            formSubmitOk: '',
            entityChild: null,
            dataList: [],
            sendEmail: false,
            news: null,
            filterTabActive: TYPES.WEB,
            type: null,
            confirmation: false,
            defaultImageHome: true,
            amount_images_home: 0
        }

        this.filterTabs = [
            {
                label: 'Mostrar en Web',
            },
            {
                label: 'Enviar E-mail'
            },
        ];

        this.handleResponse = this.handleResponse.bind(this);
    }

    async componentDidMount() {
        this.fillGrid(this.state.filterTabActive);

        await QLIBS.fetchDataGET(APIS.URLS.NEWS_QUERY + '?Vigentes=true', true).then(response => {
            this.setState({ amount_images_home: response.length });
        });

        let response = await QLIBS.getParameters(GLOBAL.PARAMETERS.SHOW_DEFAULT_IMAGE_HOME);
        if (response) {
            let value = JSON.parse(response.value);
            this.setState({ defaultImageHome: this.state.amount_images_home === 0 && !value ? true : value }, () => { console.log(response); document.getElementById('defaultImageHome').checked = this.state.defaultImageHome; });
        }
    }

    fillGrid = (tab) => {
        //this.setState({ loadingForm: true, dataList: [] }, () => { QLIBS.fetchDataGET(APIS.URLS.NEWS_QUERY + '?UserId=' + userAuth.simulatorUserId() + '&Type=' + tab, true).then(this.handleResponse); });
        this.setState({ loadingForm: true, dataList: [] }, () => { QLIBS.fetchDataGET(APIS.URLS.NEWS_QUERY + '?Type=' + tab, true).then(this.handleResponse); });
    }

    handleResponse = response => {
        let tableData = [];
        if (Array.isArray(response)) {
            tableData = response.map(function (item) {
                let entityCopy = Object.assign({}, item);
                entityCopy.Order = item.Type === TYPES.WEB ? (item.Order === 0 ? "-" : item.Order.toString()) : null;
                entityCopy.Title = item.Title ? item.Title : '-';
                entityCopy.SubTitle = item.SubTitle ? item.SubTitle : '-';
                entityCopy.Text = item.Text ? item.Text : '-';
                entityCopy.URL = item.URL ? item.URL : '-';
                entityCopy.UntilDate = (item.UntilDate === null ? null : moment(item.UntilDate).format("DD/MM/YYYY"));
                entityCopy.CreationDate = (item.CreationDate === null ? null : moment(item.CreationDate).format("DD/MM/YYYY"));
                return entityCopy;
            });
        }
        this.setState({ loadingForm: false, dataList: tableData }, () => {
            QLIBS.fetchDataGET(APIS.URLS.NEWS_QUERY + '?Vigentes=true', true).then(response => {
                this.setState({ amount_images_home: response.length });
            });
        });
    }

    addNewRecord = type => {
        this.setState({ clickOnAddNewRecord: true, submitOk: false, submitError: false, entityChild: null, type: type });
    }

    toggleModal = (response = false) => {
        if (typeof response !== 'boolean') {
            this.setState({ clickOnAddNewRecord: false, confirmation: false }, () => { this.fillGrid(this.state.filterTabActive); })
            return false;
        }

        this.setState({
            clickOnAddNewRecord: false,
            submitOk: response,
            submitError: !response,
            formSubmitOk: response ? "El registro ha sido procesado correctamente." : '',
            formError: !response ? "Ha ocurrido un error. Vuelva intentarlo." : '',
            confirmation: false,
        }, () => { this.fillGrid(this.state.filterTabActive); });
    }

    editRecord = row => {
        if (row.original.Order === "-") {
            row.original.Order = 0;
        }
        this.setState({ clickOnAddNewRecord: true, submitOk: false, submitError: false, entityChild: row.original });
    }

    deleteRecord = row => {
        this.setState({ confirmation: true, submitOk: false, submitError: false, entityChild: row.original });
    }

    delete = () => {
        QLIBS.fetchDataPOST(APIS.URLS.NEWS_DELETE, this.state.entityChild.Id, true).then((response) => {
            this.setState({
                confirmation: false,
                submitOk: response,
                submitError: !response,
                formSubmitOk: response ? "El registro ha sido eliminado correctamente." : '',
                formError: !response ? "Ha ocurrido un error. Vuelva intentarlo." : '',
            }, () => { this.fillGrid(this.state.filterTabActive); })
        });
    }

    sendEmail = row => {
        this.setState({ sendEmail: true, news: row.original });
    }

    onChangeTab = index => {
        this.setState({ filterTabActive: index }, () => { this.fillGrid(index) });
    };

    render(...props) {

        const { COMPONENTS_FORM, COMMON_CRUD_FORM } = GLOBAL;

        let orderMax = 0;
        this.state.dataList.forEach(x => orderMax = (x.Type === TYPES.WEB && x.Order !== "-" && x.Order !== null && x.Order > orderMax) ? Number(x.Order) : orderMax);

        return (
            <section id="form__marketing">
                <Loading visible={this.state.loadingForm}></Loading>
                {this.state.clickOnAddNewRecord && <NovedadesModal onDismiss={this.toggleModal} entityChild={this.state.entityChild} type={this.state.type} orderMax={orderMax} />}
                {this.state.sendEmail && <SendEmailNews onDismiss={() => { this.setState({ sendEmail: !this.state.sendEmail }) }} news={this.state.news} />}
                {this.state.confirmation && <ConfirmationModal title="Advertencia" message="¿Está seguro que desea eliminar el registro?" onDismiss={() => { this.setState({ confirmation: false }) }} onConfirm={() => { this.delete(); }} />}

                <section className="col-12">
                    <div className='row'>
                        <div className='col-12'>
                            <Breadcrumb currentPage="/news" />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12 mb-1">
                            {(this.state.submitError) && <Alert type="error" icon="exclamation" title={COMPONENTS_FORM.FORM_ERROR} message={this.state.formError} noDetail="" />}
                            {(this.state.submitOk) && <Alert title={this.state.formSubmitOk} message={''} noDetail="" />}
                        </div>
                        <div className="col-12">
                            <TabsPanel options={this.filterTabs} tabActive={this.state.filterTabActive} onChange={this.onChangeTab}>
                                <div className="container option-1" id="tab1">
                                    <br />
                                    <div className="row">
                                        <div className="col-12">
                                            {this.state.amount_images_home === 0 && this.state.defaultImageHome === false && (<Alert type="warning" icon="exclamation" title="Advertencia!" message="Debe haber al menos una imagen de inicio" noDetail="" />)}
                                        </div>
                                        <div className="col-12">
                                            <Checkbox id="defaultImageHome" text="Mostrar imagen (default) en inicio" checked={this.state.defaultImageHome}
                                                disabled={this.state.amount_images_home === 0} smallPrint={this.state.amount_images_home === 0 ? 'Debe haber al menos una portada en inicio. No es posible editar este check' : ''}
                                                onChange={(event) => {
                                                    const isCheckbox = event.target.type === "checkbox";
                                                    let inputName = event.target.id ? event.target.id : event.target.name;
                                                    let inputValue = isCheckbox ? event.target.checked : event.target.value;
                                                    this.setState({ [inputName]: inputValue }, () => {

                                                        QLIBS.saveParameters(GLOBAL.PARAMETERS.SHOW_DEFAULT_IMAGE_HOME, this.state.defaultImageHome);
                                                    });
                                                }} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-10'>

                                        </div>
                                        <div className="col-2" style={{ marginBottom: '24px', marginTop: '42px' }}>
                                            <CRUDButtons text={COMMON_CRUD_FORM.NEW_BUTTON} icon={COMMON_CRUD_FORM.NEW_ICON_BUTTON} onClick={() => { this.addNewRecord(TYPES.WEB) }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <TableComponent columns={[
                                                { Header: "Acciones", accessor: "actions", width: 90, style: { display: 'flex', alignItems: 'center' }, filterable: false, sortable: false, Cell: row => (<Actions onEdit={() => this.editRecord(row)} onDelete={() => this.deleteRecord(row)} />) },
                                                { Header: "Orden", accessor: "Order", resizable: true, width: 95 },
                                                { Header: "Asunto", accessor: "Title", resizable: true, width: 150 },
                                                { Header: "Título", accessor: "SubTitle", resizable: true, width: 290 },
                                                { Header: "Texto", accessor: "Text", resizable: true },
                                                { Header: "Link", accessor: "URL", resizable: true },
                                                { Header: "Fecha de inicio", accessor: "CreationDate", width: 155, resizable: true, Cell: row => (<p className={`custom-row ${row.original.Pendiente ? "custom-row-underline" : ""}`}>{row.original.Pendiente ? "(" + row.original.CreationDate + ")" : row.original.CreationDate}</p>) },
                                                { Header: "Fecha de finalización", accessor: "UntilDate", width: 200 },
                                                { Header: "Activo", accessor: "Activo", resizable: true, width: 95, Cell: row => (<p className={`custom-row ${row.original.Pendiente ? "custom-row-underline" : ""}`}>{row.original.Pendiente ? "(" + row.original.Activo + ")" : row.original.Activo}</p>) },
                                                { Header: "¿Tiene imagen?", accessor: "TieneImagen", resizable: true, width: 160 },
                                            ]} data={this.state.dataList} keyField={"Id"} {...props} />
                                        </div>
                                    </div>
                                </div>
                                <div className="container option-2" id="tab2">
                                    <div className='row'>
                                        <div className='col-10'>

                                        </div>
                                        <div className="col-2" style={{ marginBottom: '24px', marginTop: '42px' }}>
                                            <CRUDButtons text={COMMON_CRUD_FORM.NEW_BUTTON} icon={COMMON_CRUD_FORM.NEW_ICON_BUTTON} class="button button-primary-full button-small button--full rounded" onClick={() => { this.addNewRecord(TYPES.EMAIL) }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <TableComponent columns={[
                                                { Header: "Acciones", accessor: "actions", width: 80, style: { display: 'flex', alignItems: 'center' }, filterable: false, sortable: false, Cell: row => (<Actions onEdit={() => this.editRecord(row)} onDelete={() => this.deleteRecord(row)} />) },
                                                { Header: "Asunto", accessor: "Title", resizable: true, width: 150 },
                                                { Header: "Título", accessor: "SubTitle", resizable: true, width: 290 },
                                                { Header: "Texto", accessor: "Text", resizable: true },
                                                { Header: "¿Es un HTML?", accessor: "TieneHTML", resizable: true, width: 150 },
                                                { Header: "¿Tiene imagen?", accessor: "TieneImagen", resizable: true, width: 160 },
                                                { Header: "", accessor: "actions", width: 60, style: { display: 'flex', alignItems: 'center' }, filterable: false, sortable: false, Cell: row => (<Actions customIcon="paper-plane" customTitle="Enviar por e-mail" onCustom={() => this.sendEmail(row)} />) },
                                            ]} data={this.state.dataList} keyField={"Id"} {...props} />
                                        </div>
                                    </div>
                                </div>
                            </TabsPanel>
                        </div>
                    </div>
                </section>
            </section>
        )
    }
}

export default Novedades