import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer
} from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import * as routerHelpers from "../Routes/RouterHelpers";
import PublicRoutes from "./public.config";
import PrivateRoutes from "./private.config";
import { MainLayout } from '../Components';
import userAuth from '../Config/userauth';
import Footer from '../Components/Footer';
import * as utils from '../Shared/Utils';

const LayoutContext = {
  /**
   * Stores layout state, can be consumed globally.
   */
  State: createContext(null),

  /**
   * Stores `dispatch` function to update layout state, intended to be internal.
   */
  Dispatch: createContext(null)
};

const actionTypes = {
  /**
   * Initializes layout state from provided `{ pathname, menuConfig }` action
   * payload.
   */
  INIT: "INIT",

  /**
   * Updates current subheader from provided `{ title }` action payload.
   */
  SET_SUBHEADER: "SET_SUBHEADER",

  /**
   * Controls splash screen visibility.
   */
  SHOW_SPLASH_SCREEN: "SHOW_SPLASH_SCREEN",
  HIDE_SPLASH_SCREEN: "HIDE_SPLASH_SCREEN"
};

function reducer(state, { type, payload }) {
  if (type === actionTypes.INIT) {
    const nextState = init(payload);

    // Update only subheader.
    return { ...state, subheader: nextState.subheader };
  }

  if (type === actionTypes.SET_SUBHEADER) {
    return { ...state, subheader: payload };
  }

  if (type === actionTypes.SHOW_SPLASH_SCREEN) {
    return {
      ...state,
      splashScreen: {
        ...state.splashScreen,
        refs: { ...state.splashScreen.refs, [payload.id]: true }
      }
    };
  }

  if (type === actionTypes.HIDE_SPLASH_SCREEN) {
    const { [payload.id]: skip, ...nextRefs } = state.splashScreen.refs;

    return {
      ...state,
      splashScreen: { ...state.splashScreen, refs: nextRefs }
    };
  }

  return state;
}

function init({ pathname, menuConfig }) {
  //const currentPage = pathname.slice(1 /* Remove leading slash. */);
  let breadcrumbs = [];

  breadcrumbs.reverse();
  const state = { subheader: { title: "", breadcrumb: [], description: "" }, splashScreen: { refs: {} } };


  return state;
}

export function LayoutContextProvider({ history, children, menuConfig }) {
  const [state, dispatch] = useReducer(
    reducer,
    { menuConfig, pathname: history.location.pathname },
    // See https://reactjs.org/docs/hooks-reference.html#lazy-initialization
    init
  );

  // Subscribe to history changes and reinitialize on each change.
  useEffect(
    () =>
      history.listen(({ pathname }) => {
        dispatch({
          type: actionTypes.INIT,
          payload: { pathname, menuConfig }
        });
      }),

    /**
     * Passing `history` and `menuConfig` to `deps` ensures that `useEffect`
     * will cleanup current `history` listener and will dispatch `INIT`
     * with `menuConfig` reference from current render.
     *
     * @see https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect
     */
    [history, menuConfig]
  );

  const { refs: splashScreenRefs } = state.splashScreen;
  const splashScreenVisible = useMemo(
    () => Object.keys(splashScreenRefs).length > 0,
    [splashScreenRefs]
  );

  useEffect(() => {
    //const splashScreen = document.getElementById("splash-screen");

    if (splashScreenVisible) {
      //splashScreen.classList.remove("hidden");

      return () => {
        //splashScreen.classList.add("hidden");
      };
    }

    const timeout = setTimeout(() => {
      //splashScreen.classList.add("hidden");
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [splashScreenVisible]);

  // Pass state and dispatch to it's contexts.
  return (
    <LayoutContext.State.Provider value={state}>
      <LayoutContext.Dispatch.Provider value={dispatch}>
        {children}
      </LayoutContext.Dispatch.Provider>
    </LayoutContext.State.Provider>
  );
}

export const Routing = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  const isAuth = userAuth.isAuthenticated();
  routerHelpers.saveLastLocation(lastLocation);

  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls }) => ({
      isAuthorized: isAuth,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  const gotoMaintenance = () => {
    window.location.href = 'https://www.lifeseguros.com.ar/mantenimiento/';
  }

  useEffect(() => {
    const redirect = process.env.REACT_APP_MAINTENANCE === "true";
    if (redirect)
      gotoMaintenance()
  }, [])

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <>
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Switch>
          {!isAuthorized ? (
            /* Render auth page when user at `/auth` and not authorized. */
            PublicRoutes.map(({ path, component, exact }) => (
              <Route key={path}
                path={path}
                component={component} exact={exact} />
            ))
          ) : (
            /* Otherwise redirect to root page (`/`) */
            <Redirect from="/login" to={userLastLocation} />
          )}

          {!isAuthorized ? (
            /* Redirect to `/auth` when user is not authorized */
            <Redirect to="/login" />
          ) : (
            userAuth.isAdmin() ? (
              <Switch>
                {
                  <Redirect exact from="/" to="/admin-users" />
                }
                {PrivateRoutes.filter((f) => { return f.path === "/admin-users"; }).map(({ path, component, exact }) => (
                  <Route key={path} path={path} component={component} exact={exact} />
                ))}
              </Switch>
            ) : (
              userAuth.requestChangePassword() ? (
                <Switch>
                  {
                    <Redirect exact from="/" to={"/recover-reset?code=" + userAuth.userCode() + "&simulador=true"} />
                  }
                  {PublicRoutes.filter((f) => { return f.path === "/recover-reset"; }).map(({ path, component, exact }) => (
                    <Route key={path} path={path} component={component} exact={exact} />
                  ))}
                </Switch>
              ) : (
                userAuth.requestSelectProducer() ? (
                  <Switch>
                    {
                      <Redirect exact from="/" to="/simulate-producer" />
                    }
                    {PrivateRoutes.filter((f) => { return f.menu_producer === false; }).map(({ path, component, exact }) => (
                      <Route key={path} path={path} component={component} exact={exact} />
                    ))}
                  </Switch>
                ) : (
                  <MainLayout {...history}>
                    <Switch>
                      {
                        /* Redirect from root URL to /home. */
                        <Redirect exact from="/" to="/home" />
                      }
                      {PrivateRoutes.filter((f) => { return f.path !== "/simulate-producer" && f.path !== "/admin-users"; }).map(({ path, component, exact }) => (
                        <Route key={path} path={path} component={component} exact={exact} />
                      ))}
                    </Switch>
                  </MainLayout>
                )
              )
            )
          )}
        </Switch>
      </LayoutContextProvider>
      <Footer />
    </>
  );
});