import React from 'react'
import {
    Loading,
    Table as TableComponent,
} from "../../../Components";
import * as QLIBS from '../../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../../Config/userToken';
import { APPCONFIG } from '../../../app.config';
import moment from 'moment';
import { truncate } from '../../../Shared/Utils';
import userAuth from '../../../Config/userauth';


const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

const requestOptions = {
    method: 'GET',
    headers: DEFAULT_HEADER.get()
};

const SectionEnum = {
    Vida: 1,
    AP: 2,
    OtrosRubros: 3,
    Ajustes: 4
};

const initSections = [
    {
        id: SectionEnum.Vida,
        title: 'Vida',

    },
    {
        id: SectionEnum.AP,
        title: 'AP',
    },
    {
        id: SectionEnum.OtrosRubros,
        title: 'Otros rubros',
    },
    {
        id: SectionEnum.Ajustes,
        title: 'Ajustes',
    },
];

export class PolicyDetailGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: QLIBS.getTableColumns(props.columns),
            data: [],
            loading: false,
            width: (props.width === undefined || props.width === null) ? '100%' : props.width.toString() + 'px'
        };
    }

    componentDidMount() {
        this.setState({ loading: true }); 
        fetch(`${PRODUCERURL}api/settlement/ConceptoPorRubro?Rubro=${this.props.original.rubroEnum}&cuit=${userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer()}`, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    const cols = this.props.columns;
                    const formatData = data.map((item) => {
                        return {
                            fecha: moment(item.fecha).format("DD/MM/YYYY"),
                            periodo: item.periodo,
                            rubro: item.rubro,
                            rubroDescripcion: item.rubroDescripcion,
                            concepto: item.concepto,
                            percepcion:  `$ ${truncate(item.percepcion, 2)}`,
                            totalBR: `$ ${truncate(item.totalBR, 2)}`,
                            monto:  `$ ${truncate(item.monto, 2)}`,
                            iva:  `$ ${truncate(item.iva, 2)}`,
                        }});
                        this.setState({ data:formatData });
                }
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <>
                <Loading visible={this.state.loading}></Loading>
                <div className="m-4">
                    <div className="row my-3">
                        <div style={{ width: this.state.width }}>
                            <TableComponent
                                columns={this.state.columns}
                                data={this.state.data}
                                manual={false}
                                expandable={this.props.expandable}

                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
