/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import {
	Alert,
	Breadcrumb,
	Checkbox,
	Loading,
	Table as TableComponent
} from "../../Components";
import Actions from "../../Components/GridComponents/Actions";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { GLOBAL } from "../../Config/global";
import "font-awesome/css/font-awesome.min.css";
import { APPCONFIG } from '../../app.config';
import { USER_TOKEN } from '../../Config/userToken';
import moment from "moment";
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../Config/userToken';

class HistorialComunicaciones extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			listCommunication: [],
			indexItem: null,
			showModal: false,
			showGrid: false,
			loadingData: false
		};
		this.searchResultsError = "";

		this.detailsColumn = {
			Header: "",
			accessor: "actions",
			width: 100,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<>
					<Actions
						icon="fa fa-file"
						onDownload={() => this.detailsItem(row)}
					/>
				</>
			)
		};

		this.baseTableColumns = this.createBaseTableColumns();
		this.listTableColumns = this.createListTableColumns();

		this.handleShowBody = this.handleShowBody.bind(this);
		this.handleHideBody = this.handleHideBody.bind(this);
		this.handleConfirmCommunication = this.handleConfirmCommunication.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.loadGrid = this.loadGrid.bind(this);
	}

	componentDidMount() {

		this.handleResponse([]);
		this.loadGrid();

	}


	detailsItem = event => {
		const index = event.row._original.index;
		this.handleShowBody(index);
	}

	handleShowBody(index) {
		this.setState({showModal: true, indexItem: index }, () => {
			if (!this.state.listCommunication[this.state.indexItem].Readed)
				this.handleConfirmCommunication(this.state.listCommunication[this.state.indexItem].Id, false);
		});
	}

	handleHideBody() {
		this.setState({showModal: false, indexItem: null });
	}


	loadGrid = () => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		this.searchResultsError = "";

		this.setState({loadingData: true });

		const cuit = userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer();
		if (cuit !== null && cuit !== undefined) {

			const requestOptions = {
				method: 'GET',
				headers: DEFAULT_HEADER.get()
			};
	
		  fetch(PRODUCERURL + `api/Communication/SearchCommunication?CUIT=${cuit}&Vigentes=true&Ultimos=false`, requestOptions)
		  .then((response) => {
			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then(this.handleResponse)
				.catch(this.handleResponse);
			}
			else {
				this.searchResultsError = `Error procesando solicitud: ${response.statusText}`;
				this.setState({loadingData: false, showGrid: false, listCommunication: [] });
			}

		  });
		  
		}
	}

	handleResponse(response) {
		const { COMMON_SEARCH_FORM } = GLOBAL;

		let tableData = [];
		if (Array.isArray(response)) {
			this.searchResultsError = "";

			moment.locale("es");
			tableData = response.map(function (item, index) {
				item.index = index;
				return {
					...item,
					ReadedDesc: (item.Readed) ? 'Sí' : 'No',
					ConfirmedDesc: (!item.ConfirmationRequired) ? 'No requiere' : (item.Confirmed) ? 'Sí' : 'No',
					PriorityDesc: (item.Priority === 1) ? 'Baja' : (item.Priority === 2) ? 'Regular' : (item.Priority === 3) ? 'Alta' : null
				}
			});

		}
		else {
			this.searchResultsError = COMMON_SEARCH_FORM.SERVER_ERROR;
		}

		this.listTableColumns = this.createListTableColumns();

		this.searchTableData = {
			makeData: () => {
				return tableData
			}
		};

		this.setState({ loadingData: false, showGrid: true, listCommunication: response });
	}

	handleConfirmCommunication(Id, Confirmated) {

		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
	
			const requestOptions = {
				method: 'GET',
				headers: DEFAULT_HEADER.get()
			};
	
		const cuit = userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer();
		if (cuit !== null && cuit !== undefined) {
	
		  var confirmated = (Confirmated) ? 'true' : 'false';
	
		  fetch(`${PRODUCERURL}api/Communication/ConfirmCommunication?CUIT=${cuit}&Id=${Id}&Confirmated=${confirmated}`, requestOptions)
		  .then((response) => {
			if (response.ok) {
			  //actualizo los datos
			  this.loadGrid();
			}
			else {
			  this.searchResultsError = `Error procesando solicitud: ${response.statusText}`;
			}
		  });
	
		}
	}


	createListTableColumns() {
		const newTableColumns = [this.detailsColumn, ...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}

	createBaseTableColumns() {
		let newTableColumns = [];

		// Get Columns identifier
		let columns = {
			"Title": { title: "Título", field: "Title", show: true },
			"ReadedDesc": { title: "Leído", field:"ReadedDesc", show: true, width: 150 },
			"ConfirmedDesc": { title: "Confirmado", field:"ConfirmedDesc", show: true, width: 150 },
			"PriorityDesc": { title: "Prioridad", field: "PriorityDesc", show: true, width: 150 }
		};

		// Add Columns to Table
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });

		return newTableColumns;
	}


	GetUrlBody(id) {
		const userToken = USER_TOKEN.get();
		const AccessToken = userToken.token;
		const RefreshToken = userToken.refreshToken;

		const download_url = APPCONFIG.ENDPOINTS.PRODUCERURL + `api/FileRepository/Download/CommunicationBody/${id}?RefreshToken=${RefreshToken}&AccessToken=${AccessToken}`;
		return download_url;
	}
	
	GetUrlAttach(id) {
		const userToken = USER_TOKEN.get();
		const AccessToken = userToken.token;
		const RefreshToken = userToken.refreshToken;

		const download_url = APPCONFIG.ENDPOINTS.PRODUCERURL + `api/FileRepository/Download/CommunicationAttach/${id}?RefreshToken=${RefreshToken}&AccessToken=${AccessToken}`;
		return download_url;
	}



	render(...props) {
		const { PAGES } = GLOBAL;

		return (
			<>

			{this.state.showModal && (
			<div className="modal communication-modal" tabIndex="-1" role="dialog"  aria-modal="true">
				<div className="modal-dialog communication-modal-dialog" role="document">
					<div className="modal-content communication-modal-content">
						<div className="modal-header">
						<button type="button" className="close" aria-hidden="true" onClick={() => this.handleHideBody()}>x</button>
						<h4 className="modal-title text-center">{this.state.listCommunication[this.state.indexItem].Title}</h4>
						</div>
						<div className={(this.state.listCommunication[this.state.indexItem].BodyEmbedded) ? "modal-body" : "modal-body communication-modal-body"}>
						{(this.state.listCommunication[this.state.indexItem].BodyEmbedded) ?
							<iframe
								src={this.GetUrlBody(this.state.listCommunication[this.state.indexItem].Id)}
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							>
							</iframe>
							:
							<img className="communication-body-img"
								src={this.GetUrlBody(this.state.listCommunication[this.state.indexItem].Id)}
							>
							</img>
						}
						</div>
						<div className="modal-footer communication-footer">
						<div className='col-12 row'>

							<div className='col-6 communication-footer-left'>
							{this.state.listCommunication[this.state.indexItem].ConfirmationRequired && (
								<Checkbox
									id="ctlConfirmed"
									text={this.state.listCommunication[this.state.indexItem].ConfirmationText}
									disabled={this.state.listCommunication[this.state.indexItem].Confirmed}
									checked={this.state.listCommunication[this.state.indexItem].Confirmed}
									onChange={() => this.handleConfirmCommunication(this.state.listCommunication[this.state.indexItem].Id, true)}
									smallPrint=""
								/>
							)}
							</div>
							<div className='col-6 communication-footer-right'>
							{this.state.listCommunication[this.state.indexItem].UrlLink && (
								<a target='_blank'
									href={this.GetUrlAttach(this.state.listCommunication[this.state.indexItem].Id)}
									style={{ color: "#504F4F" }}>Descargar documentación adjunta <span className="download" />
								</a>
							)}
							</div>

						</div>
						</div>
					</div>
				</div>
			</div>
			)}
				
				<Loading visible={this.state.loadingData}></Loading>
				
				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/historialcomunicaciones" />
						</div>
						<div className="col-12">
							<h4 className="form-title">{PAGES.TITLE.HISTORIALCOMMUNICATION}</h4>
						</div>
						<div className="form-container container table-view col-md-12 mt-2">

							{this.searchResultsError && (
								<div id="form-error-message" className="row my-2">
									<div className="col-12">
										<Alert
											icon="times"
											message=""
											title={this.searchResultsError}
											errorInfo={this.searchResultsError}
											type="error"
										/>
									</div>
								</div>
							)}
							{this.state.showGrid && (
								<div className="row my-3">
									<div className="col-12 px-3">
										{<TableComponent
											columns={this.listTableColumns}
											data={this.searchTableData.makeData()}
										/>}
									</div>
								</div>
							)}
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default HistorialComunicaciones;