import React, { useEffect, useRef, useState } from 'react';
import { array, string, bool, number, func } from 'prop-types';
import ReactTable from 'react-table';
import { advancedExpandTableHOC } from 'react-table/lib/hoc/advancedExpandTable';
import "react-table/react-table.css";
import { GLOBAL } from '../../../Config/global';
import "react-table/react-table.css";


const ExpandTable = advancedExpandTableHOC(ReactTable);
const renderSpan = (spanClass) => (
    <span className={spanClass} />
);
const TextProps = {
    previousText: renderSpan('before-btn'),
    nextText: renderSpan('after-btn'),
    ofText: GLOBAL.TABLE_VIEW.OF_TEXT,
    rowsText: GLOBAL.TABLE_VIEW.ROWS_TEXT,
    pageText: GLOBAL.TABLE_VIEW.PAGE_TEXT
};
const TableMinimum = ({
    columns,
    className,
    data,
    defaultPageSize,
    filterable,
    onSortedChange,
    loading,
    sorted,
    onFilterChange,
    pageSize,
    subComponent }) => {
    const [selection, setSelection] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const tableElementRef = useRef(null);

    const toggleSingleSelection = key => {
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            setSelection([
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ]);
        } else {
            setSelection([
                ...selection,
                key
            ]);
        }
    };

    const isSelected = key => selection.includes(`select-${key}`);

    return (
        <React.Fragment>
            <ExpandTable
                {...TextProps}
                showPaginationBottom={false}
                ref={tableElementRef}
                data={data}
                loading={loading}
                pageSize={pageSize}
                onFilteredChange={onFilterChange}
                sorted={sorted}
                filterable={false}
                defaultPageSize={defaultPageSize}
                minRows={1}
                toggleSelection={toggleSingleSelection}
                selectAll={selectAll}
                resizable={false}
                isSelected={isSelected}
                onSortedChange={onSortedChange}
                columns={columns}
                className={`-striped -highlight ${className}`}
                SubComponent={subComponent}
            />
   
      
    </React.Fragment >
  );
}

TableMinimum.propTypes = {
    className: string,
    columns: array.isRequired,
    filterable: bool,
    defaultPageSize: number,
    data: array.isRequired,
    keyField: string,
    resizable: bool,
    getTableRef: func,
    onPageSizeChange: func,
    defaultSortingMethod: func,
    handleToggleAllSelection: func,
    handleToggleSingleSelection: func,
    handleSetSelectAll: func,
    getRowProps: func,
    selectInputComponent: func,
    selectAll: bool,
    isSelected: func,
    manual: bool,
    pageSize: number,
    onFilterChange: func,
    onSortedChange: func,
    loading: bool,
    pages: number,
    page: number,
    expandable: bool,
    subComponent: func
};

TableMinimum.defaultProps = {
    className: "",
    pageSize: 10,
    keyField: "id",
    defaultPageSize: 10,
    filterable: true,
    resizable: true,
    selectAll: false,
    loading: false,
    manual: false,
    sorted: [],
    isSelected: () => false,
    defaultSortingMethod: () => null,
    handleToggleAllSelection: () => null,
    handleToggleSingleSelection: () => null,
    handleSetSelectAll: () => null,
    getRowProps: () => ({
        className: ``
    }),
    selectInputComponent: () => null,
    onSortedChange: () => null,
    expandable: false,
    subComponent: null
};
export default TableMinimum;
