import React, { useState, useRef } from 'react';
import { string, number, bool, func } from 'prop-types';
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import fileUpload from '../../Resources/Images/DragAndDrop/file-upload.svg';
import { GLOBAL } from '../../Config/global';
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DragAndDrop = (props) => {
	const [filesData, setFilesData] = useState();
	const { DRAG_AND_DROP } = GLOBAL;
	const { refPond } = useRef(null);

	const [layoutMsj,] = useState(
		`<div class="dragAndDrop"> 
            <h1 class="dragAndDrop__title">${DRAG_AND_DROP.TITLE}</h1> 
            <p>${DRAG_AND_DROP.TEXT}</p> 
            <img src=${fileUpload} class="dragAndDrop__img" alt="icon"/>
        </div>`
	);

	const { allowMultiple, maxFiles, api, onhandleInit, onhandleFileSelect, onprocessfile } = props;

	return (
		<FilePond ref={refPond}
			allowMultiple={allowMultiple}
			maxFiles={maxFiles}
			labelIdle={layoutMsj}
			server={api}
			files={filesData}
			oninit={onhandleInit}
			onprocessfile={(error, file) => {
				onprocessfile(error, file);
			}}
			onupdatefiles={(fileItems) => {
				const newFiles = fileItems.map((fileItem) => fileItem.file);
				onhandleFileSelect(fileItems.map(fileItem => ({ filename: fileItem.filename, status: fileItem.status })))
				setFilesData(newFiles);
			}}
		/>
	);
};


DragAndDrop.defaultProps = {
	allowMultiple: true,
	maxFiles: 10,
	api: "",
	onhandleInit: ""
};

DragAndDrop.propTypes = {
	allowMultiple: bool,
	maxFiles: number,
	api: string,
	onhandleInit: func
};

export default DragAndDrop;
