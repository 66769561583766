import React from 'react';
import { Alert, Breadcrumb, Button, Loading, ViewsModal } from '../../Components';
import { APPCONFIG } from '../../app.config';
import { DEFAULT_HEADER } from '../../Config/userToken';
import userAuth from '../../Config/userauth';
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from "../../Config/global";
import { ErrorLevel } from '../../Components/Alert/errorLevel';
import { PolicyDetailGrid } from './partials/drillDown';
import TableMinimum from '../../Components/Table/TableMinimum';
import { RubroEnum } from './partials/rubro';
import { truncate } from '../../Shared/Utils';

function moneyFormat(val) {
    return val ? "$ " + truncate(val, 2) : null;
};

class ConceptosFacturar extends React.Component {
    constructor(props) {
        super(props);

        this.page = 0;
        this.pageSize = 10;

        this.state = {
            filterTabActive: 3,
            data: [],
            filters: [],
            sort: [],
        }
        this.onChangeTab = this.onChangeTab.bind(this);
        this.baseTableColumns = this.createBaseTableColumns();
        this.handleSaveViewModal = this.handleSaveViewModal.bind(this);
        this.listTableColumns = this.createListTableColumns();
    }
    onChangeTab = index => {
        this.setState({ name: this.state.name, filterTabActive: index, filtersSelected: (index > 0) });
    };
    createListTableColumns() {
        const newTableColumns = [...this.baseTableColumns.filter(f => f.show)];
        return newTableColumns;
    }
    getFilter() {
        let filters = this.state.filters
        return filters
    }
    getSort() {
        let sort = this.state.sort
        return sort
    }

    handleSaveViewModal(newListColumns) {
        const tempTableColumns = this.createBaseTableColumns();

        this.baseTableColumns = newListColumns.map(c => {
            let col = tempTableColumns.find(f => f.name === c.name);
            col.show = c.show;
            return col;
        });

        this.listTableColumns = this.createListTableColumns();
    }

    createBaseTableColumns() {
        const { REPORT_FORMAT } = GLOBAL;
        let newTableColumns = [];
        let columns = {
            "rubro": { title: "Rubro", field: 'Rubro', format: REPORT_FORMAT.TEXTO, show: true, sortable: false, filterable: false },
            "monto": { title: "Monto", field: 'Monto', signo: '$', format: REPORT_FORMAT.MONEDA, summarize: true, show: true, sortable: false, filterable: false },
            "iva": { title: "Iva", field: 'Iva', signo: '$', format: REPORT_FORMAT.MONEDA, summarize: true, show: true, sortable: false, filterable: false },
            "percepcion": { title: "Iva Percep.", signo: '$', field: 'IvaPercep', summarize: true, format: REPORT_FORMAT.MONEDA, show: true, sortable: false, filterable: false },
            "total": { title: "Total", signo: '$', field: 'Total', summarize: true, format: REPORT_FORMAT.MONEDA, show: true, sortable: false, filterable: false },
        };
        let cols = QLIBS.getTableColumns(columns);
        cols.forEach((c) => { newTableColumns.push(c) });
        return newTableColumns;
    }
    async componentDidMount() {
        this.handleSubmit()
    }

    handleSubmit = async (event) => {
        if (event) event.preventDefault();
        const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
        const requestOptions = {
            method: 'POST',
            headers: DEFAULT_HEADER.get()
        };

        var url = new URL(`${PRODUCERURL}api/Settlement/Conceptos?cuit=${userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer()}`);
        this.setState({ loadingData: true });
        const x = await fetch(url, requestOptions);
        const response = await x.json();
        if (response) {
            if (Array.isArray(response)) {
                const formatData = response.map((item) => {
                    return {
                        rubro: this.initSections.find(x => x.id === item.rubro).title,
                        iva: `$ ${truncate(item.iva, 2)}`,
                        monto: `$ ${truncate(item.monto, 2)}`,
                        percepcion: `$ ${truncate(item.percepcion, 2)}`,
                        total: `$ ${truncate(item.totalBR, 2)}`,
                        rubroEnum: item.rubro
                    }
                });
                this.setState({ data: formatData, loadingData: false });
            };
        } else {
            this.setState({ loadingData: false });
        }

    }

    columnsDef = {
        "periodo": { title: "Período", field: 'periodo', width: 110},
        "rubroDescripcion": { title: "Rubro", field: 'rubroDescripcion', width: 110, show:false},
        "fecha": { title: "Fecha", field: 'fecha', format: GLOBAL.REPORT_FORMAT.FECHA, width: 100},
        "concepto": { title: "Concepto", field: 'concepto', width: 150},
        "monto": { title: "Monto", field: 'monto', format: moneyFormat },
        "iva": { title: "Iva", field: 'IVA', format: moneyFormat },
        "percepcion": { title: "Iva Percep.", field: 'percepcion', format: moneyFormat },
        "totalBR": { title: "Total", field: 'totalBR', format: moneyFormat },
    };

    createExcelReportColumns() {
		let newTableColumns = [];
        let cols = QLIBS.getTableColumns(this.columnsDef);
		cols.forEach((c) => { newTableColumns.push(c) });
        const excelReportColumns = newTableColumns.map(c => {
			return {
				Title: c.title,
				Field: c.field,
				Summarize: c.summarize,
				Format: c.format
			}
		});
        return excelReportColumns
    }


    handleExportSubmit(event) {
        const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

        event.preventDefault();
        this.setState({ loadingData: true });

        const requestOptions = {
            method: 'POST',
            headers: DEFAULT_HEADER.get(),
            body: JSON.stringify({
                producerCuit: userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer(),
                filters: this.state.searchCriteriaText?.replace(/['"]+/g, "\\'"),
                columns: JSON.stringify(this.createExcelReportColumns())
            })
        };

        fetch(`${PRODUCERURL}api/Settlement/Conceptos/Export`, requestOptions)
            .then(function (resp) {
                if (resp.ok)
                    return resp.blob();
            })
            .then(function (blob) {
                if (blob) {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Conceptos.xlsx";
                    link.click();
                }
            })
            .then(() => {
                this.setState({ loadingData: false });
            })
    };

    initSections = [
        {
            id: RubroEnum.Vida,
            title: 'Vida',
        },
        {
            id: RubroEnum.AP,
            title: 'AP',
        },
        {
            id: RubroEnum.OtrosRubros,
            title: 'Otros rubros',
        },
        {
            id: RubroEnum.Ajustes,
            title: 'Ajustes',
        },
    ];

    render() {
        const { TABLE_VIEW } = GLOBAL;
        const { COMMON_SEARCH_FORM } = GLOBAL;
        return (
            <>{this.state.showViewsModal && <ViewsModal
                listColumns={this.baseTableColumns}
                onDismiss={() => { this.setState({ showViewsModal: false }) }}
                onSave={(listColumns) => { this.handleSaveViewModal(listColumns) }}
            />}
                <Loading visible={this.state.loadingData}></Loading>

                <section className="col-12">
                    <div className="row pagosLiquidaciones">
                        <div className="col-12">
                            <Breadcrumb currentPage="/conceptos-a-facturar" />
                        </div>
                        <div className="form-container container table-view col-md-12 mt-2">
                            <form id="comisiones_search_form_1" onSubmit={this.handleSubmit} onKeyDown={this.onKeyDown} className="register__Form" noValidate>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="search-buttons">
                                                    <Button type="submit" id="btnSubmit" classes="to-bottom button-small search-button"><span className="fa fa-search"> </span> {COMMON_SEARCH_FORM.SEARCH_BUTTON}</Button>
                                                </div>
                                                <div className="search-buttons">
                                                    <Button type="button" classes="to-bottom button-small search-button" onClick={this.clearFiltros}><span className="fa fa-filter"> </span> {COMMON_SEARCH_FORM.CLEAR_FILTERS}</Button>
                                                </div>
                                                {(this.state.data.length > 0) && (
                                                    <div className="search-buttons">
                                                        <Button type="submit" onClick={event => this.handleExportSubmit(event)} size="small">
                                                            <i className="fas fa-file-download mx-2" /> {TABLE_VIEW.EXPORT_TEXT}</Button>
                                                    </div>
                                                )}
                                                <div className="search-buttons">
                                                    <Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showViewsModal: true }) }}><span className="fa fa-table"> </span> {COMMON_SEARCH_FORM.VIEW_BUTTON}</Button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {this.state.error && <div id="form-error-message" className="row my-2">
                                    <div className="col-12">
                                        <Alert
                                            icon="times"
                                            message=""
                                            title={this.searchResultsError}
                                            errorInfo={this.searchResultsError}
                                            level={ErrorLevel.Error}
                                        />
                                    </div>
                                </div>}
                                <div className="row my-3">
                                    <div className="col-12 px-3 table-comisiones">
                                        <TableMinimum
                                            columns={this.listTableColumns}
                                            sorted={this.getSort()}
                                            onSortedChange={this.applySort}
                                            filter={this.getFilter()}
                                            data={this.state.data}
                                            manual={true}
                                            onFilterChange={this.applyFilter}
                                            page={this.page}
                                            pageSize={this.pageSize}
                                            onPageSizeChange={this.changeSize}
                                            pages={this.pages}
                                            expandable={true}
                                            changePage={this.changePage}
                                            subComponent={props => (<PolicyDetailGrid columns={this.columnsDef} width={(window.innerWidth - 120)} {...props} />)}
                                        // {...props} 
                                        />
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </>
        )
    };
}
export default ConceptosFacturar;