import React, { Component } from 'react';
import moment from 'moment';
import { Breadcrumb, Button, Alert, Loading, Combobox } from '../../Components';
import { FORM_ERRORS } from "../../Config/form_errors";
import { withRouter } from "react-router-dom";
import userAuth from '../../Config/userauth';
import { APPCONFIG } from '../../app.config';
import { DEFAULT_HEADER } from '../../Config/userToken';
import { numericLiteral } from '@babel/types';

export class CertificadosRetencion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingForm: false,
            meses: [
                { id: 7, value: 'Julio'},
                { id: 8, value: 'Agosto'},
                { id: 9, value: 'Septiembre'},
                { id: 10, value: 'Octubre'},
                { id: 11, value: 'Noviembre'},
                { id: 12, value: 'Diciembre'}
            ],
            anios: [],
            errorMsg_mes: '',
            borderColor_mes: '',
            errorMsg_anio: '',
            borderColor_anio: '',
            mes: '',
            anio: '',
            intermed: null,				
            submitError: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);     
    }


    componentDidMount() {   
        const anios = [];

        for(let i = new Date().getFullYear(); i > 2020; i--) {
            anios.push({ id: i, value: i});
        }

        this.setState({ anios: anios });

        const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
        const requestOptions = {
			method: 'GET',			
			headers: DEFAULT_HEADER.get()
		};
				
        fetch(PRODUCERURL + "api/producer/GetProducerCodes?userId=" + userAuth.userId(), requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((data) => {            
			const intermed =  data.filter(e => e.ProducerCodes.find(d => d.Core === 'VT')).map(e => e.ProducerCodes.find(d => d.Core === 'VT').Code)[0];
            this.setState({ intermed: intermed });            

            // fetch(PRODUCERURL + 'settlement/?$filter=(ProducerId%20eq%20' + this.state.intermed + ')%20and%20(Source%20eq%20%27VT%27)%20and%20%20ImputationDate%20ge%202021-06-01%20and%20ImputationDate%20le%202021-06-30', requestOptions)
			// .then((response) => {
			// 	return response.json();
			// })
			// .then((data) => {
			// 	const retencionesHabitadas = (data.find(e => e.estado === 1) || {});            
			// 	this.setState({ retencionesHabitadas: retencionesHabitadas });            
		    // });
        });			
    }

    handleChange = event => {        
    }

    handleComboChange = (selectedOption, event) => {
        let self = this;
        let inputName = event.name;
        let inputValue = selectedOption.value;
        self.setState({ [inputName]: inputValue }, () => {           
        });
    }	
	
    handleSubmit = event => {
        event.preventDefault();
        const { COMMON_ERRORS } = FORM_ERRORS;
        let errorMsg_mes = '';
        let borderColor_mes = '';
        let errorMsg_anio = '';
        let borderColor_anio = '';

        if(!this.state.mes) {
            errorMsg_mes = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            borderColor_mes = 'error';
        }

        if(!this.state.anio) {
            errorMsg_anio = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            borderColor_anio = 'error';
        }
      
        this.setState({ errorMsg_mes, borderColor_mes, errorMsg_anio, borderColor_anio});
        if(!this.state.mes || !this.state.anio) return;

        const mes = this.state.mes.toString().length === 1 ? `0${this.state.mes}` : this.state.mes;
        const anio = this.state.anio;
        const intermed = this.state.intermed;		
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
        const requestOptions = {
			method: 'GET',			
			headers: DEFAULT_HEADER.get()
		};		
		
        const fechaDesde = `${anio}-${mes}-01`;
        const fechaHasta = moment(fechaDesde, 'YYYY-MM-DD').add('months', 1).add('days', -1).format('YYYY-MM-DD');
		
		fetch(PRODUCERURL + `settlement/?$filter=(ProducerId%20eq%20${this.state.intermed})%20and%20(Source%20eq%20%27VT%27)%20and%20%20ImputationDate%20ge%20${fechaDesde}%20and%20ImputationDate%20le%20${fechaHasta}`, requestOptions)
			.then((response) => {
				return response.json();
			})
			.then((data) => {				
				const retencionesHabitadas = (data.find(e => e.estado === 1) || {});    
                
                if(!retencionesHabitadas.estado) {
                    this.setState({ submitError: 'Para descargar retenciones por favor comunicarse con ...'});
                } else {
                    this.setState({ submitError: numericLiteral});
                    window.open(`http://ori-dtp-vtimeos.retiro.ar:8083/Temp/agentes_view.ashx?month=${mes}&year=${anio}&intermed=${intermed}`)
                }                                    
            })
			.catch((error) => {
                this.setState({ submitError: `Error al invocar a settlement: ${error.message}`});
			});    						
    }
	
    render(...props) {
        return (
            <section>
                <Loading visible={this.state.loadingForm}></Loading>  
                <section className="form-container master-page">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <Breadcrumb currentPage="/certificados-retencion" />                                
                            </div>
                        </div>
                    </div> 
                    <div className="form-container container">
                        <div className="padding10">
                          <form id="consulta_form" onSubmit={this.handleSubmit}>
                            {this.state.submitError && (
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <Alert
                                            type="error"
                                            icon="times"
                                            title="ERROR!"
                                            message={this.state.submitError}
                                            noDetail={true}
                                        />
                                    </div>
                                </div>
                            )}
                            {/* {this.state.submitOk && (
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <Alert
                                            type="success"
                                            title="Mensaje enviado"
                                            message="Su comentario seria recepcionado y respondido a la brevedad. Muchas gracias."
                                            noDetail={true}
                                        />
                                    </div>
                                </div>
                            )} */}                          
                            <div className="row">
                                <div className="col-6">
                                    <Combobox
                                        id="mes"
                                        label="Mes"
                                        options={this.state.meses.map((item) => ({ value: item.id, label: item.value }))}
                                        defaultValue={this.state.mes}
                                        onChange={this.handleComboChange}
                                        errorMsg={this.state.errorMsg_mes}
                                        borderColor={this.state.borderColor_mes}
                                    />
                                </div>
                                <div className="col-6">
                                    <Combobox
                                        id="anio"
                                        label="Año"
                                        options={this.state.anios.map((item) => ({ value: item.id, label: item.value }))}
                                        defaultValue={this.state.anio}
                                        onChange={this.handleComboChange}
                                        errorMsg={this.state.errorMsg_anio}
                                        borderColor={this.state.borderColor_anio}
                                    />
                                </div>
                            </div>                                                   
                            <div className="row">
                                <div className="col-12">
                                    <Button type="submit" id="btnSubmit">Descargar</Button>
                                </div>
                            </div>                            
                        </form>
                        </div>
                    </div> 
                </section>     
            </section>
        )
    }
}

export default withRouter(CertificadosRetencion);