import React from 'react';
class Information extends React.Component {
	
	render() {
		return (
			<>
				<div class='rebranding'>
          <div class="content">
          <p>Temporalmente, te redirigimos al portal de ebroker para acceder a la información solicitada. </p>
          <p>Tené en cuenta que deberás volver a ingresar tus credenciales.</p>
          <a class="btn-green" href="https://eservices.lifeseguros.com.ar/hlogbrk.aspx" target="_blank" rel="noopener noreferrer">Ingresar</a>
          </div>
          </div>
				
			</>
		);
	}
}

export default Information;