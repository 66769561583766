/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { HomeCarousel, HomeCommunication, HomeUserInfo, HomeAccountManagerInfo } from "../../Components";


const Home = props => {

  return (
    <div className="container-fluid">

      <div className="row container-home">

        <div className="col-sm-12 col-md-6 pl-3 pr-1">
          <HomeCarousel />
        </div>
        <div className="col-sm-12 col-md-3 pl-1 pr-1">
          <div className="col-12 pb-1 px-0" style={{ height: "80%"}}>
            <HomeCommunication />
          </div>
          <div className="col-12 pb-1 px-0" style={{ height: "20%"}}>
          <a href={process.env.REACT_APP_MARKETING_LINK} target='_blank'  rel="noopener noreferrer"><img src="./images/Home/marketing_digital.png" alt="Marketing digital" /></a>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 pl-1 pr-3">
          <div className="col-12 pb-1 px-0" style={{ height: "45%"}}>
            <HomeUserInfo />
          </div>
          <div className="col-12 pb-1 px-0" style={{ height: "35%"}}>
            <HomeAccountManagerInfo />
          </div>
          <div className="col-12 pb-1 px-0" style={{ height: "20%"}}>
          <a href={process.env.REACT_APP_MARKETING_LINK} target='_blank'  rel="noopener noreferrer"><img src="./images/Home/capacitacion.png" alt="Capacitaciones" /></a>
          </div>
        </div>

      </div>
      
    </div>
  )
};

export default Home;