import { APPCONFIG } from '../app.config';

const APIS = {
    URLS: {
        LOGOUT: APPCONFIG.ENDPOINTS.SECURITYURL + 'Account/Logout',

        USERS_SIMULATORS_QUERY: APPCONFIG.ENDPOINTS.SECURITYURL + 'simulatorusers/getusers',
        USERS_SIMULATORS_CREATE: APPCONFIG.ENDPOINTS.SECURITYURL + 'simulatorusers/createuser',
        USERS_SIMULATORS_DELETE: APPCONFIG.ENDPOINTS.SECURITYURL + 'simulatorusers/deleteuser',
        USERS_SIMULATORS_LOGIN: APPCONFIG.ENDPOINTS.SECURITYURL + 'simulatorusers/login',
        USERS_SIMULATORS_CHANGE_PASSWORD: APPCONFIG.ENDPOINTS.SECURITYURL + 'account/resetpassword',
        
        SIMULATE_PRODUCER: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/simulator/simulateproducer',
        
        ACTIONS_QUERY: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/simulator/getaudit',
        ACTIVITY_QUERY: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/simulator/getlogs?exception=false',
        ERRORS_QUERY: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/simulator/getlogs?exception=true',
        MESSAGES_QUERY: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/simulator/getcommunicationaudit',
        
        PRODUCER_AUTOCOMPLETE_BY_CUIT: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/datos/GetAutocompleteValues?e=Productor_CUIT',
        PRODUCER_AUTOCOMPLETE_BY_NAME: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/datos/GetAutocompleteValues?e=Productor_NOMBRE',

        NEWS_QUERY: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/news/NewsSearch',
        NEWS_CREATE: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/news/NewsCreate',
        NEWS_UPDATE: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/news/NewsUpdate',
        NEWS_DELETE: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/news/NewsDelete',
        SEND_NEWS: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/news/SendNews',
        
        PRODUCER: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/datos/GetComboValues?e=Productor',
        SECTOR: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/datos/GetComboValues?e=Sector&parentId=',
        TIPO_CONSULTA: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/datos/GetComboValues?e=TipoConsulta&parentId=',
        
        SCHEDULER_SAVE: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/scheduler/SaveSchedulerData',
        SCHEDULER_GET: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/scheduler/GetSchedulerData',

        STATISTICS_MODULO_MAS_VISITADO: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Simulator/GetModulos?period=',
        STATISTICS_USUARIOS_REGISTRADOS: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Simulator/GetUsuariosRegistrados',
        STATISTICS_ULTIMOS_ACCESOS: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Simulator/GetUltimosAccesos?period=',
        STATISTICS_PRODUCTORES_REGISTRADOS: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Simulator/GetProductoresRegistrados',

        PARAMETERS_GET: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Parameters/Parameters',
        PARAMETERS_SAVE: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Parameters/ParametersSave',

        CONSULTA_SEND: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Communication/EnviarConsulta',
        DOWNLOAD_CERTIFICADO_INDIVIDUAL: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Datos/Certificado',
        DOWNLOAD_CERTIFICADO_MATRIZ: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Datos/PolizaMatriz',
        DOWNLOAD_CERTIFICADO_PAGOSYLIQ: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/Settlement/Certificado',
        DOWNLOAD_FILE:  APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/FileRepository/File',
        DELETE_FILE: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/FileRepository/',
    }
}
export { APIS };