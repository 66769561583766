import React, { useRef, useState } from "react";
import { bool } from "prop-types";
import { GLOBAL } from "../../Config/global";
import Button from "../Button";

const FileUpload = ({ disabled }) => {
  const uploadButtonStyles = {
    padding: '6px 34px',
    borderWidth: '1px'
  };

  const { FILE_UPLOAD } = GLOBAL;
  const [file, setFile] = useState({});
  const uploadButtonRef = useRef(null);

  const onChange = event => {
    setFile(event.target.files[0]);
  };

  const onButtonClick = () => {
    uploadButtonRef.current.click();
  };

  return (
    <React.Fragment>
      <Button type="button" fill="outline" size="medium" onClick={onButtonClick} customStyles={uploadButtonStyles} disabled={disabled} >
        {FILE_UPLOAD.TEXT}
      </Button>
      <input
        ref={uploadButtonRef}
        type="file"
        id="file"
        hidden
        onChange={onChange}
      />
      <div className="d-flex align-items-center">
        <span className="filename mx-2"> {file && (file.name || FILE_UPLOAD.DEFAULT_FILE)} </span>
      </div>
    </React.Fragment>
  );
};

FileUpload.propTypes = {
  disabled: bool
}

FileUpload.defaultProps = {
  disabled: false
}

export default FileUpload;
