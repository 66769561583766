import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import LiquidacionStep1 from '../../Resources/Images/Tutoriales/Liquidacion/Liquidacion-1.jpg';
import LiquidacionStep2 from '../../Resources/Images/Tutoriales/Liquidacion/Liquidacion-2.jpg';

const Liquidacion = props => {
    return (
        <div>
            <ReactWizard
                steps={[
                    {
                        stepName: "PASO 1", component: <StepTutorial image={LiquidacionStep1} text={['Hacer clic en “Ingresar” para ser redirigido al portal de ebroker para acceder a la información solicitada.']} />
                    },
                    {
                        stepName: "PASO 2", component: <StepTutorial image={LiquidacionStep2} text={['Accede al portal de ebroker volviendo a ingresar tus credenciales']} />
                    }
                ]}
                navSteps
                title="Paso a paso para utilizar la búsqueda de Liquidación."
                headerTextCenter
                validate
                color="green"
                previousButtonText="Volver"
                nextButtonText="Continuar"
                finishButtonText="Finalizar"
                finishButtonClick={() => { props.onFinish(); }}
            />
        </div>
    )
}

Liquidacion.propTypes = {

}

export default Liquidacion
