import React, { useState } from 'react';
import { string, oneOf, bool } from 'prop-types';
import { Button, Checkbox, TextArea } from '../index';
import { GLOBAL } from '../../Config/global';
import { ErrorLevel } from './errorLevel';

const Alert = ({ errorDesc, errorInfo, errorLine, icon, message, title, noDetail, details, level }) => {
  const [showDetails, setShowDetails] = useState(details);
  const [showDescription, setShowDescription] = useState(false);
  const handleClick = () => {
    setShowDetails(!showDetails);
  }

  const { ALERT_ERROR } = GLOBAL;

  return (
    <>
      <div
        className={`alert-modal alert-modal-${level} text-center text-lg-left text-light`}
        role="alert"
      >
        <div className="d-md-inline">
          <i className={`fas fa-${icon}-circle icon`}></i><p className="message">{title}</p>
        </div>
        <p className="text"> {message}</p>
        {
          (level === ErrorLevel.Error || level === ErrorLevel.Information) && !noDetail &&
          <button onClick={handleClick} className="show-details" type="button">
            {!showDetails ? <span>{ALERT_ERROR.SEE_MORE}</span> : <span>{ALERT_ERROR.SEE_LESS}</span>}
          </button>
        }
      </div>
      {
        level === ErrorLevel.Information && !noDetail &&
        <div className={`alert-content transition-${showDetails ? 'show' : 'hide'}`}>
          <div className="error-details">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="error-spec">
                    {errorInfo &&
                      <b>{errorInfo}</b>
                    }
                    {errorInfo &&
                      <br />
                    }
                    {errorDesc.split('<br>')?.map((item, i) => (
                      <p key={i}>{item}</p>
                    )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`alert-modal-end alert-modal-${level}`}></div>
        </div>
      }
      {
        level === ErrorLevel.Error && !noDetail &&
        <div className={`alert-content transition-${showDetails ? 'show' : 'hide'}`}>
          <div className="error-details">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="error-spec">
                    <span className="error-line">
                      {errorLine}:
                    </span> {errorInfo}<br />
                    {errorDesc}
                    <span className="show-more" onClick={() => setShowDescription(!showDescription)}>
                      {" "}Ver más <i className={`fas fa-chevron-${!showDescription ? 'right' : 'down'}`} />
                    </span>
                    <span className={`more-info error-content transition-${showDescription ? 'show-lines' : 'hide'}`}>
                      {errorDesc}
                      <br />{errorDesc}
                      <br />{errorDesc}
                      <br />{errorDesc}
                    </span>
                  </p>
                  <p>{ALERT_ERROR.TEXTAREA_TITLE}</p>
                  <TextArea text={ALERT_ERROR.TEXTAREA_PLACEHOLDER} rowNum={8} />
                  <div id="checkbox-area">
                    <Checkbox id="errorCheck" text={ALERT_ERROR.ATTACH_REPORT} />
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between">
                <div className="col-6">
                  <Button color="secondary" fill="outline" size="medium-lg">{ALERT_ERROR.CANCEL_BUTTON}</Button>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <Button size="medium-lg">{ALERT_ERROR.SEND_BUTTON}</Button>
                </div>
              </div>
            </div>
          </div>
          <div className={`alert-modal-end alert-modal-${level}`}></div>
        </div>
      }
    </>
  );
}

Alert.defaultProps = {
  errorDesc: '',
  errorInfo: '',
  errorLine: '',
  icon: 'check',
  level: ErrorLevel.Warning,
  noDetail: false,
  details: false
};

Alert.propTypes = {
  errorDesc: string,
  errorInfo: string,
  errorLine: string,
  icon: oneOf(['info', 'check', 'times']),
  message: string.isRequired,
  title: string.isRequired,
  type: string,
  noDetail: bool,
  details: bool
};


export default Alert;
