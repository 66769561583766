import React, { Component } from 'react'


export class PolicyInfoModal extends Component {
    constructor(props) {
        super(props);
        const { policy, onDismiss } = props;
        this.state = {
            onDismiss: onDismiss,
            policy: policy
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="modal" tabIndex="-1" role="dialog" aria-hidden="false" id="modal-novedades">
                <div className="modal-fullscreen-xl" role="document">
                    <div className="modal-content">
                        <div>
                            <button type="button" className="close positioning" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={this.state.onDismiss}>&times;</span>
                            </button>
                            <h5 className="modal-title text-center">Información detallada</h5>
                        </div>
                        <div className="modal-body">
                            <section id="">
                                <nav>
                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Información general</a>
                                        <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Información de la póliza</a>
                                        <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Información asegurado</a>
                                    </div>
                                </nav>
                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div className="row" style={{paddingTop:"15px"}}>
                                                <div className="col">
                                                    <ul style={{textAlign:"left",display:"grid",gridGap:"10px" }}>
                                                        <li> <b>Producto: </b> {this.state.policy.producto}</li>
                                                        <li><b>Nombre: </b> {this.state.policy.nombre}</li>
                                                        <li><b>Premio: </b> {this.state.policy.premio}</li>
                                                        <li><b>Prima: </b> {this.state.policy.prima}</li>
                                                        <li><b>Moneda: </b> {this.state.policy.moneda}</li>
                                                        <li><b>Póliza: </b> {this.state.policy.poliza}</li>
                                                        <li><b>Fecha de emisión: </b> {this.state.policy.fecha_emision}</li>
                                                        <li><b>Número de certificado: </b> {this.state.policy.certificado}</li>
                                                        <li><b>Estado: </b> {this.state.policy.estado}</li>
                                                        <li><b>Fecha último estado: </b> {this.state.policy.fechaultimoestado}</li>
                                                        <li><b>Periodo facturado: </b> {this.state.policy.periodo_facturado}</li>                                              
                                                    </ul>                                                
                                            </div>
                                            <div className="col">
                                                    <ul style={{textAlign:"left",display:"grid",gridGap:"10px" }}>                                                        
                                                        <li><b>Medio de pago </b> {this.state.policy.medio_pago}</li>
                                                        <li><b>Vigencia desde </b> {this.state.policy.fecha_vigencia_desde}</li>
                                                        <li><b>Vigencia hasta </b> {this.state.policy.fecha_vigencia_hasta}</li>
                                                        <li><b>Productor </b> {this.state.policy.productor}</li>
                                                        <li><b>Número de documento contratante</b> {this.state.policy.documento_contratante}</li>
                                                        <li><b>Compañía </b> {this.state.policy.compania}</li>
                                                        <li><b>Ramo </b> {this.state.policy.ramo}</li>
                                                        <li><b>Certificado </b> <a href={this.state.policy.pdfUrl}>Click para abrir</a></li>
                                                        <li><b>Core </b> <i>{this.state.policy.core}</i></li>
                                                        <li><b>Fecha información </b> <i>{this.state.policy.fecha_dm}</i></li>
                                                    </ul>                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">..5.</div>
                                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">..7.</div>
                                </div>
                            </section>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default PolicyInfoModal