import React, { useState } from "react";
import { DragAndDrop, Header} from "../../Components";
import { GLOBAL } from "../../Config/global";

const DragFiles = () => {
  const [settings] = useState({
    apiURl: {},
    allowMultiple: true
  });

  const {
    COMPONENTS_FORM: { MENUS }
  } = GLOBAL;

  const handleInit = () => {
    // instance has initialised
  };

  return (
    <section>
      <Header
        menuItems={MENUS}
        showBackButton={false}
        showHamburgerMenu
        showNavbar
      />
      <section className="form-container master-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center ">
              <DragAndDrop
                maxFiles={20}
                allowMultiple={settings.allowMultiple}
                api={settings.apiURl}
                onhandleInit={handleInit()}
              />
            </div>
          </div>
        </div>
      </section>
      {/*<Footer rol={CHANNEL_OPTIONS.ROLE} />*/}
    </section>
  );
};

export default DragFiles;
