import React, { useState } from 'react'
import { Accordion, Card, Button} from "react-bootstrap";
import {
    Loading,
    Table as TableComponent,
} from "../../../Components";
import * as QLIBS from '../../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../../Config/userToken';
import { APPCONFIG } from '../../../app.config';
import moment from 'moment';


const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

const requestOptions = {
    method: 'GET',
    headers: DEFAULT_HEADER.get()
};

const SectionEnum = {
    GrupoFamiliar: 1,
    DireccionPoliza: 2,
    Coberturas: 3,
    Beneficiarios: 4,
    ComposicionPremio: 5,
    FormaDePago: 6,
    Facturacion: 7,
    Rechazos: 8
};

const initSections = [
    {
        id: SectionEnum.GrupoFamiliar,
        title: 'Grupo Familiar',
        loaded: false
    },
    {
        id: SectionEnum.DireccionPoliza,
        title: 'Dirección de Póliza',
        loaded: false
    },
    {
        id: SectionEnum.Coberturas,
        title: 'Coberturas',
        loaded: false
    },
    {
        id: SectionEnum.Beneficiarios,
        title: 'Beneficiarios',
        loaded: false
    },
    {
        id: SectionEnum.ComposicionPremio,
        title: 'Composición de Premio',
        loaded: false,
        secondLevel: true
    },
    {
        id: SectionEnum.FormaDePago,
        title: 'Forma de Pago',
        loaded: false
    },
    {
        id: SectionEnum.Facturacion,
        title: 'Facturación',
        loaded: false,
        expandable: true
    },
    {
        id: SectionEnum.Rechazos,
        title: 'Rechazos',
        loaded: false,
        secondLevel: true
    }
];

function dateFormat(val) {
    return val ? moment(val).format('DD/MM/YYYY') : null;
}

function moneyFormat(val) {
    return val ? "$ " + val.toLocaleString("es-AR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) : null;
};

const columnsDef = {
    [SectionEnum.GrupoFamiliar]: {
        "NROLEDESC": { title: "Rol" },
        "SCLIENTDESC": { title: "Nombre" },
        "DBIRTHDAT": { title: "Fecha de nacimiento", format: dateFormat },
        "NRODOC": { title: "Documento" },
        "DNULLDATE": { title: "Fecha anulación", format: dateFormat },
        "DEFFECDATE": { title: "Fecha de efecto" }
    },
    [SectionEnum.DireccionPoliza]: {
        "SRECTYPEDESC": { title: "Tipo" },
        "SDESCADD": { title: "Dirección" },
        "SZIP_CODE": { title: "Código Postal" },
        "NLOCALDESC": { title: "Localidad" },
        "NPROVINCEDESC": { title: "Provincia" },
        "NCOUNTRYDESC": { title: "País" },
        "SE_MAIL": { title: "Correo electrónico" }
    },              
    [SectionEnum.Coberturas]: {
        "NCOVERDESC": { title: " Nombre Cobertura" },
        "NCAPITAL": { title: "Suma Asegurada" },
        "DSTARTDATE": { title: " Fecha Vigencia Desde", format: dateFormat }, // TODO: falta incluir este campo en el response
        "DEXPIRDAT": { title: "Fecha Vigencia Hasta", format: dateFormat }    // TODO: falta incluir este campo en el response  
    },   
    [SectionEnum.Beneficiarios]: {
        "SCLIENTDESC": { title: "Nombre" },
        "CLIENTDBITHDATE": { title: " Fecha de Nacimiento", format: dateFormat },
        "NPARTICIP": { title: "Porcentaje de participación" },
        "NGROUPBEN": { title: "Orden Beneficiario" },
        "NRELATIONDESC": { title: "Relación" },
        "DEFFECDATE": { title: "Fecha de Actualización", format: dateFormat }
    },  
    // [SectionEnum.ComposicionPremio]: {
    //     "STYPE_DETAIDESC": { title: "Tipo Registro" },
    //     "NDISSUR_TYPEDESC": { title: "Subtipos recargo" },
    //     "NPREMIUM": { title: "Total importe" }       
    // },
    [SectionEnum.ComposicionPremio]: {
        "CONCEPTO": { title: "Concepto", width: 200 },
        "DESCRIPCION": { title: "Descripción", width: 500 },
        "PRIMA": { title: "Monto", format: moneyFormat, summarize: true, signo: '$' }     
    },
    [SectionEnum.FormaDePago]: {
        "SCLIENTDESC": { title: "Nombre" },
        "NTYP_CRECARDDESC": { title: "Tipo Tarjeta" },
        "SCREDI_CARDCONFIDENTIALIT": { title: "Número de Tarjeta" },
        "DCARDEXPIR": { title: "Fecha vencimiento de Tarjeta de Crédito", format: dateFormat },
        "NBANKEXTDESC": { title: "Banco" },
        "SCBU": { title: "Código CB" }
    }, 
    [SectionEnum.Facturacion]: {
        "NRECEIPT": { title: "Nro. Factura" },
        "DISSUEDAT": { title: "Fecha Emisión" },
        "DEFFECDATE": { title: "Fecha Inicio vigencia" },
        "DEXPIRDAT": { title: "Fecha Fin vigencia" },
        "DPAYDATE": { title: "Última Aplicación" },
        "DLIMITDATE": { title: "Fecha de vencimiento"},
        "NPREMIUM": { title: "Premio" },
        "NBALANCE": { title: "Saldo Gestión" },
        "NSTATUS_PREDESC": { title: "Estado" }
    },
    [SectionEnum.Rechazos]: {
        "DLEDGERDAT": { title: "Fecha Movimiento", format: dateFormat },
        "BULLETINSNCOD_AGREEDESC": { title: "Medio de Pago" },
        "NPAYREJECTDESC": { title: "Causa de Rechazo" },
        "NBALANCE": { title: "Saldo" },
        "NBULLETINS": { title: "Cupon" }      
    }              
};

const apiMethods = {
    [SectionEnum.GrupoFamiliar]: (props) => `GetRoles?idRamo=${props.original.ramoId}&idProducto=${props.original.productCode}&idCertificado=${props.original.certificateNumber}&poliza=${props.original.policyNumber}`, 
    [SectionEnum.DireccionPoliza]: (props) => `GetDirecciones?idRamo=${props.original.ramoId}&idProducto=${props.original.productCode}&idCertificado=${props.original.certificateNumber}&poliza=${props.original.policyNumber}`,
    [SectionEnum.Coberturas]: (props) => `GetCoberturas?idRamo=${props.original.ramoId}&idProducto=${props.original.productCode}&idCertificado=${props.original.certificateNumber}&poliza=${props.original.policyNumber}`,
    [SectionEnum.Beneficiarios]: (props) => `GetBeneficiarios?idRamo=${props.original.ramoId}&idProducto=${props.original.productCode}&idCertificado=${props.original.certificateNumber}&poliza=${props.original.policyNumber}`,    
    [SectionEnum.FormaDePago]: (props) => `GetFormaPago?idRamo=${props.original.ramoId}&idProducto=${props.original.productCode}&idCertificado=${props.original.certificateNumber}&poliza=${props.original.policyNumber}`,
    [SectionEnum.Facturacion]: (props) => `GetFacturas?idRamo=${props.original.ramoId}&idProducto=${props.original.productCode}&idCertificado=${props.original.certificateNumber}&poliza=${props.original.policyNumber}`,
    [SectionEnum.ComposicionPremio]: (props) => `GetPremioAgrupado?factura=${props.original.NRECEIPT}`,
    [SectionEnum.Rechazos]: (props) => `GetRechazos?idRamo=${props.original.ramoId}&idProducto=${props.original.productCode}&idCertificado=${props.original.certificateNumber}&factura=${props.original.NRECEIPT}`
};

const apiResponses = {
    [SectionEnum.GrupoFamiliar]: 'Roles', 
    [SectionEnum.DireccionPoliza]: 'Direcciones',
    [SectionEnum.Coberturas]: 'Coberturas',
    [SectionEnum.Beneficiarios]: 'Beneficiarios',
    [SectionEnum.ComposicionPremio]: 'PremioAgrupado',
    [SectionEnum.FormaDePago]: 'FormaPago',
    [SectionEnum.Facturacion]: 'Facturas',
    [SectionEnum.Rechazos]: 'Rechazos'
};

class PolicyDetailGrid extends React.Component {
    constructor(props) {        
        super(props);
        
        this.state = {
            columns:  QLIBS.getTableColumns(columnsDef[props.id]),
            data: [],
            loading: false,
            width: (props.width === undefined || props.width === null) ? '100%' : props.width.toString() + 'px'
        };
    }

    componentDidMount() {            
        this.setState({ loading: true });         
        fetch(`${PRODUCERURL}api/producer/${apiMethods[this.props.id](this.props)}`, requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            const dataArray = data[0] ? data[0][apiResponses[this.props.id]] : [];
            const cols = columnsDef[this.props.id];

            if(dataArray.length) {
                for(let entity of dataArray) {
                    for(let colName in cols) {                        
                        const col = cols[colName];

                        if(col && col.format) {
                            entity[colName] = col.format(entity[colName]);
                        }
                    }
                }
                
                this.setState({ data: dataArray }); 
            }
            
            this.setState({ loading: false });          
        });      
    }

    render() {        
        
        return (
            <>
                <Loading visible={this.state.loading}></Loading>
                
                <div className="row my-3">
					<div style={{width: this.state.width}}>

                <TableComponent 
                    columns={this.state.columns} 
                    data={this.state.data} 
                    expandable={this.props.expandable} 
                    subComponent={props => { 
                        const propsJoin = Object.assign({}, {...this.props}, {...props}, { original: Object.assign({}, {...this.props.original}, {...props.original})} )

                        return (<PolicyRowDetail secondLevel={this.props.expandable} {...propsJoin}  />)
                    }}/>

                    </div>
                </div>
            </>
        );
    }
}



const PolicyRowDetail = (props) => {
    const filteredSections = initSections.filter(e => e.secondLevel === props.secondLevel)
    const [sections, setSections] = useState( JSON.parse(JSON.stringify(filteredSections)));     
    
    const loadSection = (id) => {
        const section = sections.find(e => e.id === id);
    
        if(section.loaded) return;   
        
        const expandable = section.expandable && !props.secondLevel
        
        section.loaded = true;
        section.grid = <PolicyDetailGrid  {...props} id={id} expandable={expandable} />;
    
        setSections(sections.slice());
    };
   
    return (
        <div className="m-4">   
            <Accordion defaultActiveKey="0">
                { sections.map(e => (
                    <Card key={e.id}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={e.id} onClick={() => loadSection(e.id)}>
                                {e.title}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={e.id}>
                            <Card.Body>{e.grid}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>   
        </div>
                  
    );
};

export default PolicyRowDetail;
