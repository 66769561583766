/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { bool, oneOf, number, string } from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'moment/locale/es';
import 'react-dates/lib/css/_datepicker.css';
import { renderSelectBirthday, renderSelectDate, selectYears, startYear } from '../../Shared/Utils';
import { GLOBAL } from '../../Config/global';

const DatePicker = ({ defaultValue, birthday, firstDayOfWeek, inputIconPosition, numberOfMonths, noDefaultValue, displayFormat, id, disabled, smallPrint, errorMsg, imgSrc, onChange, readOnly }) => {
  const { COMMON_SEARCH_FORM } = GLOBAL;
  moment.locale('es');
  const [date, setDate] = useState(defaultValue ? moment(defaultValue) : (birthday ? moment().year(selectYears(startYear)[60]) : (noDefaultValue ? null : moment())));
  const [focus, setFocus] = useState(false);

  const handleDateChange = inputDate => {
    setDate(inputDate);
    onChange && onChange({
      target: {
        id: id,
        value: ((inputDate && inputDate._isValid) ? inputDate._d.getFullYear() + "/" + (inputDate._d.getMonth() + 1) + "/" + inputDate._d.getDate() : ""),
        isValid: inputDate && inputDate._isValid
      }
    });
  }

  const handleFocusChange = ({ focused }) => {
    setFocus(focused);
  }

  const isOutsideRange = () => false;

  return (
    <SingleDatePicker
      customInputIcon={<i className={(disabled) ? "far fa-calendar-minus" : "far fa-calendar-minus green-icon"}></i>}
      date={date}
      displayFormat={displayFormat}
      placeholder={COMMON_SEARCH_FORM.DATEPICKER_PLACEHOLDER}
      firstDayOfWeek={firstDayOfWeek}
      focused={focus}
      id={id}
      inputIconPosition={inputIconPosition}
      isOutsideRange={isOutsideRange}
      navPrev={<PrevArrow />}
      navNext={<NextArrow />}
      numberOfMonths={numberOfMonths}
      onDateChange={handleDateChange}
      onFocusChange={handleFocusChange}
      renderMonthElement={birthday ? renderSelectBirthday : renderSelectDate}
      hideKeyboardShortcutsPanel
      showDefaultInputIcon
      disabled={disabled}
      readOnly={(readOnly) ? readOnly : false}
    />
  );
}

DatePicker.defaultProps = {
  birthday: false,
  noDefaultValue: false,
  disabled: false,
  defaultValue: '',
  errorMsg: '',
  smallPrint: '',
  imgSrc: '',
  borderColor: '',
  maxLength: '',
  displayFormat: "DD/MM/YYYY",
  firstDayOfWeek: 0,
  inputIconPosition: "after",
  numberOfMonths: 1
}

DatePicker.propTypes = {
  birthday: bool,
  disabled: bool,
  displayFormat: string,
  firstDayOfWeek: number,
  id: string.isRequired,
  defaultValue: string,
  errorMsg: string,
  smallPrint: string,
  imgSrc: string,
  borderColor: string,
  maxLength: string,
  inputIconPosition: oneOf(["after", "before"]),
  numberOfMonths: number
}

const PrevArrow = () => {
  return (
    <div className="left-arrow-nav">
      <i className="fas fa-chevron-left"></i>
    </div>
  );
}

const NextArrow = () => {
  return (
    <div className="right-arrow-nav">
      <i className="fas fa-chevron-right"></i>
    </div>
  );
}


export default DatePicker;
