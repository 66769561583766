import { DEFAULT_HEADER } from '../../Config/userToken';
import React from 'react'
import { Table as TableComponent, NewUserModal,Button, Alert, Breadcrumb, Actions,Loading } from '../../Components';
import { GLOBAL } from '../../Config/global';
import * as QLIBS from '../../qLibsHelpers';
import { APPCONFIG } from '../../app.config';
import RecoverPassWordModal from '../../Components/RecoverPasswordModal';
export class Simuladores extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			tableData: [],
			loadingData: false,
			showModal: false,
			recoverUserSelected: '',
			recoverEmailSelected: '',
			showResetModal:false,
			alertMessage : '',
			error : false,
		}
		this.detailsColumn = {
			Header: "",
			accessor: "actions",
			width: 100,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<>
					<Actions
					onDelete={'() => this.removeFile(row)'}
					/>
					<Actions
					onCustom={()=>{this.showRecoverModal(row)} }
					customTitle='Restablecer contraseña'
					customIcon='sync'
					/>
				</>

			),

		};
		this.baseTableColumns = this.createBaseTableColumns();
		this.listTableColumns = this.createListTableColumns();
	}
	createBaseTableColumns() {
		let newTableColumns = [];
		let columns = {
			"id": { title: "Id", field: "id", show: true },
			"firstName": { title: "Nombre", field: "nombre", show: true },
			"lastName": { title: "Apellido", field: "apellido", show: true},
			"userName": { title: "Email", field: "userName", show: true },
		};
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });
		return newTableColumns;
	}

	createListTableColumns() {
		const newTableColumns = [this.detailsColumn, ...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}
	showRecoverModal =(row) =>{
		
		let userName = row.original.firstName + ' ' + row.original.lastName

		this.setState({showResetModal:true, recoverUserSelected:userName,recoverEmailSelected:row.original.userName});

	}

	submitResetPassword= async (email) =>{
		const { SECURITYURL } = APPCONFIG.ENDPOINTS;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				Cuit : email,
				Email : email,      
				Application:GLOBAL.APPNAME  
			})
		  };
		  try {
			this.setState({ loadingData: true })
			await fetch(SECURITYURL+ "account/forgotpassword", requestOptions)
			.then(x=> x.json()).then(response => {
				if (response.success) {
					this.setState({ alertMessage: 'Email de restablecimiento enviado.',error : false  });
				}
				else
				this.setState({ alertMessage: 'Error al enviar email. '+response.businessExceptions[0].message,error : true  });
			})
		  ;
		  } catch (error) {
			this.setState({ alertMessage: 'Error al enviar el mail de restablecimiento.', error : true  });
		  }finally{
			this.setState({ loadingData: false,showResetModal:false })
		  }
		
	}

	getData() {
		this.setState({ loadingData: true });
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};

		 fetch(`${PRODUCERURL}api/Simulator/GetAll`, requestOptions)
		 	.then(x => {return QLIBS.authorizeAction(x)})
			.then(response => {
				if (Array.isArray(response)) {
					let data = response.map(function (item) {
						return {
							...item,
							inputName: item.inputName,
						}
					});
					this.setState({ tableData: data })
				}
			}
			).catch(error => {
				this.setState({ showModal: true, alertMessage: 'No se pudo crear el usuario. '+error.businessExceptions[0].message,error : true});
			}).finally(() => this.setState({ loadingData: false }))

	}
	componentDidMount() {
		this.getData();
	}
	componentDidUpdate() {
	}

	createNewUser() {
		this.setState({ showModal: true })
	}
	hideModal = () => {
		this.setState({ showModal: false })
	}
	saveUser = async (input) => {
		this.setState({ loadingData: true });
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				Application: GLOBAL.APPNAME,
				FirstName: input.nombre,
				LastName: input.apellido,
				Email: input.email,
				Rol: 'Simulador'
			})
		};

		try {
			await fetch(PRODUCERURL + "api/login/RegisterSimulator", requestOptions).then(x=> x.json()).then(response=> {
				if (response.success) {
					this.getData();
					this.setState({ showModal: true, alertMessage: 'Simulador creado de forma correcta.',error : false  });
				}
				else{
					this.setState({ showModal: true, alertMessage: 'No se pudo crear el usuario. '+response.businessExceptions[0].message,error : true});
				}
			});			
		} catch {
			this.setState({ showModal: true, alertMessage: 'Error al contactar al servidor.',error : true});
		}
		finally{
			this.setState({ loadingData: false });
			this.hideModal()
		}
	}

	handleResponseError(response) {
		response.text()
		.then((err) => {
		  this.setState({ alertMessage: err,error : true });
		});
	  }

	render() {
		const { PAGES } = GLOBAL;
		return (
			<>
			<Loading visible={this.state.loadingData}></Loading>
				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/simulator" />
						</div>
						<div className="col-12">
							<h4 className="form-title">{PAGES.TITLE.SIMULADORES}</h4>
						</div>
						<div className="form-container container table-view col-md-12 mt-2">
							{this.state.showModal && <NewUserModal
								status="primary"
								title="Agregar nuevo usuario"
								onDismiss={this.hideModal}
								onConfirm={this.saveUser}
								producer='false'
							></NewUserModal>}
							{this.state.showResetModal && <RecoverPassWordModal
								status="primary"
								title="Restablecer contraseña"
								onDismiss={()=> {this.setState({showResetModal:false})}}
								onConfirm={()=>this.submitResetPassword(this.state.recoverEmailSelected)}

								email={this.state.recoverEmailSelected}
								name={this.state.recoverUserSelected}
							></RecoverPassWordModal>}
							<div className="row pt-4">
								{(this.state.alertMessage) && (
									<div className="col-12 px-0">
										<Alert
										icon="info"
										noDetail={true} 
										type={this.state.error ?'error':'success'}
											title={this.state.error ? 'Error: ' : '¡Hecho! '}
											message={this.state.alertMessage}
										/>
									</div>
								)}
							</div>

							<div className="row col-12 my-3">
								<div className="search-buttons">
									<Button type="button" id="btnNuevo" onClick={() => { this.createNewUser() }} classes="to-bottom button-small search-button"><span className="fa fa-plus"> </span> Nuevo usuario</Button>
								</div>
							</div>
							<div className="row my-3">
								<div className="col-12 px-3">
									<div className="col-12 mb-2">
										{<TableComponent
											columns={this.listTableColumns}
											data={this.state.tableData}
										/>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}

}

export default Simuladores