export const truncate = (num, places) => {
    let result = +(Math.round(num + "e+" + places) + "e-" + places);
    return result.toLocaleString("es-AR");
};
export const formatNumberWithTwoDecimals = (num) => {
    return (
        num
          .toFixed(2) // always two decimal digits
          .replace('.', ',') // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      ) // use . as a separator
};