import React from 'react';
import { Header, QuickAccess } from '../../Components';
export class Avanzadas extends React.Component {

  render() {
    return (
      <div>
        <Header
          {...this.props.history}
          showBackButton={false}
          showNotificationPanel
          showProfileBadge
          showNavbar={true}
          showHamburgerMenu
          token={true}
        />
        <div className='container avanzadas'>

          <div className='quick'>
            <QuickAccess />
          </div>
          <div className='opciones'>
            <div className='opcion'>
              <i className="fa fa-tasks fa-4x"></i>
              <h3>Auditoría</h3>
              <p>Consulta de logs de actividades y errores</p>
              <a className='linkBtn' href='/auditoria-productor' >Consultar</a>
            </div>
            <div className='opcion'>
              <i className="fa fa-pie-chart fa-4x"></i>
              <h3>Estadísticas</h3>
              <p>Visualiza últimos accesos, usuarios registrados, etc</p>
              <a className='linkBtn' href='/estadisticas' >Ver</a>
            </div>
          </div>
        </div>
      </div>


    )
  }

};

export default Avanzadas;