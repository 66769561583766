/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { withRouter } from "react-router-dom";
import { Breadcrumb, Button, Alert, Loading, Input, Combobox, DatePicker } from '../../Components';
import { GLOBAL } from '../../Config/global';
import { FORM_DATA } from "../../Config/form_data";
import { FORM_ERRORS } from "../../Config/form_errors";
import { APPCONFIG } from '../../app.config';
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../Config/userToken';


class RubricaDigital extends React.Component {

	constructor(props) {
		super(props);

		const toDay = new Date();
		const periodoActual = toDay.getFullYear() + (toDay.getMonth() + 1).toString().padStart(2,'0');

		this.itemInit = {
			periodo: periodoActual
		};

		this.state = {
			error: '',
			loadingForm: true,
			item: this.itemInit
		};

		this.periodos = [];
		const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
		for (var month = toDay.getMonth(); month >= 0; month--) {
			const periodoValue = toDay.getFullYear() + (month + 1).toString().padStart(2,'0');
			const periodoLabel = monthNames[month] + " " + toDay.getFullYear();
			if (periodoValue >= "202111") {
				this.periodos.push({ value: periodoValue, label: periodoLabel });
			}
		}

		/// ERRORS
		this.errorPeriodo = "";

		/// FUNCIONES
		this.handleComboChange = this.handleComboChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleResponseOk = this.handleResponseOk.bind(this);
		this.handleResponseError = this.handleResponseError.bind(this);
		this.procesarRubricaDigital = this.procesarRubricaDigital.bind(this);
	}

	componentDidMount() {
		this.setState({
			loadingForm: false
		});
	}


	handleComboChange = (selectedOption, event) => {
		const { COMMON_ERRORS } = FORM_ERRORS;

		let inputName = event.name;
		let inputValue = selectedOption.value;

		this.errorPeriodo = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION : "";
		this.errorPeriodoBorderColor = (this.errorPeriodo) ? "error" : "success";

		/// SETEO DE VALORES
		let itemCopy = Object.assign({}, this.state.item);
		itemCopy[inputName] = inputValue;
		this.setState({ item: itemCopy });
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { RUBRIC_DIGITAL } = GLOBAL;
		const item = this.state.item;

		if (item.periodo.length > 0)
		{
			this.setState({ error: "" });
			this.procesarRubricaDigital();
		}
		else
		{
			this.setState({ error: RUBRIC_DIGITAL.VALIDATION_ERROR });
		}
	}

	handleResponseOk = (response) => {
		let self = this;
		response.blob()
		.then(function (blob) {
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = "rubrica-digital.xml";
			link.click();
		})
		.then(() => {
			self.setState({ loadingForm: false });
		});
	}

	handleResponseError = (response) => {
		let self = this;
		response.text()
		.then(function (message) {
			self.setState({ loadingForm: false, error: message });
		})
	}


	procesarRubricaDigital() {
		
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
        var userId = userAuth.userId();

		this.setState({ loadingForm: true });
			
		const item = this.state.item;
		const parameters = JSON.stringify({
			Periodo: item.periodo,
			UserId: userId 
		});
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: `{"Report": "RubricDigital", "FileName": "rubrica-digital.xml", "ContentType": "text/xml", "Parameters": "${parameters.replace(/['"]+/g, '\\"')}"}`
		};

		fetch(`${PRODUCERURL}portfolio/downloadrubricdigital`, requestOptions)
		.then(response => {
			if (response.ok)
				this.handleResponseOk(response);
			else
				this.handleResponseError(response);
		})
		.catch(error => {
			this.setState({ error: `Error: ${error.message}` });
		});
	}


	render(...props) {
		const { RUBRIC_DIGITAL, PAGES } = GLOBAL;

		return (
			<>
				<Loading visible={this.state.loadingForm}></Loading>
				<section className='form-container master-page rubricaDigital'>
					<div className='col-12'>
						<div className='row'>
							<div className='col-12'>
								<div className="col-12">
									<Breadcrumb currentPage="/rubricadigital" />
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-12">
											<h4 className="form-title">{PAGES.TITLE.RUBRIC_DIGITAL}</h4>
										</div>
									</div>
								</div>
								<div className="col-12">
									{
										(this.state.error) &&
										<div id="form-error-message" className="row my-2">
											<div className="col-12">
												<Alert
													type="error"
													icon="info"
													title={this.state.error}
													message=""
													noDetail={true}
												/>
											</div>
										</div>
									}
								</div>
								<form id="rubrica_digital_form" onSubmit={this.handleSubmit} className="rubrica_digital__Form">
									<section>
										<div className="mt-4">
											<div className="row">
												<div className="col-4">
													<Combobox
														id="periodo"
														text="Periodo"
														label="Periodo"
														placeholder={FORM_DATA.SELECT_OPTION}
														options={this.periodos}
														errorMsg={this.errorPeriodo}
														borderColor={this.errorPeriodoBorderColor}
														defaultValue={this.state.item.periodo}
														onChange={(v, e) => { this.handleComboChange(v, e); }}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-4 pt-5">
													<Button type="submit" onClick={this.handleSubmit}>{FORM_DATA.GET_RUBRIC_DIGITAL}</Button>
												</div>
											</div>
										</div>
									</section>
								</form>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default withRouter(RubricaDigital);
