/* eslint-disable import/named */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/no-named-as-default */
import React, { Component } from 'react'
import { Breadcrumb, Loading, TabsPanel, Table as TableComponent, Actions, Checkbox, Typeahead, DatePicker, Button } from '../../Components';
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from '../../Config/global';
import { withRouter } from "react-router-dom";
import userAuth from '../../Config/userauth';
import moment from "moment";
import { AuditoriaModal } from './modal';
import * as utils from '../../Shared/Utils';

export class Auditoria extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingForm: false,
            viewDetail: false,
            filterTabActive: 0,
            parent: [],
            child: [],
            cuit: '',
            fechaDesde: QLIBS.getFirstDay(),
            fechaHasta: QLIBS.getLastDay(),
            currentUser: userAuth.isAuthenticated() && userAuth.isUserSimulator() && !utils.getStorage('menuAdvanced') ? true : false,
            advanced: false
        };
        this.filterTabs = [
            {
                label: 'Actividad'
            },
            {
                label: 'Errores'
            },
            {
                label: 'Mensajes'
            },
        ];

        this.columns1 = [];
        this.columns_excepctions = [];
        this.columns_auditorias = [];
        this.columns_emails = [];

        this.data1 = [];
        this.array_excepctions = [];
        this.array_auditorias = [];
        this.array_emails = [];

        this.title = '';
        this.logs = null;
        this.actions = null;
        this.messages = null;

        this.handleChange = this.handleChange.bind(this);
        this.handleResponseLogs = this.handleResponseLogs.bind(this);
        this.handleResponseActions = this.handleResponseActions.bind(this);
        this.handleResponseMessages = this.handleResponseMessages.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.executeQuery(this.state.filterTabActive);
    }

    handleChange = event => {
        let isCheckbox = event.target.type === "checkbox";
        let inputName = isCheckbox ? event.target.id : ('name' in event.target ? event.target.name : event.target.id);
        let inputValue = isCheckbox ? event.target.checked : event.target.value;
        this.setState({ [inputName]: inputValue }, () => {
            if (isCheckbox && inputName === "currentUser" && !inputValue) {
                this.setState({ parent: [], child: [], cuit: '' });
            }
            this.executeQuery(this.state.filterTabActive);
        });
    }

    onChangeTab = index => {
        this.setState({ filterTabActive: index }, () => { this.executeQuery(index) });
    };

    viewDetail = (row, type) => {
        this.logs = null;
        this.actions = null;
        this.messages = null;
        switch (type) {
            case 'logs':
                this.title = 'DETALLE DE LOG';
                this.logs = row.original;
                break;
            case 'actions':
                this.title = 'DETALLE DE ACCIÓN';
                this.actions = row.original;
                break;
            case 'messages':
                this.title = 'DETALLE DE MENSAJE ENVIADO';
                this.messages = row.original;
                break;
            default:
                break;
        }
        this.setState({ viewDetail: true });
    }

    toggleModal = () => {
        this.setState({ viewDetail: false });
    }

    executeQuery = (index) => {
        this.setState({ loadingForm: true });
        switch (index) {
            case 0:
                QLIBS.fetchDataGET(APIS.URLS.ACTIONS_QUERY + this.getQueryParams(), true)
                    .then(this.handleResponseActions);
                break;
            case 1:
                QLIBS.fetchDataGET(APIS.URLS.ERRORS_QUERY + this.getQueryParams(false), true)
                    .then((response) => { this.handleResponseLogs(2, response); });
                break;
            case 2:
                QLIBS.fetchDataGET(APIS.URLS.MESSAGES_QUERY + this.getQueryParams(), true)
                    .then(this.handleResponseMessages);
                break;
            default:
                break;
        }
    }

    getQueryParams = (start = true) => {
        return (start ? '?' : '&')
            + 'userId=' + (this.state.currentUser ? userAuth.userId() : '')
            + '&cuit=' + (!this.state.currentUser ? this.state.cuit : '')
            + '&from=' + moment(this.state.fechaDesde).format("MM/DD/YYYY")
            + '&to=' + moment(this.state.fechaHasta).format("MM/DD/YYYY");
    }

    handleResponseLogs = (index, response) => {
        if (response && response.length > 0) {
            response = response.map(function (item) {
                let result = Object.assign({}, item);
                result.creationDate = item.creationDate === null ? '-' : moment(item.creationDate).format("DD/MM/YYYY HH:mm:ss");
                return result;
            });
        }
        let columns = {
            "cuit": { title: "CUIT" },
            "name": { title: "Nombre - Razón social" },
            "detail": { title: "Detalle" },
            "creationDate": { title: "Fecha" },
        };
        if (this.state.advanced) {
            columns["severity"] = { title: "Gravedad" };
            columns["source"] = { title: "Origen" };
            columns["class"] = { title: "Clase" };
            columns["method"] = { title: "Método" };
        }
        if (index === 1) {
            this.data1 = response;
            this.columns1 = this.builColumnsTable(this.data1, columns, 'logs');
        }
        else {
            columns["exception"] = { title: "Excepción" };
            this.array_excepctions = response;
            this.columns_excepctions = this.builColumnsTable(this.array_excepctions, columns, 'logs');
        }

        this.setState({ loadingForm: false });
    }

    handleResponseActions = response => {
        if (response && response.length > 0) {
            response = response.map(function (item) {
                let result = Object.assign({}, item);
                result.creationDate = item.creationDate === null ? '-' : moment(item.creationDate).format("DD/MM/YYYY HH:mm:ss");
                return result;
            });
        }
        let columns = {
            "cuit": { title: "CUIT" },
            "name": { title: "Nombre - Razón social" },
            "creationDate": { title: "Fecha" },
            "function": { title: "Evento realizados" },
        };
        if (this.state.advanced) {
            columns["application"] = { title: "Aplicación" };
            columns["action"] = { title: "Acción" };
            columns["url"] = { title: "URL" };
        }
        this.array_auditorias = response;
        this.columns_auditorias = this.builColumnsTable(this.array_auditorias, columns, 'actions');
        this.setState({ loadingForm: false });
    }

    handleResponseMessages = response => {
        if (response && response.length > 0) {
            response = response.map(function (item) {
                let result = Object.assign({}, item);
                result.sendDate = item.sendDate === null ? '-' : moment(item.sendDate).format("DD/MM/YYYY HH:mm:ss");
                return result;
            });
        }
        let columns = {
            "cuit": { title: "CUIT" },
            "name": { title: "Nombre - Razón social" },
            "typeString": { title: "Tipo" },
            "sendDate": { title: "Fecha de envio" },
            "to": { title: "Enviado a" },
        };
        this.array_emails = response;
        this.columns_emails = this.builColumnsTable(this.array_emails, columns, 'messages');
        this.setState({ loadingForm: false });
    }

    builColumnsTable = (tableData, columnsDefinition, type) => {
        console.log(tableData);
        if (this.state.currentUser) {
            delete columnsDefinition.cuit;
            delete columnsDefinition.name;
        }
        let result = [];
        result.push({
            Header: "",
            accessor: "actions",
            width: 70,
            style: { display: 'flex', alignItems: 'center' },
            filterable: false,
            sortable: false,
            Cell: row => (<Actions onViewDetail={() => this.viewDetail(row, type)} />)
        });
        let cols = QLIBS.getTableColumns(columnsDefinition);
        cols.forEach((c) => { result.push(c) });
        return result;
    }

    render(...props) {
        const { COMMON_SEARCH_FORM } = GLOBAL;
        return (
            <>
                <Loading visible={this.state.loadingForm}></Loading>
                <section className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb currentPage="/auditoria-productor" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-container container table-view col-md-12 mt-2">
                            <form className="auditoria__Form">
                                {this.state.viewDetail && <AuditoriaModal onDismiss={() => { this.toggleModal() }} title={this.title} logs={this.logs} actions={this.actions} messages={this.messages} />}
                                <div className="row mb-3">
                                    <div className="col-xs-12 col-sm-12 col-md-4">
                                        <label>{COMMON_SEARCH_FORM.FECHA.DESDE}</label>
                                        <DatePicker
                                            id="fechaDesde"
                                            defaultValue={this.state.fechaDesde ? this.state.fechaDesde.toString() : ''}
                                            maxLength="10"
                                            noDefaultValue="true"
                                            onKeyDown={this.handleKeyDown}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4">
                                        <label>{COMMON_SEARCH_FORM.FECHA.HASTA}</label>
                                        <DatePicker
                                            id="fechaHasta"
                                            defaultValue={this.state.fechaHasta ? this.state.fechaHasta.toString() : ''}
                                            maxLength="10"
                                            noDefaultValue="true"
                                            onKeyDown={this.handleKeyDown}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4">
                                        {!utils.getStorage('menuAdvanced') && (
                                            <Checkbox
                                                id="currentUser"
                                                className="mt-4"
                                                text={"Ver solo productor simulado"}
                                                checked={this.state.currentUser}
                                                onChange={this.handleChange}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-4">
                                        {this.state.currentUser === false && (
                                            <Typeahead
                                                id="cuit"
                                                label={COMMON_SEARCH_FORM.PRODUCTOR_CUIT}
                                                placeholder={COMMON_SEARCH_FORM.PRODUCTOR_CUIT_PLACEHOLDER}
                                                SEARCH_URI={APIS.URLS.PRODUCER_AUTOCOMPLETE_BY_CUIT}
                                                selected={this.state.parent}
                                                onSelected={(selected) => {
                                                    let array = [{ id: selected.id, value: selected.value }];
                                                    this.setState({ parent: array, child: array });
                                                    this.handleChange({ target: { name: 'cuit', value: selected.id } });
                                                }}
                                                onClean={() => { this.setState({ parent: [], cuit: '' }) }}
                                                labelKey={"id"}
                                                disabled={this.state.currentUser}
                                            />)}
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4">
                                        {this.state.currentUser === false && (
                                            <Typeahead
                                                id="nombre"
                                                label={COMMON_SEARCH_FORM.PRODUCTOR_NOMBRE}
                                                placeholder={COMMON_SEARCH_FORM.PRODUCTOR_NOMBRE_PLACEHOLDER}
                                                SEARCH_URI={APIS.URLS.PRODUCER_AUTOCOMPLETE_BY_NAME}
                                                selected={this.state.child}
                                                onSelected={(selected) => {
                                                    let array = [{ id: selected.id, value: selected.value }];
                                                    this.setState({ parent: array, child: array });
                                                    this.handleChange({ target: { name: 'cuit', value: selected.id } });
                                                }}
                                                onClean={() => { this.setState({ child: [], cuit: '' }) }}
                                                labelKey={"value"}
                                                disabled={this.state.currentUser}
                                            />)}
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4">
                                        <Checkbox
                                            id="advanced"
                                            className="mt-4"
                                            text="Avanzado"
                                            checked={this.state.advanced}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="row justify-content-end mb-2">
                                    <div className="col-2">
                                        <Button type="button" onClick={() => {
                                            this.setState({
                                                parent: [],
                                                child: [],
                                                cuit: '',
                                                fechaDesde: QLIBS.getFirstDay(),
                                                fechaHasta: QLIBS.getLastDay(),
                                                currentUser: false,
                                                advanced: false
                                            }, () => {
                                                if (document.getElementById('currentUser')) {
                                                    document.getElementById('currentUser').checked = true;
                                                }
                                                document.getElementById('advanced').checked = false;
                                                this.executeQuery(this.state.filterTabActive);
                                            })
                                        }}><i class="fa fa-eraser" aria-hidden="true"></i>&nbsp;Limpiar filtros</Button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <TabsPanel options={this.filterTabs} tabActive={this.state.filterTabActive} onChange={this.onChangeTab}>
                                            <div className="container option-1" id="tab1">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <br />
                                                        <TableComponent columns={this.columns_auditorias} data={this.array_auditorias} {...props} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container option-2" id="tab2">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <br />
                                                        <TableComponent columns={this.columns_excepctions} data={this.array_excepctions} {...props} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container option-3" id="tab3">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <br />
                                                        <TableComponent columns={this.columns_emails} data={this.array_emails} {...props} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container option-1" id="tab4">

                                            </div>
                                        </TabsPanel>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default withRouter(Auditoria);