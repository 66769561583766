import React from 'react'
import LoadingImg from '../../Resources/Images/Loading/Loading.gif';
const LoadingTable = props => {
    return (
        <>
            <div hidden={!props.loading} style={{
                display: "block",
                position: "absolute",
                transition: "all .3s ease",
                top: '50%',
                right: '50%',
                textAlign: "center"
            }}>
                <img src={LoadingImg} alt="loading img"/>
            </div>
        </>
    )
}

export default LoadingTable;
